import { useNavigate, useParams } from "react-router-dom";
import AppContainer from "src/components/app/common/AppContainer";
import { useRoomListQuery } from "src/hooks/coreApi/useRoomListQuery";
import { PATH_DASHBOARD } from "src/routes/paths";
import RoomForm from "src/components/app/room/RoomForm";
import AppMenu from "src/components/app/common/AppMenu";
import { useRoomDeleteMutation } from "src/hooks/coreApi/useRoomDeleteMutation";
import AppAlertDialog, {
  useAppAlertDialog,
} from "src/components/app/common/AppAlertDialog";
import { useSnackbar } from "notistack";
import { AxiosError } from "axios";
import { forEach } from "lodash";

export default function RoomEdit() {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();

  const roomQuery = useRoomListQuery({
    id: Number(id),
    withRoomParticipants: true,
    withRoomParticipantsUser: true,
  });

  const room = roomQuery.data?.rooms[0];
  const roomDeleteMutation = useRoomDeleteMutation();
  const alertDialog = useAppAlertDialog();
  const { enqueueSnackbar } = useSnackbar();

  const handleDeleteOk = () => {
    if (room?.id) {
      roomDeleteMutation.mutate(
        {
          id: room.id,
        },
        {
          onSuccess() {
            enqueueSnackbar("削除しました");
            navigate("/video/rooms");
          },
          onError(err) {
            type ErrorType = {
              [key: string]: string;
            };

            const aErr = (err = err as AxiosError<ErrorType>);
            forEach(aErr.response?.data.roomParticipants, (v) => {
              enqueueSnackbar(v, {
                variant: "error",
              });
            });
          },
        }
      );
    }
  };

  return (
    <>
      <AppContainer
        meta={{
          title: "ルーム詳細",
        }}
        breadcrumbs={{
          heading: "ルーム詳細",
          links: [
            { name: "ホーム", href: PATH_DASHBOARD.root },
            { name: "ルーム詳細" },
          ],
          action: (
            <AppMenu
              options={[
                {
                  label: "削除する",
                  onClick() {
                    alertDialog.onOpen();
                  },
                },
              ]}
            >
              その他の操作
            </AppMenu>
          ),
        }}
      >
        <RoomForm
          isEdit={true}
          currentRoom={room}
          onSuccess={roomQuery.refetch}
        />
      </AppContainer>
      {room && (
        <AppAlertDialog
          {...alertDialog}
          title="ルームの削除"
          description={`ルーム: ${room.name} (ID: ${room.id})を削除してもよろしいですか？`}
          onOk={handleDeleteOk}
        />
      )}
    </>
  );
}
