import { useSnackbar } from "notistack";
import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { RHFTextField } from "src/components/hook-form";
import FormProvider from "src/components/hook-form/FormProvider";
import useFormError from "src/hooks/app/form/useFormError";
import { usePlaceCreateMutation } from "src/hooks/coreApi/usePlaceCreateMutation";
import { usePlaceUpdateMutation } from "src/hooks/coreApi/usePlaceUpdateMutation";
import { PlaceResponse } from "src/openapi";
import AppDescriptionField from "../../common/AppDescriptionField";
import AppFormLayout, {
  AppFormButtonGroup,
  AppFormError,
  AppFormErrorGroup,
  AppFormGroup,
  AppFormCard,
  AppFormSubmitButton,
} from "../../common/AppFormLayout";

type PlaceFormValues = {
  name: string;
  code: string;
};

type Props = {
  isEdit?: boolean;
  currentPlace?: PlaceResponse;
  onSuccess?(): void;
};

export default function PlaceForm(props: Props) {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const placeCreateMutation = usePlaceCreateMutation();
  const placeUpdateMutation = usePlaceUpdateMutation();

  const defaultValues = useMemo<PlaceFormValues>(() => {
    return {
      name: props.currentPlace?.name || "",
      code: props.currentPlace?.code || "",
    };
  }, [props.currentPlace]);

  const placeForm = useForm<PlaceFormValues>({
    defaultValues,
  });

  useEffect(() => {
    placeForm.reset(defaultValues);
  }, [props]);

  const onSuccess = () => {
    if (props.isEdit) {
      enqueueSnackbar("更新しました");
    }

    if (!props.isEdit) {
      enqueueSnackbar("追加しました");
      placeForm.reset();
      navigate("/places");
    }
    props?.onSuccess && props.onSuccess();
  };

  const onError = useFormError<PlaceFormValues>(placeForm);

  const onSubmit = (values: PlaceFormValues) => {
    if (props.isEdit && props?.currentPlace?.id) {
      placeUpdateMutation.mutate(
        {
          id: props.currentPlace.id,
          placeUpdateRequest: {
            name: values.name,
            code: values.code,
          },
        },
        {
          onSuccess,
          onError,
        }
      );
    }

    if (!props.isEdit) {
      placeCreateMutation.mutate(
        {
          placeCreateRequest: {
            name: values.name,
            code: values.code,
          },
        },
        {
          onSuccess,
          onError,
        }
      );
    }
  };

  return (
    <FormProvider
      methods={placeForm}
      onSubmit={placeForm.handleSubmit(onSubmit)}
    >
      <AppFormLayout>
        <AppFormCard>
          {!placeForm.formState.isValid && (
            <AppFormErrorGroup>
              <AppFormError<PlaceFormValues>
                errors={placeForm.formState.errors}
              />
            </AppFormErrorGroup>
          )}

          {props.isEdit && props.currentPlace && (
            <AppFormGroup>
              <AppDescriptionField label="ID">
                {props.currentPlace.id}
              </AppDescriptionField>
            </AppFormGroup>
          )}

          <AppFormGroup>
            <RHFTextField name="name" label="名前" />
            <RHFTextField name="code" label="コード" />
          </AppFormGroup>

          <AppFormButtonGroup>
            <AppFormSubmitButton
              loading={
                placeCreateMutation.isLoading || placeUpdateMutation.isLoading
              }
            >
              {!props.isEdit ? "作成する" : "保存する"}
            </AppFormSubmitButton>
          </AppFormButtonGroup>
        </AppFormCard>
      </AppFormLayout>
    </FormProvider>
  );
}
