import React from "react";
import { Helmet } from "react-helmet-async";

export type AppPageMetaProps = {
  title: string;
};

export default function AppPageMeta(props: AppPageMetaProps) {
  return (
    <>
      <Helmet>
        <title>{props.title} | さあ・くる管理ダッシュボード</title>
      </Helmet>
    </>
  );
}
