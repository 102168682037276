import { useNavigate, useParams } from "react-router-dom";
import { useMemo, useEffect } from "react";
// form
import { useForm } from "react-hook-form";
// @mui
import { LoadingButton } from "@mui/lab";
import { Grid, Card, Stack, Box } from "@mui/material";
// routes
import { PATH_DASHBOARD } from "../../../../routes/paths";
// @types
import { IBlogNewPost } from "../../../../@types/blog";
// components
import { useSnackbar } from "../../../snackbar";
import FormProvider, {
  RHFAutocomplete,
  RHFSwitch,
  RHFTextField,
} from "../../../hook-form";
import { useContentCreateMutation } from "src/hooks/coreApi/useContentCreateMutation";
import { useContentUpdateMutation } from "src/hooks/coreApi/useContentUpdateMutation";
import { ContentResponse } from "src/openapi/api";
import { ContentType } from "src/hooks/coreApi/useContentListQuery";
import dayjs from "dayjs";
import useFormError from "src/hooks/app/form/useFormError";
import { useContentTagListQuery } from "src/hooks/coreApi/useContentTagListQuery";
import DeleteIcon from "@mui/icons-material/Delete";

// const TAGS_OPTION = ["イベント", "神山ch", "まちの風景", "議会"];

export type FormValuesProps = IBlogNewPost;

type FormValues = {
  titleJa: string;
  bodyJa: string;
  dekigotoVideoUrl: string;
  tagLabels: string[];
  releaseAt: string;
};

export type ContentFormProps = {
  isEdit?: boolean;
  currentContent?: ContentResponse;
  onSuccess?(): void;
};

export default function ContentDekigotoForm(props: ContentFormProps) {
  const navigate = useNavigate();

  const { type } = useParams<{ type: ContentType }>();

  const { enqueueSnackbar } = useSnackbar();

  const contentTagListQuery = useContentTagListQuery("dekigoto", {});
  const contentTags = contentTagListQuery.data?.contentTags || [];

  const contentCreateMutation = useContentCreateMutation();
  const contentUpdateMutation = useContentUpdateMutation();

  const defaultValues = useMemo<FormValues>(() => {
    return {
      titleJa: props.currentContent?.titleJa || "",
      bodyJa: props.currentContent?.bodyJa || "",
      dekigotoVideoUrl: props.currentContent?.dekigotoVideoUrl || "",
      tagLabels: props.currentContent?.contentTags?.map((t) => t.label) || [],
      releaseAt:
        dayjs(props.currentContent?.releaseAt).format("YYYY-MM-DDTHH:mm") || "",
    };
  }, [props.currentContent]);

  const contentForm = useForm<FormValues>({
    defaultValues,
  });

  useEffect(() => {
    contentForm.reset(defaultValues);
  }, [props]);

  const onClickDeleteTag = (index: number) => {
    const confirmOk = window.confirm(
      "このタグを削除しますか？\n（削除すると他のコンテンツに紐づいているタグも削除されます）"
    );
    if (confirmOk) {
      enqueueSnackbar("タグを削除しました");
    }
  };

  const onSuccess = () => {
    enqueueSnackbar("保存しました");

    if (!props.isEdit) {
      contentForm.reset();
      navigate(PATH_DASHBOARD.content[type as ContentType]);
    }

    props?.onSuccess && props.onSuccess();
  };

  const onError = useFormError<FormValues>(contentForm);

  const onSubmit = (values: FormValues) => {
    if (!type) {
      return;
    }

    if (props.isEdit && props.currentContent) {
      contentUpdateMutation.mutate(
        {
          id: props.currentContent.id,
          contentUpdateRequest: {
            slug: props.currentContent.slug,
            detailType: "",
            titleJa: values.titleJa,
            titleEn: values.titleJa, // TODO
            bodyJa: values.bodyJa,
            bodyEn: values.bodyJa, // TODO
            dekigotoVideoUrl: values.dekigotoVideoUrl,
            areaIds: [], // TODO
            placeIds: [], // TODO
            status: "public", // TODO
            kinkyuLevel: null,
            releaseAt: dayjs(values.releaseAt).toISOString(),
            tagLabels: values.tagLabels,
          },
        },
        {
          onSuccess,
          onError,
        }
      );
    } else {
      contentCreateMutation.mutate(
        {
          contentCreateRequest: {
            type: type,
            slug: "",
            detailType: "",
            titleJa: values.titleJa,
            titleEn: values.titleJa, // TODO
            bodyJa: values.bodyJa,
            bodyEn: values.bodyJa, // TODO
            dekigotoVideoUrl: values.dekigotoVideoUrl,
            areaIds: [], // TODO
            placeIds: [], // TODO
            status: "public", // TODO
            kinkyuLevel: null,
            releaseAt: dayjs(values.releaseAt).toISOString(),
            tagLabels: values.tagLabels,
          },
        },
        {
          onSuccess,
          onError,
        }
      );
    }
  };

  return (
    <FormProvider
      methods={contentForm}
      onSubmit={contentForm.handleSubmit(onSubmit)}
    >
      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          <Card sx={{ p: 3 }}>
            <Stack spacing={3}>
              <RHFTextField name="titleJa" label="タイトル" />
              <RHFTextField name="bodyJa" label="本文" multiline rows={3} />
              <RHFTextField name="dekigotoVideoUrl" label="Youtube URL" />
            </Stack>
          </Card>
        </Grid>

        <Grid item xs={12} md={4}>
          <Card sx={{ p: 3 }}>
            <Stack spacing={3}>
              {/* <div>
                <RHFSwitch
                  name="status"
                  label="公開する"
                  labelPlacement="start"
                  sx={{
                    mb: 1,
                    mx: 0,
                    width: 1,
                    justifyContent: "space-between",
                  }}
                />
              </div> */}
              <RHFAutocomplete
                name="tagLabels"
                label="カテゴリ"
                freeSolo
                multiple
                options={contentTags.map((c) => c.label)}
                ChipProps={{ size: "small" }}
                renderOption={(props, option, { selected }) => {
                  return (
                    <li {...props}>
                      <Box
                        width="100%"
                        display="flex"
                        justifyContent="space-between"
                      >
                        <div>{option}</div>
                        <div style={{ fontSize: "10px" }}>
                          <DeleteIcon
                            htmlColor="gray"
                            onClick={() => {
                              onClickDeleteTag(1);
                            }}
                          />
                        </div>
                      </Box>
                    </li>
                  );
                }}
              />

              <RHFTextField
                name="releaseAt"
                label="公開日時"
                type="datetime-local"
                onChange={(evt) => {
                  contentForm.setValue("releaseAt", evt.target.value);
                }}
              />
            </Stack>
          </Card>

          <Stack direction="row" spacing={1.5} sx={{ mt: 3 }}>
            <LoadingButton
              fullWidth
              type="submit"
              variant="contained"
              size="large"
              loading={contentForm.formState.isSubmitting}
            >
              保存する
            </LoadingButton>
          </Stack>
        </Grid>
      </Grid>
    </FormProvider>
  );
}
