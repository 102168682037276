import { PATH_DASHBOARD } from "../../../routes/paths";
import ContentForm from "../../../components/app/content/ContentForm";
import AppContainer from "src/components/app/common/AppContainer";
import { useNavigate, useParams } from "react-router";
import {
  useContentListQuery,
  ContentType,
} from "src/hooks/coreApi/useContentListQuery";
import AppErrorAlert from "src/components/app/common/AppErrorAlert";
import { contentTypeOptions } from "src/utils/app/content";
import { useMemo } from "react";
import AppMenu from "src/components/app/common/AppMenu";
import { useAppConfirm } from "src/components/app/common/AppConfirm/useAppConfirm";
import { useContentDeleteMutation } from "src/hooks/coreApi/useContentDeleteMutation";
import { useSnackbar } from "notistack";

export default function ContentEdit() {
  const { id } = useParams<{ id: string }>();
  const { type } = useParams<{ type: ContentType }>();

  const navigate = useNavigate();

  const contentListQuery = useContentListQuery(type as ContentType, {
    id: Number(id),
    withContentPlaces: true,
    withFiles: true,
    withUpdatedByUser: true,
    withContentPlacesPlace: true,
    withCreatedByUser: true,
    withContentTags: true,
  });
  const content = contentListQuery.data?.contents[0];

  const contentDeleteMutation = useContentDeleteMutation();

  const { enqueueSnackbar } = useSnackbar();

  const typeName = useMemo(() => {
    return contentTypeOptions?.[type as ContentType]?.name || "コンテンツ";
  }, [type]);

  const appConfirm = useAppConfirm();

  const onDeleteSuccess = () => {
    enqueueSnackbar(
      <>
        {content?.titleJa}
        <br />
        を削除しました
      </>
    );
    navigate(PATH_DASHBOARD.content?.[type as ContentType]);
  };

  const onDeleteError = () => {
    enqueueSnackbar("削除に失敗しました", { variant: "error" });
  };

  const onClickDelete = async () => {
    const confirmOk = await appConfirm.open({
      title: `${typeName}の削除`,
      body: (
        <>
          {content?.titleJa}
          <br />
          を削除しますか？
        </>
      ),
    });

    if (!confirmOk) {
      return;
    }

    contentDeleteMutation.mutate(
      {
        id: Number(id),
      },
      {
        onSuccess: onDeleteSuccess,
        onError: onDeleteError,
      }
    );
  };

  if (!type) {
    return <AppErrorAlert error="利用できないコンテンツタイプです" />;
  }

  if (!content) {
    return <AppErrorAlert error="このコンテンツは存在しません" />;
  }

  return (
    <>
      <AppContainer
        meta={{
          title: `${typeName}登録`,
        }}
        breadcrumbs={{
          heading: `${typeName}登録`,
          links: [
            { name: "ホーム", href: PATH_DASHBOARD.root },
            { name: `${typeName}一覧`, href: PATH_DASHBOARD.content[type] },
            { name: `${typeName}登録` },
          ],
          action: (
            <AppMenu
              options={[
                {
                  label: "削除する",
                  onClick: onClickDelete,
                },
              ]}
            >
              その他の操作
            </AppMenu>
          ),
        }}
      >
        <ContentForm type={type} isEdit={true} currentContent={content} />
      </AppContainer>
    </>
  );
}
