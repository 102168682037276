import { TaxiWeeklyScheduleApiTaxiWeeklyScheduleSaveRequest } from "../../openapi/api";
import appApiClient from "./coreApiClient";
import useBaseMutation from "./useBaseMutation";

export const useTaxiWeeklyScheduleSaveMutation = () =>
  useBaseMutation({
    mutationFn: (
      req: TaxiWeeklyScheduleApiTaxiWeeklyScheduleSaveRequest,
      config
    ) => appApiClient.taxiWeeklyScheduleApi.taxiWeeklyScheduleSave(req, config),
  });
