import { useCallback } from 'react'
import { atom, useRecoilState } from 'recoil'

type OpenArgs = {
  title?: string
  body: React.ReactNode
  cancelText?: string
  isShowCancel?: boolean
  okText?: string
  isShowOk?: boolean
}

type DialogProps =
  | (OpenArgs & {
      resolve: (value: boolean) => void
    })
  | undefined

const dialogPropsState = atom<DialogProps>({
  key: 'confirmDialog/dialogProps',
  default: undefined,
})

export function useAppConfirm() {
  const [dialogProps, setDialogProps] =
    useRecoilState<DialogProps>(dialogPropsState)

  const close = useCallback(
    (result: boolean) => {
      if (!dialogProps) {
        return
      }
      if (dialogProps.resolve) {
        dialogProps.resolve(result)
      }

      setDialogProps(undefined)
    },
    [dialogProps],
  )

  function open({ title, body }: OpenArgs): Promise<boolean> {
    return new Promise((resolve) => {
      setDialogProps({
        title,
        body,
        resolve,
      })
    })
  }

  return { isOpen: !!dialogProps, open, close, dialogProps }
}
