import dayjs, { Dayjs } from "dayjs";

export function zeroValueToDateTime(timeString: string) {
  // TODO: API修正 golangのゼロ値デフォルト
  if (timeString === "0001-01-01T00:00:00Z") {
    return dayjs().format("YYYY-MM-DD 00:00:00");
  }

  return dayjs(timeString).format("YYYY-MM-DD HH:mm:ss");
}

export function formatDateTimeRFC3339(date: Dayjs) {
  return date.format("YYYY-MM-DDTHH:mm:ssZ");
}
