import { useNavigate, useParams } from "react-router-dom";
import AppContainer from "src/components/app/common/AppContainer";
import { useTaxiLandmarkListQuery } from "src/hooks/coreApi/useTaxiLandmarkListQuery";
import { PATH_DASHBOARD } from "src/routes/paths";
import TaxiLandmarkForm from "src/components/app/taxiLandmark/TaxiLandmarkForm";
import AppMenu from "src/components/app/common/AppMenu";
import { useTaxiLandmarkDeleteMutation } from "src/hooks/coreApi/useTaxiLandmarkDeleteMutation";
import AppAlertDialog, {
  useAppAlertDialog,
} from "src/components/app/common/AppAlertDialog";
import { useSnackbar } from "notistack";

export default function TaxiLandmarkEdit() {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const taxiLandmarkQuery = useTaxiLandmarkListQuery({ id: Number(id) });
  const taxiLandmark = taxiLandmarkQuery.data?.taxiLandmarks[0];
  const taxiLandmarkDeleteMutation = useTaxiLandmarkDeleteMutation();
  const alertDialog = useAppAlertDialog();
  const { enqueueSnackbar } = useSnackbar();

  const handleDeleteOk = () => {
    if (taxiLandmark?.id) {
      taxiLandmarkDeleteMutation.mutate(
        {
          id: taxiLandmark.id,
        },
        {
          onSuccess() {
            enqueueSnackbar("削除しました");
            navigate("/taxi/landmarks");
          },
          onError(err) {
            // TODO エラー表示
            enqueueSnackbar("サーバーエラーが発生しました", {
              variant: "error",
            });
          },
        }
      );
    }
  };

  return (
    <>
      <AppContainer
        meta={{
          title: "ランドマーク詳細",
        }}
        breadcrumbs={{
          heading: "ランドマーク詳細",
          links: [
            { name: "ホーム", href: PATH_DASHBOARD.root },
            { name: "ランドマーク一覧", href: PATH_DASHBOARD.taxi.landmark },
            { name: "ランドマーク詳細" },
          ],
          action: (
            <AppMenu
              options={[
                {
                  label: "削除する",
                  onClick() {
                    alertDialog.onOpen();
                  },
                },
              ]}
            >
              その他の操作
            </AppMenu>
          ),
        }}
      >
        <TaxiLandmarkForm isEdit={true} currentTaxiLandmark={taxiLandmark} />
      </AppContainer>
      {taxiLandmark && (
        <AppAlertDialog
          {...alertDialog}
          title="ランドマークの削除"
          description={`ランドマーク: ${taxiLandmark.name} (ID: )を削除してもよろしいですか？`}
          onOk={handleDeleteOk}
        />
      )}
    </>
  );
}
