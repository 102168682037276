import { Box } from "@mui/material";
import { TaxiCompanyResponse } from "src/openapi";
import { AppFormCard } from "src/components/app/common/AppFormLayout";
import TaxiCompanyForm from "src/components/app/taxiCompany/TaxiCompanyForm";
import TaxiCompanyScheduleWeeklyForm from "src/components/app/taxiCompany/TaxiCompanyScheduleWeeklyForm";

type Props = {
  currentTaxiCompany?: TaxiCompanyResponse;
  onSuccess?(): void;
};

export default function TaxiCompanyDetailDefault(props: Props) {
  if (!props.currentTaxiCompany) {
    return null;
  }

  return (
    <Box>
      <AppFormCard title="基本情報">
        <Box>
          <TaxiCompanyForm
            isEdit={true}
            currentTaxiCompany={props.currentTaxiCompany}
            onSuccess={props.onSuccess}
          />
        </Box>
      </AppFormCard>
      <Box marginTop="1rem"></Box>
      <AppFormCard
        title="受付可能時間設定(曜日ルール)"
        caption="曜日ごとのルール設定が可能です"
      >
        <TaxiCompanyScheduleWeeklyForm
          currentTaxiCompany={props.currentTaxiCompany}
        />
      </AppFormCard>
    </Box>
  );
}
