import { RHFSelect } from "src/components/hook-form";
import { useTaxiCompanyListQuery } from "src/hooks/coreApi/useTaxiCompanyListQuery";
import { TaxiCompanyApiTaxiCompanyListRequest } from "src/openapi";

type TaxiCompanySelectProps = {
  name: string;
  label: string;
  req?: TaxiCompanyApiTaxiCompanyListRequest;
  disabled?: boolean;
};

export default function TaxiCompanySelect(props: TaxiCompanySelectProps) {
  const taxicompanyListQuery = useTaxiCompanyListQuery(props.req || {});

  const taxiCompanies = taxicompanyListQuery?.data?.taxiCompanies || [];

  if (taxicompanyListQuery.isLoading) {
    return null;
  }

  return (
    <RHFSelect
      native
      name={props.name}
      label={props.label}
      disabled={props.disabled}
    >
      <option value="">タクシー会社を選択してください</option>
      {taxicompanyListQuery.isFetched &&
        taxiCompanies.length > 0 &&
        taxiCompanies.map((taxicompany) => {
          return (
            <option key={taxicompany.id} value={taxicompany.id}>
              {taxicompany.name}
            </option>
          );
        })}
    </RHFSelect>
  );
}
