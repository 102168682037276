import { Button, Card, Container } from "@mui/material";
import {
  DataGridPremium,
  GridColDef,
  GridRenderCellParams,
  GridToolbar,
  jaJP,
} from "@mui/x-data-grid-premium";
import { useEffect, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuthContext } from "src/auth/useAuthContext";
import AppContainer from "src/components/app/common/AppContainer";
import Iconify from "src/components/iconify";
import { useTaxiCompanyListQuery } from "src/hooks/coreApi/useTaxiCompanyListQuery";
import { TaxiCompanyResponse } from "src/openapi";
import { PATH_DASHBOARD } from "src/routes/paths";

export default function TaxiCompanyList() {
  const placeQuery = useTaxiCompanyListQuery({});
  const taxiCompanies = placeQuery?.data?.taxiCompanies;
  const { user } = useAuthContext();
  const navigate = useNavigate();

  // タクシー会社ユーザーの場合詳細へリダイレクト
  useEffect(() => {
    if (user?.taxiCompanyId) {
      navigate(`/taxi/companies/${user?.taxiCompanyId}`);
    }
  }, [user?.taxiCompanyId]);

  const columns = useMemo<GridColDef[]>(() => {
    return [
      { field: "id", headerName: "ID" },
      {
        field: "name",
        headerName: "名前",
        renderCell(params: GridRenderCellParams<string, TaxiCompanyResponse>) {
          return (
            <Link to={`/taxi/companies/${params.row.id}`}>{params.value}</Link>
          );
        },
      },
      { field: "address", headerName: "住所", width: 360 },
      { field: "phone", headerName: "電話番号", width: 150 },
    ];
  }, []);

  return (
    <>
      <AppContainer
        meta={{
          title: "タクシー会社一覧",
        }}
        breadcrumbs={{
          heading: "タクシー会社一覧",
          links: [
            { name: "ホーム", href: PATH_DASHBOARD.root },
            { name: "タクシー会社" },
          ],
          action: (
            <>
              <Button
                component={Link}
                to="/taxi/companies/add"
                variant="contained"
                startIcon={<Iconify icon="eva:plus-fill" />}
              >
                新規追加
              </Button>
            </>
          ),
        }}
      >
        <Card style={{ height: "400px", width: "100%" }}>
          <DataGridPremium
            rows={taxiCompanies || []}
            columns={columns}
            isRowSelectable={() => false}
            components={{ Toolbar: GridToolbar }}
            localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
            filterMode="client"
          />
        </Card>
      </AppContainer>
    </>
  );
}
