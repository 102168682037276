import { useParams } from "react-router-dom";
import AppContainer from "src/components/app/common/AppContainer";
import { useTrashCalendarListQuery } from "src/hooks/coreApi/useTrashCalendarListQuery";
import { PATH_DASHBOARD } from "src/routes/paths";
import TrashCalendarForm from "src/components/app/trashCalendar/TrashCalendarForm";

export default function TrashCalendarAdd() {
  return (
    <>
      <AppContainer
        meta={{
          title: "ごみ出しカレンダー登録",
        }}
        breadcrumbs={{
          heading: "ごみ詳細",
          links: [
            { name: "ホーム", href: PATH_DASHBOARD.root },
            { name: "ごみ出し一覧", href: PATH_DASHBOARD.trash.calendar },
            { name: "ごみ出しカレンダー登録" },
          ],
        }}
      >
        <TrashCalendarForm />
      </AppContainer>
    </>
  );
}
