import { useNavigate, useParams } from "react-router-dom";
import { ContentType } from "src/hooks/coreApi/useContentListQuery";
import { ContentResponse } from "src/openapi/api";
import AppErrorAlert from "../../common/AppErrorAlert";
import ContentBousaiForm from "./ContentBousaiForm";
import ContentDekigotoForm from "./ContentDekigotoForm";
import ContentKairanForm from "./ContentKairanForm";
import ContentKinkyuForm from "./ContentKinkyuForm";
import ContentMusenForm from "./ContentMusenForm";

export type ContentFormProps = {
  isEdit?: boolean;
  type: ContentType;
  currentContent?: ContentResponse;
  onSuccess?(): void;
};

export default function ContentForm(props: ContentFormProps) {
  const { type, ...rest } = props;

  switch (type) {
    case "bousai":
      return <ContentBousaiForm {...rest} />;
    case "dekigoto":
      return <ContentDekigotoForm {...rest} />;
    case "kinkyu":
      return <ContentKinkyuForm {...rest} />;
    case "musen":
      return <ContentMusenForm {...rest} />;
    case "kairan":
      return <ContentKairanForm {...rest} />;
    default:
      return <AppErrorAlert error="利用できないコンテンツ種類です" />;
  }
}
