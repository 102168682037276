import { Box, Button } from "@mui/material";
import { TaxiCompanyResponse } from "src/openapi";
import TaxiCompanyScheduleTable, {
  TaxiCompanyScheduleTableDataRow,
  useTaxiCompanySchedulTableValues,
} from "../TaxiCompanyScheduleTable";
import { useEffect, useMemo } from "react";
import dayjs, { Dayjs } from "dayjs";
import { useSnackbar } from "notistack";
import { useTaxiDailyScheduleListQuery } from "src/hooks/coreApi/useTaxiDailyScheduleListQuery";
import { useTaxiDailyScheduleSaveMutation } from "src/hooks/coreApi/useTaxiDailyScheduleSaveMutation";
import { useTaxiWeeklyScheduleListQuery } from "src/hooks/coreApi/useTaxiWeeklyScheduleListQuery";

type Props = {
  isEdit?: boolean;
  currentTaxiCompany?: TaxiCompanyResponse;
  onSuccess?(): void;
};

export default function TaxiCompanyScheduleDailyForm(props: Props) {
  // 始点
  const minDate = useMemo<Dayjs>(() => {
    return dayjs().startOf("week");
  }, []);

  // 終点 2ヶ月後まで
  const maxDate = useMemo<Dayjs>(() => {
    return dayjs().add(2, "month").startOf("day");
  }, [minDate]);

  const { enqueueSnackbar } = useSnackbar();

  const taxiDailyScheduleSaveMutation = useTaxiDailyScheduleSaveMutation();

  const taxiDailyScheduleListQuery = useTaxiDailyScheduleListQuery({
    taxiCompanyId: props.currentTaxiCompany?.id,
    startDate: minDate.format("YYYY-MM-DD"),
    endDate: maxDate.format("YYYY-MM-DD"),
  });

  const taxiWeeklyScheduleQuery = useTaxiWeeklyScheduleListQuery({
    taxiCompanyId: props.currentTaxiCompany?.id,
  });

  useEffect(() => {
    taxiDailyScheduleListQuery.refetch();
    taxiWeeklyScheduleQuery.refetch();
  }, []);

  const taxiDailySchedules = useMemo(() => {
    return taxiDailyScheduleListQuery.data?.schedules || [];
  }, [taxiDailyScheduleListQuery.data]);

  const taxiWeeklySchedules = useMemo(() => {
    return (
      taxiWeeklyScheduleQuery.data || {
        sunTimes: null,
        monTimes: null,
        tueTimes: null,
        wedTimes: null,
        thuTimes: null,
        friTimes: null,
        satTimes: null,
      }
    );
  }, [taxiWeeklyScheduleQuery.data]);

  const dataRows = useMemo<TaxiCompanyScheduleTableDataRow[]>(() => {
    const rows = taxiDailySchedules?.map((v) => {
      return {
        day: dayjs(v.date).format("YYYY-MM-DD"),
        times: v.times,
      };
    });

    return rows;
  }, [taxiDailySchedules]);

  const tableValues = useTaxiCompanySchedulTableValues();

  const onClickSubmit = async () => {
    taxiDailyScheduleSaveMutation.mutate(
      {
        taxiDailyScheduleSaveRequest: {
          taxiCompanyId: props.currentTaxiCompany?.id || 0,
          schedules: tableValues.map((v) => {
            return {
              date: v.day,
              times: v.times,
            };
          }),
        },
      },
      {
        onSuccess() {
          enqueueSnackbar("更新しました");
        },
        onError() {
          enqueueSnackbar("更新に失敗しました", { variant: "error" });
        },
      }
    );
  };

  if (!props.currentTaxiCompany) {
    return null;
  }

  return (
    <Box>
      <TaxiCompanyScheduleTable
        dataRows={dataRows}
        headerType="day"
        startTime={props.currentTaxiCompany.startBusinessHour}
        endTime={props.currentTaxiCompany.endBusinessHour}
        minDate={minDate}
        maxDate={maxDate}
        callbackDisabled={(day, time) => {
          const date = dayjs(day);
          switch (date.day()) {
            case 0:
              return !!taxiWeeklySchedules?.sunTimes?.includes(time);
            case 1:
              return !!taxiWeeklySchedules?.monTimes?.includes(time);
            case 2:
              return !!taxiWeeklySchedules?.tueTimes?.includes(time);
            case 3:
              return !!taxiWeeklySchedules?.wedTimes?.includes(time);
            case 4:
              return !!taxiWeeklySchedules?.thuTimes?.includes(time);
            case 5:
              return !!taxiWeeklySchedules?.friTimes?.includes(time);
            case 6:
              return !!taxiWeeklySchedules?.satTimes?.includes(time);
            default:
              return false;
          }
        }}
      />
      <Box marginTop="1rem" textAlign="right">
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={onClickSubmit}
        >
          保存する
        </Button>
      </Box>
    </Box>
  );
}
