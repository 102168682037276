import { TextField } from "@mui/material";
import dayjs from "dayjs";
import { useSnackbar } from "notistack";
import { useEffect, useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { RHFCheckbox, RHFTextField } from "src/components/hook-form";
import FormProvider from "src/components/hook-form/FormProvider";
import useFormError from "src/hooks/app/form/useFormError";
import { useTopicSaveByOfficeMutation } from "src/hooks/coreApi/useTopicSaveByOfficeMutation";
import { fDate } from "src/utils/formatTime";
import AppDescriptionField from "../../common/AppDescriptionField";
import AppFormLayout, {
  AppFormButtonGroup,
  AppFormError,
  AppFormErrorGroup,
  AppFormGroup,
  AppFormCard,
  AppFormSubmitButton,
} from "../../common/AppFormLayout";

type UserTaxiDiscountFormValues = {
  commonName: string;
  kana: string;
  telephone: string;
  mailaddress: string;
  address: string;
  zipcode: string;
  taxiDiscountFlag: boolean;
  taxiDiscountAppliedDate: string;
};

type Props = {
  currentUser: any; // TODO
  onSuccess?(): void;
};

export default function UserTaxiDiscountForm(props: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const topicSaveByOfficeMutation = useTopicSaveByOfficeMutation();

  const defaultValues = useMemo<UserTaxiDiscountFormValues>(() => {
    return {
      commonName:
        props.currentUser.userInfo?.commonName ||
        props.currentUser.nickname ||
        "",
      kana:
        props.currentUser.userInfo?.kana ||
        props.currentUser.nicknameKana ||
        "",
      telephone:
        props.currentUser.userInfo?.telephone ||
        props.currentUser.userInfo?.phone ||
        "",
      mailaddress:
        props.currentUser.userInfo?.mailaddress ||
        props.currentUser.userInfo?.email ||
        "",
      address: props.currentUser.userInfo?.address || "",
      zipcode: props.currentUser.userInfo?.zipcode || "",
      taxiDiscountFlag: props.currentUser.taxiDiscountFlag,
      // TODO API修正 golangのゼロ値デフォルト
      taxiDiscountAppliedDate:
        props.currentUser.taxiDiscountAppliedDate === "0001-01-01T00:00:00Z"
          ? dayjs().format("YYYY-MM-DD")
          : dayjs(props.currentUser.taxiDiscountAppliedDate).format(
              "YYYY-MM-DD"
            ),
    };
  }, [props.currentUser]);

  const userTaxiDiscountForm = useForm<UserTaxiDiscountFormValues>({
    defaultValues,
  });

  useEffect(() => {
    userTaxiDiscountForm.reset(defaultValues);
  }, [props]);

  const onSuccess = () => {
    enqueueSnackbar("更新しました");
    userTaxiDiscountForm.reset();
    props?.onSuccess && props.onSuccess();
  };

  const onError =
    useFormError<UserTaxiDiscountFormValues>(userTaxiDiscountForm);

  const onSubmit = (values: UserTaxiDiscountFormValues) => {
    topicSaveByOfficeMutation.mutate(
      {
        topicSaveByOfficeRequest: {
          userId: props.currentUser.id,
          commonName: values.commonName,
          kana: values.kana,
          address: values.address,
          telephone: values.telephone || null,
          mailaddress: values.mailaddress || null,
          zipcode: values.zipcode || null,
          taxiDiscountFlag: values.taxiDiscountFlag,
          taxiDiscountAppliedDate: values.taxiDiscountAppliedDate,
        },
      },
      {
        onSuccess,
        onError,
      }
    );
  };

  const w = userTaxiDiscountForm.watch("taxiDiscountAppliedDate");

  return (
    <FormProvider
      methods={userTaxiDiscountForm}
      onSubmit={userTaxiDiscountForm.handleSubmit(onSubmit)}
    >
      <AppFormLayout>
        <AppFormCard>
          {!userTaxiDiscountForm.formState.isValid && (
            <AppFormErrorGroup>
              <AppFormError<UserTaxiDiscountFormValues>
                errors={userTaxiDiscountForm.formState.errors}
              />
            </AppFormErrorGroup>
          )}

          <AppFormGroup>
            <AppDescriptionField label="TOPIC ID">
              {props.currentUser?.userInfo?.topicId || "未登録"}
            </AppDescriptionField>
          </AppFormGroup>
          <AppFormGroup>
            <RHFTextField
              name="taxiDiscountAppliedDate"
              label="申込日"
              type="date"
              onChange={(evt) => {
                userTaxiDiscountForm.setValue(
                  "taxiDiscountAppliedDate",
                  evt.target.value
                );
              }}
            />
            <RHFTextField name="commonName" label="氏名" />
            <RHFTextField name="kana" label="氏名カナ" />
            <RHFTextField name="zipcode" label="郵便番号" />
            <RHFTextField name="address" label="住所" />
            <RHFTextField name="telephone" label="電話番号" />
            <RHFTextField name="mailaddress" label="連絡先(Eメールアドレス)" />
            <RHFCheckbox name="taxiDiscountFlag" label="割引適用" />
          </AppFormGroup>

          <AppFormButtonGroup>
            <AppFormSubmitButton loading={topicSaveByOfficeMutation.isLoading}>
              保存する
            </AppFormSubmitButton>
          </AppFormButtonGroup>
        </AppFormCard>
      </AppFormLayout>
    </FormProvider>
  );
}
