import { MenuList, Paper, Stack } from "@mui/material";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { ReactNode, useState } from "react";
import { ClickAwayListener } from "@mui/base";

type AppMenuOption = {
  onClick(): void;
  label: ReactNode;
};

type AppMenuProps = {
  options: AppMenuOption[];
  children: ReactNode;
};

export default function AppMenu(props: AppMenuProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    // Click-Away Listener
    // ref: https://mui.com/base/react-click-away-listener/
    <ClickAwayListener onClickAway={handleClose}>
      <div>
        <Button
          size="large"
          color="inherit"
          variant="contained"
          onClick={handleClick}
        >
          {props.children}
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <Stack direction="row" spacing={2}>
            <Paper>
              <MenuList
                autoFocusItem={open}
                id="composition-menu"
                aria-labelledby="composition-button"
              >
                {props.options.map((option) => {
                  return (
                    <MenuItem onClick={option.onClick}>{option.label}</MenuItem>
                  );
                })}
              </MenuList>
            </Paper>
          </Stack>
        </Menu>
      </div>
    </ClickAwayListener>
  );
}
