import { Navigate, useRoutes } from "react-router-dom";
// auth
import AuthGuard from "../auth/AuthGuard";
import GuestGuard from "../auth/GuestGuard";
// layouts
import CompactLayout from "../layouts/compact";
import DashboardLayout from "../layouts/dashboard";
// config
import { PATH_AFTER_LOGIN } from "../config-global";
//
import { Page404, PageOne, PageTwo, PageThree, LoginPage } from "./elements";
import { PATH_AUTH, PATH_DASHBOARD } from "./paths";
import UserList from "src/pages/user/UserList";
import UserEdit from "src/pages/user/UserDetail";
import Home from "src/pages/Home";
import PlaceList from "src/pages/place/PlaceList";
import PlaceEdit from "src/pages/place/PlaceEdit";
import PlaceAdd from "src/pages/place/PlaceAdd";
import UserAdd from "src/pages/user/UserAdd";
import ContentList from "src/pages/contents/ContentList";
import ContentAdd from "src/pages/contents/ContentAdd";
import ContentEdit from "src/pages/contents/ContentEdit";
import TaxiAchievementList from "src/pages/taxiAchievement/TaxiAchievementList";
import TaxiReservationList from "src/pages/taxiReservation/TaxiReservationList";
import RoomList from "src/pages/room/RoomList";
import RoomAdd from "src/pages/room/RoomAdd";
import RoomEdit from "src/pages/room/RoomEdit";
import VPNTest from "src/pages/setting/VPNTest";
import TrashCalendarList from "src/pages/trashCalendar/TrashCalendarList";
import TrashCalendarAdd from "src/pages/trashCalendar/TrashCalendarAdd";
import TrashCalendarEdit from "src/pages/trashCalendar/TrashCalendarEdit";
import TaxiAchievementEdit from "src/pages/taxiAchievement/TaxiAchievementEdit";
import TaxiAchievementAdd from "src/pages/taxiAchievement/TaxiAchievementAdd";
import TaxiCompanyList from "src/pages/taxiCompany/TaxiCompanyList";
import TaxiCompanyDetail from "src/pages/taxiCompany/TaxiCompanyDetail";
import TaxiCompanyAdd from "src/pages/taxiCompany/TaxiCompanyAdd";
import TaxiLandmarkList from "src/pages/taxiLandmark/TaxiLandmarkList";
import TaxiLandmarkAdd from "src/pages/taxiLandmark/TaxiLandmarkAdd";
import TaxiLandmarkEdit from "src/pages/taxiLandmark/TaxiLandmarkEdit";
import TaxiReservationEdit from "src/pages/taxiReservation/TaxiReservationEdit";
import TaxiReservationAdd from "src/pages/taxiReservation/TaxiReservationAdd";

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: PATH_AUTH.login,
      element: (
        <GuestGuard>
          <LoginPage />
        </GuestGuard>
      ),
    },
    {
      path: PATH_DASHBOARD.root,
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: "home", element: <Home /> },
        {
          path: "users",
          children: [
            { path: "", element: <UserList /> },
            { path: "add", element: <UserAdd /> },
            { path: ":id", element: <UserEdit /> },
          ],
        },
        {
          path: "places",
          children: [
            { path: "", element: <PlaceList /> },
            { path: "add", element: <PlaceAdd /> },
            { path: ":id", element: <PlaceEdit /> },
          ],
        },
        {
          path: "contents",
          children: [
            {
              path: ":type",
              children: [
                { path: "", element: <ContentList /> },
                { path: "add", element: <ContentAdd /> },
                { path: ":id", element: <ContentEdit /> },
              ],
            },
          ],
          // children: [
          //   { path: "", element: <ContentList /> },
          //   { path: "add", element: <ContentAdd /> },
          //   { path: ":id", element: <ContentEdit /> },
          // ],
        },
        {
          path: "taxi",
          children: [
            {
              path: "companies",
              children: [
                { path: "", element: <TaxiCompanyList /> },
                { path: "add", element: <TaxiCompanyAdd /> },
                { path: ":id", element: <TaxiCompanyDetail /> },
              ],
            },
            {
              path: "reservations",
              // element: <TaxiReservationList />,
              children: [
                { path: "", element: <TaxiReservationList /> },
                { path: "add", element: <TaxiReservationAdd /> },
                { path: ":id", element: <TaxiReservationEdit /> },
              ],
            },
            {
              path: "achievements",
              children: [
                { path: "", element: <TaxiAchievementList /> },
                { path: "add", element: <TaxiAchievementAdd /> },
                { path: ":id", element: <TaxiAchievementEdit /> },
              ],
            },
            {
              path: "landmarks",
              children: [
                { path: "", element: <TaxiLandmarkList /> },
                { path: "add", element: <TaxiLandmarkAdd /> },
                { path: ":id", element: <TaxiLandmarkEdit /> },
              ],
            },
          ],
        },
        {
          path: "video",
          children: [
            {
              path: "rooms",
              children: [
                { path: "", element: <RoomList /> },
                { path: "add", element: <RoomAdd /> },
                { path: ":id", element: <RoomEdit /> },
              ],
            },
          ],
        },
        {
          path: "trashCalendars",
          children: [
            { path: "", element: <TrashCalendarList /> },
            { path: "add", element: <TrashCalendarAdd /> },
            { path: ":id", element: <TrashCalendarEdit /> },
          ],
        },
        { path: "setting/vpn-test", element: <VPNTest /> },
      ],
    },
    {
      element: <CompactLayout />,
      children: [{ path: "404", element: <Page404 /> }],
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}
