import AppContainer from "src/components/app/common/AppContainer";
import { PATH_DASHBOARD } from "src/routes/paths";
import RoomForm from "src/components/app/room/RoomForm";

export default function RoomAdd() {
  return (
    <>
      <AppContainer
        meta={{
          title: "ルーム作成",
        }}
        breadcrumbs={{
          heading: "ルーム作成",
          links: [
            { name: "ホーム", href: PATH_DASHBOARD.root },
            { name: "ルーム作成" },
          ],
        }}
      >
        <RoomForm />
      </AppContainer>
    </>
  );
}
