import { Alert } from "@mui/material";
import React from "react";
import { Navigate } from "react-router";
import { useAuthContext } from "src/auth/useAuthContext";
import { getUserAuthTypeOption } from "src/utils/user";

export default function Home() {
  const { user } = useAuthContext();
  const role = getUserAuthTypeOption(user)?.type || "";
  // ログイン後に表示する画面

  // 管理者はユーザー一覧
  if (role === "office") {
    return <Navigate to="/users" replace />;
  }

  // タクシー会社は予約一覧
  if (role === "taxiCompany") {
    return <Navigate to="/taxi/reservations" replace />;
  }

  return <Alert severity="error">この機能を利用する権限がありません</Alert>;
}
