import { Chip } from "@mui/material";
import { UserResponse } from "src/openapi";
import { getUserAuthTypeOption } from "src/utils/user";

type Props = {
  user: UserResponse;
};

export default function UserAuthTypeChip(props: Props) {
  const authTypeOption = getUserAuthTypeOption(props.user);
  return <Chip label={authTypeOption?.name} />;
}
