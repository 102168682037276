// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_DASHBOARD = "";

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  login: "/login",
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  one: path(ROOTS_DASHBOARD, "/one"),
  two: path(ROOTS_DASHBOARD, "/two"),
  three: path(ROOTS_DASHBOARD, "/three"),
  place: path(ROOTS_DASHBOARD, "/places"),
  area: path(ROOTS_DASHBOARD, "/areas"),
  home: path(ROOTS_DASHBOARD, "/home"),
  user: {
    list: path(ROOTS_DASHBOARD, "/users"),
    create: path(ROOTS_DASHBOARD, "/users/new"),
    edit: (id: number) => path(ROOTS_DASHBOARD, `/users/${id}`),
  },
  video: {
    root: path(ROOTS_DASHBOARD, "/video"),
    room: path(ROOTS_DASHBOARD, "/video/rooms"),
  },
  trash: {
    root: path(ROOTS_DASHBOARD, "/trashCalendars"),
    calendar: path(ROOTS_DASHBOARD, "/trashCalendars"),
  },
  content: {
    root: path(ROOTS_DASHBOARD, "/contents"),
    dekigoto: path(ROOTS_DASHBOARD, "/contents/dekigoto"),
    musen: path(ROOTS_DASHBOARD, "/contents/musen"),
    bousai: path(ROOTS_DASHBOARD, "/contents/bousai"),
    kairan: path(ROOTS_DASHBOARD, "/contents/kairan"),
    kinkyu: path(ROOTS_DASHBOARD, "/contents/kinkyu"),
    shikyo: path(ROOTS_DASHBOARD, "/contents/shikyo"),
  },
  taxi: {
    root: path(ROOTS_DASHBOARD, "/taxi"),
    achievement: path(ROOTS_DASHBOARD, "/taxi/achievements"),
    reservation: path(ROOTS_DASHBOARD, "/taxi/reservations"),
    lamdmark: path(ROOTS_DASHBOARD, "/taxi/lamdmarks"),
    company: path(ROOTS_DASHBOARD, "/taxi/companies"),
    car: path(ROOTS_DASHBOARD, "/cars"),
    landmark: path(ROOTS_DASHBOARD, "/taxi/landmarks"),
  },
  setting: {
    root: path(ROOTS_DASHBOARD, "/setting/vpn-test"),
    vpnTest: path(ROOTS_DASHBOARD, "/setting/vpn-test"),
  },
};
