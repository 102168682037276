import { forwardRef } from "react";
import { Link as RouterLink } from "react-router-dom";
// @mui
import { useTheme } from "@mui/material/styles";
import { Box, Link, BoxProps } from "@mui/material";

// ----------------------------------------------------------------------

export interface LogoProps extends BoxProps {
  disabledLink?: boolean;
}

const Logo = forwardRef<HTMLDivElement, LogoProps>(
  ({ disabledLink = false, sx, ...other }, ref) => {
    const theme = useTheme();

    const PRIMARY_LIGHT = theme.palette.primary.light;

    const PRIMARY_MAIN = theme.palette.primary.main;

    const PRIMARY_DARK = theme.palette.primary.dark;

    // OR using local (public folder)
    // -------------------------------------------------------
    // const logo = (
    //   <Box
    //     component="img"
    //     src="/logo/logo_single.svg" => your path
    //     sx={{ width: 40, height: 40, cursor: 'pointer', ...sx }}
    //   />
    // );

    const logo = (
      <Box
        ref={ref}
        component="div"
        sx={{
          width: 40,
          height: 40,
          display: "inline-flex",
          ...sx,
        }}
        {...other}
      >
        <svg
          id="_レイヤー_1"
          data-name="レイヤー 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 183.22 154.96"
        >
          <path
            d="m172.67,154.96c-.29,0-.59-.03-.88-.1l-49.86-11.57c-11.06,2.39-22.84,1.97-34.13-1.23-22.34-6.33-37.79-21.42-44.67-43.64-3.61-11.65-4.45-23.91-2.63-35.68-2.85-2.07-5.71-4.43-8.57-7.1-1.57-1.47-1.66-3.93-.19-5.5,1.47-1.57,3.93-1.66,5.5-.19,1.68,1.57,3.36,3.02,5.04,4.36,1.45-5.33,3.46-10.49,6.04-15.38,9.26-17.53,24.72-30.05,44.71-36.19,29.31-9.01,63.39,4.78,79.28,32.08,10.87,18.67,13.57,37.27,8.27,56.85-3.41,12.6-9.16,21.68-17.07,26.99-6.33,4.66-16.36,5.44-24.82,1.83-7.83-3.34-12.91-9.74-13.94-17.57-1.34-10.18,5.67-16.32,10.31-20.38,1.16-1.01,2.25-1.97,3.06-2.84.04-.04.16-.17.13-.38-.03-.21-.17-.3-.22-.33-6.33-3.85-15.9-10.91-23.44-19.21-15.3,14.12-40.35,22.61-66.85,7.62-.99,9.52-.07,19.33,2.83,28.7,6.06,19.57,19.67,32.87,39.36,38.45,10.34,2.93,21.11,3.25,31.15.93.58-.13,1.18-.13,1.76,0l38.92,9.03-9.81-11.16c-1.42-1.61-1.26-4.08.35-5.5,1.61-1.42,4.08-1.26,5.49.35l17.81,20.26c1.12,1.27,1.29,3.12.42,4.57-.71,1.19-1.99,1.9-3.34,1.9Zm-52.72-100.8c6.07,6.81,14.31,13.45,22.13,18.2,2.14,1.3,3.55,3.46,3.89,5.93.34,2.47-.45,4.93-2.16,6.76-1.07,1.14-2.31,2.23-3.62,3.37-5.14,4.5-8.46,7.88-7.72,13.5.91,6.89,6.2,10.11,9.28,11.42,5.92,2.52,13.03,2.1,17.29-1.04,6.45-4.33,11.13-11.93,14.03-22.66,4.8-17.71,2.42-33.88-7.48-50.9C153.59,18.12,124.89,1.11,95.33,10.19c-17.97,5.52-31.84,16.72-40.12,32.39-2.76,5.23-4.8,10.82-6.11,16.59,23.98,15.05,46.36,7.94,60.56-5.42-3.65-5.08-6.21-10.41-6.67-15.63-.52-5.9,1.68-11.19,6.35-15.3,2.39-2.11,5.61-3.19,8.8-2.95,3.13.23,5.95,1.64,7.94,3.97,5.81,6.83,3.09,19.18-6.14,30.32Zm-2.67-26.54c-1.03,0-2.03.37-2.79,1.04-2.86,2.52-4.05,5.3-3.74,8.77.28,3.19,1.84,6.75,4.27,10.41,6.1-7.99,7.8-15.82,5.14-18.96-.62-.73-1.53-1.18-2.56-1.25-.1,0-.2-.01-.31-.01Z"
            fill="#f39800"
          />
          <circle cx="70.4" cy="92.47" r="5.13" fill="#8fc31f" />
          <circle cx="112.03" cy="94.47" r="5.13" fill="#8fc31f" />
          <path
            d="m91.53,119.41c-.58,0-1.17-.02-1.78-.07-5.3-.39-13.15-4.7-13.91-10.83-.19-1.57.93-3.01,2.5-3.2,1.58-.19,3.01.93,3.2,2.5.3,2.44,4.9,5.53,8.63,5.81,5.25.39,8.92-1.39,11.22-5.42.78-1.38,2.54-1.86,3.92-1.07,1.38.79,1.86,2.54,1.07,3.92-3.13,5.5-8.25,8.37-14.85,8.37Z"
            fill="#8fc31f"
          />
          <path
            d="m15.16,44.64c-5.56,10.62.88,16.41,6.19,16.36,2.99-.03,12.38-4.38.57-15.71-1.57-1.5,17.21,5.89,15.14-7.88-.74-4.92-8.77-9.58-16.02-.02,3.85-5.08,1.97-13.08-4.44-13.03-4.99.04-10.49,2.93-3.78,14.73C9.01,37.15-.21,37.68,0,43.99c.48,10.11,11.02,9.34,15.16.65Z"
            fill="#8fc31f"
          />
        </svg>
      </Box>
    );

    if (disabledLink) {
      return logo;
    }

    return (
      <Link component={RouterLink} to="/" sx={{ display: "contents" }}>
        {logo}
      </Link>
    );
  }
);

export default Logo;
