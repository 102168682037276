import { TaxiCompanyApiTaxiCompanyListRequest } from "../../openapi";
import appApiClient from "./coreApiClient";
import useBaseQuery from "./useBaseQuery";

export const useTaxiCompanyListQuery = (
  req: TaxiCompanyApiTaxiCompanyListRequest
) =>
  useBaseQuery({
    queryKey: ["taxiCompanyList", req],
    queryFn: (config) =>
      appApiClient.taxiCompanyApi.taxiCompanyList(req, config),

  });
