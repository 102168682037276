import { FieldValues, UseFormReturn } from "react-hook-form";
import Axios, { AxiosError } from "axios";
import { useCallback } from "react";

type ErrorResponse = {
  [key: string]: string[];
};

export default function useFormError<TFieldValues extends FieldValues>(
  form: UseFormReturn<TFieldValues>,
  fieldErrorMap?: {
    [key in string]: string;
  }
) {
  return useCallback(
    (err: any) => {
      // Axiosエラー以外のエラーの場合
      if (!Axios.isAxiosError(err)) {
        return;
      }

      const axiosError = err as AxiosError<ErrorResponse>;
      // API側で想定しているバリデーションエラーの場合
      // TODO エラーレスポンスの型の揺れを修正
      let allErrors: any;
      if (axiosError?.response?.data?.errors) {
        allErrors = axiosError?.response?.data?.errors;
      } else {
        allErrors = axiosError?.response?.data || {};
      }

      if (Object.values(allErrors).length > 0) {
        Object.keys(allErrors).forEach((responseField) => {
          const errors = allErrors[responseField] as string[];
          // 明示的なマッピングをしたい場合
          let mappedField = responseField;
          if (!fieldErrorMap && fieldErrorMap?.[responseField]) {
            mappedField = fieldErrorMap[responseField];
          }

          // 変更されていないフィールドに対してエラーは表示しない
          const isIncludeDefaultValues = Object.keys(
            form.formState?.defaultValues || {}
          ).includes(responseField);

          if (!isIncludeDefaultValues) {
            return;
          }

          errors.forEach((err: string) => {
            // @ts-ignore TODO
            form.setError(mappedField, {
              message: err,
            });
          });
        });
      }
    },
    [form.formState.errors]
  );
}
