import { Box } from "@mui/material";
import { TaxiCompanyResponse } from "src/openapi";
import TaxiCompanyScheduleDailyForm from "src/components/app/taxiCompany/TaxiCompanyScheduleDailyForm ";
import { AppFormCard } from "src/components/app/common/AppFormLayout";

type Props = {
  currentTaxiCompany?: TaxiCompanyResponse;
};

export default function TaxiCompanyScheduleWeekly(props: Props) {
  if (!props.currentTaxiCompany) {
    return null;
  }

  return (
    <Box>
      <AppFormCard
        caption={
          <>
            日毎に予約受付時間を設定できます。
            <br />
            ※曜日ルールで×にしている時間は変更することができません。
          </>
        }
      >
        <TaxiCompanyScheduleDailyForm
          currentTaxiCompany={props.currentTaxiCompany}
        />
      </AppFormCard>
    </Box>
  );
}
