import { useSnackbar } from "notistack";
import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { RHFTextField } from "src/components/hook-form";
import FormProvider from "src/components/hook-form/FormProvider";
import useFormError from "src/hooks/app/form/useFormError";
import { useTaxiCompanyCreateMutation } from "src/hooks/coreApi/useTaxiCompanyCreateMutation";
import { useTaxiCompanyUpdateMutation } from "src/hooks/coreApi/useTaxiCompanyUpdateMutation";
import { TaxiCompanyResponse } from "src/openapi";
import AppDescriptionField from "../../common/AppDescriptionField";
import AppFormLayout, {
  AppFormButtonGroup,
  AppFormError,
  AppFormErrorGroup,
  AppFormGroup,
  AppFormSubmitButton,
} from "../../common/AppFormLayout";

type TaxiCompanyFormValues = {
  name: string;
  address: string;
  phone: string;
  startBusinessHour: string;
  endBusinessHour: string;
};

type Props = {
  isEdit?: boolean;
  currentTaxiCompany?: TaxiCompanyResponse;
  onSuccess?(): void;
};

export default function TaxiCompanyForm(props: Props) {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const taxiCompanyCreateMutation = useTaxiCompanyCreateMutation();
  const taxiCompanyUpdateMutation = useTaxiCompanyUpdateMutation();

  const defaultValues = useMemo<TaxiCompanyFormValues>(() => {
    return {
      name: props.currentTaxiCompany?.name || "",
      address: props.currentTaxiCompany?.address || "",
      phone: props.currentTaxiCompany?.phone || "",
      startBusinessHour: props.currentTaxiCompany?.startBusinessHour || "",
      endBusinessHour: props.currentTaxiCompany?.endBusinessHour || "",
    };
  }, [props.currentTaxiCompany]);

  const taxiCompanyForm = useForm<TaxiCompanyFormValues>({
    defaultValues,
  });

  useEffect(() => {
    taxiCompanyForm.reset(defaultValues);
  }, [props]);

  const onSuccess = () => {
    if (props.isEdit) {
      enqueueSnackbar("更新しました");
    }

    if (!props.isEdit) {
      enqueueSnackbar("追加しました");
      taxiCompanyForm.reset();
      navigate("/taxiCompanies");
    }
    props?.onSuccess && props.onSuccess();
  };

  const onError = useFormError<TaxiCompanyFormValues>(taxiCompanyForm);

  const onSubmit = (values: TaxiCompanyFormValues) => {
    if (props.isEdit && props?.currentTaxiCompany?.id) {
      taxiCompanyUpdateMutation.mutate(
        {
          id: props.currentTaxiCompany.id,
          taxiCompanyUpdateRequest: {
            name: values.name,
            address: values.address,
            phone: values.phone,
            startBusinessHour: values.startBusinessHour,
            endBusinessHour: values.endBusinessHour,
          },
        },
        {
          onSuccess,
          onError,
        }
      );
    }

    if (!props.isEdit) {
      taxiCompanyCreateMutation.mutate(
        {
          taxiCompanyCreateRequest: {
            name: values.name,
            address: values.address,
            phone: values.phone,
            startBusinessHour: values.startBusinessHour,
            endBusinessHour: values.endBusinessHour,
          },
        },
        {
          onSuccess,
          onError,
        }
      );
    }
  };

  return (
    <FormProvider
      methods={taxiCompanyForm}
      onSubmit={taxiCompanyForm.handleSubmit(onSubmit)}
    >
      <AppFormLayout>
        {!taxiCompanyForm.formState.isValid && (
          <AppFormErrorGroup>
            <AppFormError<TaxiCompanyFormValues>
              errors={taxiCompanyForm.formState.errors}
            />
          </AppFormErrorGroup>
        )}

        <AppFormGroup>
          {props.isEdit && props.currentTaxiCompany && (
            <AppFormGroup>
              <AppDescriptionField label="ID">
                {props.currentTaxiCompany.id}
              </AppDescriptionField>
            </AppFormGroup>
          )}
          <RHFTextField name="name" label="名前" />
          <RHFTextField name="address" label="住所" />
          <RHFTextField name="phone" label="電話番号" />
          <RHFTextField name="startBusinessHour" label="営業開始時間" />
          <RHFTextField name="endBusinessHour" label="営業終了時間" />
        </AppFormGroup>

        <AppFormButtonGroup>
          <AppFormSubmitButton
            loading={
              taxiCompanyCreateMutation.isLoading ||
              taxiCompanyUpdateMutation.isLoading
            }
          >
            {!props.isEdit ? "作成する" : "保存する"}
          </AppFormSubmitButton>
        </AppFormButtonGroup>
      </AppFormLayout>
    </FormProvider>
  );
}
