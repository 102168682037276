import { useRef } from 'react'
import { useAppConfirm } from './useAppConfirm'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'

type Props = {
  children: React.ReactNode
}

export default function AppConfirmProvider(props: Props) {
  const { isOpen, close, dialogProps } = useAppConfirm()
  const {
    title,
    body,
    isShowOk = true,
    okText,
    isShowCancel = true,
    cancelText,
  } = dialogProps ?? {}
  const cancelRef = useRef<any>()

  return (
    <>
      {props.children}
      <Dialog
        open={isOpen}
        onClose={() => close(false)}
      >
        <DialogTitle>
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {body}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {isShowCancel && (
            <Button onClick={() => close(false)}>{cancelText || 'キャンセル'}</Button>
          )}
          {isShowOk && (
            <Button onClick={() => close(true)} autoFocus>
              {okText || 'OK'}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  )
}
