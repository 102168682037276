import { ContentType } from "src/hooks/coreApi/useContentListQuery";

export type ContentStatus = "public" | "draft";

export type ContentStatusOption = { status: ContentStatus; label: string };

const contentStatusOptions: ContentStatusOption[] = [
  {
    status: "public",
    label: "公開済み",
  },
  {
    status: "draft",
    label: "非公開",
  },
];

type ContentTypeOption = {
  name: string;
}

export const contentTypeOptions: { [key in ContentType]: ContentTypeOption } = {
  'bousai': {
    name: '防災情報'
  },
  'kinkyu': {
    name: '緊急情報'
  },
  'dekigoto': {
    name: '神山ch'
  },
  'kairan': {
    name: 'お知らせ'
  },
  'musen': {
    name: '防災無線'
  }
}
