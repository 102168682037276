import { useAuthContext } from "./../../auth/useAuthContext";
import { useMutation } from "@tanstack/react-query";
import { AxiosRequestConfig, AxiosResponse } from "axios";

type BaseMutationOptions<TReq, TRes> = {
  mutationFn: (
    req: TReq,
    config: AxiosRequestConfig
  ) => Promise<AxiosResponse<TRes, any>>;
};

const useBaseMutation = <TReq, TRes>(
  options: BaseMutationOptions<TReq, TRes>
) => {
  const { mutationFn } = options;
  const mutation = useMutation({
    mutationFn: async (req: TReq) => {
      const config: AxiosRequestConfig = {
        headers: {},
      };
      const res = await mutationFn(req, config);
      return res;
    },
  });

  return mutation;
};

export default useBaseMutation;
