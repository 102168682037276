import { PATH_DASHBOARD } from "../../../routes/paths";
import ContentForm from "../../../components/app/content/ContentForm";
import AppContainer from "src/components/app/common/AppContainer";
import { useParams } from "react-router";
import { ContentType } from "src/hooks/coreApi/useContentListQuery";

export default function ContentAdd() {
  const { type } = useParams<{ type: ContentType }>();
  return (
    <>
      <AppContainer
        meta={{
          title: "コンテンツ登録",
        }}
        breadcrumbs={{
          heading: "コンテンツ登録",
          links: [
            { name: "ホーム", href: PATH_DASHBOARD.root },
            {
              name: "コンテンツ一覧",
              href: PATH_DASHBOARD.content[type as ContentType],
            },
            { name: "コンテンツ登録" },
          ],
        }}
      >
        <ContentForm type={type as ContentType} isEdit={false} />
      </AppContainer>
    </>
  );
}
