import { Button, Card } from "@mui/material";
import {
  DataGridPremium,
  getGridDateOperators,
  getGridSingleSelectOperators,
  getGridStringOperators,
  GridColDef,
  GridFilterModel,
  GridLinkOperator,
  GridRenderCellParams,
  GridToolbar,
  GridValueGetterParams,
  jaJP,
} from "@mui/x-data-grid-premium";
import dayjs from "dayjs";
import { useSnackbar } from "notistack";
import { useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAppConfirm } from "src/components/app/common/AppConfirm/useAppConfirm";
import AppContainer from "src/components/app/common/AppContainer";
import AppContextMenuButton from "src/components/app/common/AppContextMenuButton";
import { useTaxiAchievementDeleteMutation } from "src/hooks/coreApi/useTaxiAchievementDeleteMutation";
import { useTaxiAchievementListQuery } from "src/hooks/coreApi/useTaxiAchievementListQuery";
import {
  TaxiAchievementApiTaxiAchievementListRequest,
  TaxiAchievementResponse,
} from "src/openapi";
import { PATH_DASHBOARD } from "src/routes/paths";
import { downloadTripLog } from "src/utils/app/taxiAchievement";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import ja from "date-fns/locale/ja";
import { AdapterDateFns } from "@mui/x-date-pickers-pro/AdapterDateFns";
import { useTaxiCompanyListQuery } from "../../../hooks/coreApi/useTaxiCompanyListQuery";
import { FilterDateRange } from "../../../components/filter/FilterDateRange";
import Iconify from "src/components/iconify";
import { useAuthContext } from "src/auth/useAuthContext";

type TaxiAchievementListProps = {
  page: number;
  pageSize: number;
  withTaxiCompany: boolean;
  withDriverUser: boolean;
  withCustomerUser: boolean;
  withTaxiCar: boolean;
  rideAtFrom?: string;
  rideAtTo?: string;
  dropAtFrom?: string;
  dropAtTo?: string;
  taxiCompanyId?: number;
  customerUserId?: number;
};

const PAGE_SIZES = [100, 250, 500, 750, 1000];

export default function TaxiAchievementList() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { user } = useAuthContext();

  const initTaxiAchievementReq: TaxiAchievementListProps = {
    page: 0,
    pageSize: PAGE_SIZES[0],
    withTaxiCompany: true,
    withDriverUser: true,
    withCustomerUser: true,
    withTaxiCar: true,
  };

  const [taxiachievementReq, setTaxiachievementReq] =
    useState<TaxiAchievementApiTaxiAchievementListRequest>(
      initTaxiAchievementReq
    );
  const taxiachievementQuery = useTaxiAchievementListQuery({
    ...taxiachievementReq,
    page: taxiachievementReq?.page ? taxiachievementReq.page + 1 : 1, // DataGridのpagenationは0始まりにする必要があるため、+1する
  });
  const taxiAchievements = taxiachievementQuery?.data?.taxiAchievements;
  const rowCount = taxiachievementQuery?.data?.count;

  const taxiAchievementDeleteMutation = useTaxiAchievementDeleteMutation();

  const taxiCompaniesQuery = useTaxiCompanyListQuery({});
  const taxiCompanies = taxiCompaniesQuery?.data?.taxiCompanies;

  const driverUsersOptions = useMemo(() => {
    if (!taxiAchievements) return [];
    const driverUsers = taxiAchievements.map(
      (taxiAchievement) => taxiAchievement.driverUser
    );
    return Array.from(new Set(driverUsers)).map((driverUser) => ({
      value: driverUser?.id,
      label: driverUser?.nickname,
    }));
  }, [taxiAchievements]);

  const appConfirm = useAppConfirm();

  useEffect(() => {
    taxiachievementQuery.refetch();
  }, [taxiachievementReq]);

  const onClickDelete = async (taxiAchievement: TaxiAchievementResponse) => {
    const confirmOk = await appConfirm.open({
      title: "運行実績の削除",
      body: `ID: ${taxiAchievement.id}を削除しますか？`,
    });

    if (!confirmOk) {
      return;
    }

    taxiAchievementDeleteMutation.mutate(
      {
        id: taxiAchievement.id,
      },
      {
        onSuccess() {
          enqueueSnackbar(`ID: ${taxiAchievement.id}を削除しました`);
          taxiachievementQuery.refetch();
        },
        onError() {
          enqueueSnackbar("削除に失敗しました", { variant: "error" });
        },
      }
    );
  };

  const onClickTripLogDownload = (taxiAchievement: TaxiAchievementResponse) => {
    downloadTripLog(taxiAchievement);
  };

  const onPageChange = (page: number) => {
    setTaxiachievementReq({
      ...taxiachievementReq,
      page,
    });
  };

  const onPageSizeChange = (pageSize: number) => {
    setTaxiachievementReq({
      ...taxiachievementReq,
      pageSize,
    });
  };

  const onFilterChange = (filterModel: GridFilterModel) => {
    let newReq = initTaxiAchievementReq;
    for (const item of filterModel.items) {
      if (!item.value) continue;
      switch (item.columnField) {
        case "rideAt":
          newReq["rideAtFrom"] = item.value[0]
            ? dayjs(item.value[0]).format("YYYY-MM-DD")
            : undefined;
          newReq["rideAtTo"] = item.value[1]
            ? dayjs(item.value[1]).format("YYYY-MM-DD")
            : undefined;
          break;
        case "dropAt":
          newReq["dropAtFrom"] = item.value[0]
            ? dayjs(item.value[0]).format("YYYY-MM-DD")
            : undefined;
          newReq["dropAtTo"] = item.value[1]
            ? dayjs(item.value[1]).format("YYYY-MM-DD")
            : undefined;
          break;
        case "taxiCompanyName":
          newReq["taxiCompanyId"] = item.value;
          break;
        case "customerUser":
          newReq["customerUserId"] = item.value;
          break;
      }
    }
    setTaxiachievementReq(newReq);
  };

  const columns = useMemo(() => {
    return [
      {
        field: "contextMenu",
        headerName: "",
        width: 60,
        filterable: false,
        hide: user?.isOffice === false,
        renderCell(
          params: GridRenderCellParams<string, TaxiAchievementResponse>
        ) {
          return (
            <AppContextMenuButton
              options={[
                {
                  label: "編集する",
                  onClick() {
                    navigate("/taxi/achievements/" + params.row.id);
                  },
                },
                {
                  label: "削除する",
                  onClick() {
                    onClickDelete(params.row);
                  },
                },
                {
                  label: "GPSログをダウンロード",
                  onClick() {
                    onClickTripLogDownload(params.row);
                  },
                },
              ]}
            />
          );
        },
      },
      { field: "id", headerName: "ID", filterable: false },
      {
        field: "rideAt",
        headerName: "乗車日時",
        width: 160,
        valueGetter(
          params: GridValueGetterParams<string, TaxiAchievementResponse>
        ) {
          return dayjs(params.row.rideAt).format("YYYY-MM-DD HH:mm");
        },
        type: "date",
        filterOperators: getGridDateOperators()
          .filter((operator) => operator.value === "is")
          .map((operator) => ({
            ...operator,
            label: "…に等しい",
            InputComponent: FilterDateRange,
          })),
      },
      {
        field: "dropAt",
        headerName: "降車日時",
        width: 160,
        valueGetter(
          params: GridValueGetterParams<string, TaxiAchievementResponse>
        ) {
          return dayjs(params.row.dropAt).format("YYYY-MM-DD HH:mm");
        },
        type: "date",
        filterOperators: getGridDateOperators()
          .filter((operator) => operator.value === "is")
          .map((operator) => ({
            ...operator,
            label: "…に等しい",
            InputComponent: FilterDateRange,
          })),
      },
      {
        field: "taxiCompanyName",
        headerName: "タクシー会社",
        valueGetter(
          params: GridValueGetterParams<string, TaxiAchievementResponse>
        ) {
          return params.row.taxiCompany?.name;
        },
        type: "singleSelect",
        filterOperators: getGridSingleSelectOperators()
          .filter((operator) => operator.value === "is")
          .map((operator) => ({ ...operator, label: "…に等しい" })),
        valueOptions:
          taxiCompanies?.map((taxiCompany) => ({
            value: taxiCompany.id,
            label: taxiCompany.name,
          })) || [],
      },
      {
        field: "driverUser",
        headerName: "運転手ユーザー",
        valueGetter(
          params: GridValueGetterParams<string, TaxiAchievementResponse>
        ) {
          return params.row.driverUser?.nickname;
        },
        filterable: false,
      },
      {
        field: "customerUser",
        headerName: "乗客さあ・くるID",
        valueGetter(
          params: GridValueGetterParams<string, TaxiAchievementResponse>
        ) {
          return params.row.customerUser?.userInfo?.code;
        },
        type: "string",
        filterOperators: getGridStringOperators()
          .filter((operator) => operator.value === "equals")
          .map((operator) => ({ ...operator, label: "…に等しい" })),
      },
      {
        field: "totalPrice",
        headerName: "通常料金",
        filterable: false,
      },
      {
        field: "discountPrice",
        headerName: "助成額",
        filterable: false,
      },
      {
        field: "finalPrice",
        headerName: "支払い料金",
        filterable: false,
      },
      {
        field: "initialPrice",
        headerName: "初乗料金",
        filterable: false,
      },
      {
        field: "approachAutoDistance",
        headerName: "迎車距離(自動計測)",
        filterable: false,
      },
      {
        field: "approachAdjustDistance",
        headerName: "迎車距離(調整後)",
        filterable: false,
      },
      {
        field: "approachAutoPrice",
        headerName: "迎車料金(自動計測)",
        filterable: false,
      },
      {
        field: "approachAdjustPrice",
        headerName: "迎車料金(調整後)",
        filterable: false,
      },
      {
        field: "rideAutoDistance",
        headerName: "乗車距離(自動計測)",
        filterable: false,
      },
      {
        field: "rideAdjustDistance",
        headerName: "乗車距離(調整後)",
        filterable: false,
      },
      {
        field: "rideAutoPrice",
        headerName: "乗車料金(自動計測)",
        filterable: false,
      },
      {
        field: "rideAdjustPrice",
        headerName: "乗車料金(調整後)",
        filterable: false,
      },
      {
        field: "waitAutoSec",
        headerName: "待機時間(自動計測)",
        filterable: false,
      },
      {
        field: "waitAdjustSec",
        headerName: "待機時間(調整後)",
        filterable: false,
      },
      {
        field: "waitAutoPrice",
        headerName: "待機料金(自動計測)",
        filterable: false,
      },
      {
        field: "waitAdjustPrice",
        headerName: "待機料金(調整後)",
        filterable: false,
      },
      {
        field: "payMethod",
        headerName: "決済方法",
        filterable: false,
        valueGetter(
          params: GridValueGetterParams<string, TaxiAchievementResponse>
        ) {
          switch (params.row.payMethod) {
            case "money":
              return "現金";
            case "payPay":
              return "PayPay";
            case "credit":
              return "クレジット";
            default:
              return "不明";
          }
        },
      },
      {
        field: "disabilityDiscount",
        headerName: "障害者割引",
        filterable: false,
        valueGetter(
          params: GridValueGetterParams<string, TaxiAchievementResponse>
        ) {
          return params.row.disabilityDiscount ? "あり" : "なし";
        },
      },
      {
        field: "returningLicenseDiscount",
        headerName: "免許返納割引",
        filterable: false,
        valueGetter(
          params: GridValueGetterParams<string, TaxiAchievementResponse>
        ) {
          return params.row.disabilityDiscount ? "あり" : "なし";
        },
      },
      {
        field: "otherDiscount",
        headerName: "その他割引",
        filterable: false,
      },
    ] as GridColDef[];
  }, [taxiCompanies]);

  return (
    <>
      <AppContainer
        meta={{
          title: "運行実績一覧",
        }}
        breadcrumbs={{
          heading: "運行実績一覧",
          links: [
            { name: "ホーム", href: PATH_DASHBOARD.root },
            { name: "運行実績" },
          ],
          action: (
            <>
              {user?.isOffice === true && (
                <Button
                  component={Link}
                  to="/taxi/achievements/add"
                  variant="contained"
                  startIcon={<Iconify icon="eva:plus-fill" />}
                >
                  新規追加
                </Button>
              )}
            </>
          ),
        }}
      >
        <Card style={{ height: "1000px", width: "100%" }}>
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ja}>
            <DataGridPremium
              rows={taxiAchievements || []}
              columns={columns}
              isRowSelectable={() => false}
              components={{ Toolbar: GridToolbar }}
              localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
              filterMode="server"
              paginationMode="server"
              onFilterModelChange={onFilterChange}
              componentsProps={{
                filterPanel: {
                  linkOperators: [GridLinkOperator.And],
                },
              }}
              pagination
              page={taxiachievementReq.page}
              pageSize={taxiachievementReq.pageSize}
              onPageChange={onPageChange}
              onPageSizeChange={onPageSizeChange}
              rowCount={rowCount}
              rowsPerPageOptions={PAGE_SIZES}
            />
          </LocalizationProvider>
        </Card>
      </AppContainer>
    </>
  );
}
