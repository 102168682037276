import { Card } from "@mui/material";
import {
  DataGridPremium,
  getGridDateOperators,
  getGridSingleSelectOperators,
  getGridStringOperators,
  GridColDef,
  GridFilterModel,
  GridLinkOperator,
  GridRenderCellParams,
  GridToolbar,
  GridValueGetterParams,
  jaJP,
} from "@mui/x-data-grid-premium";
import dayjs from "dayjs";
import { useMemo, useState } from "react";
import AppContainer from "src/components/app/common/AppContainer";
import { useTaxiReservationListQuery } from "src/hooks/coreApi/useTaxiReservationListQuery";
import {
  TaxiReservationApiTaxiReservationListRequest,
  TaxiReservationResponse,
} from "src/openapi/api";
import { PATH_DASHBOARD } from "src/routes/paths";
import {
  getTaxiReservationStatusName,
  taxiReservationStatusOptions,
} from "src/utils/app/taxiReservation";
import { FilterDateRange } from "../../../components/filter/FilterDateRange";
import { AdapterDateFns } from "@mui/x-date-pickers-pro/AdapterDateFns";
import ja from "date-fns/locale/ja";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { useTaxiCompanyListQuery } from "../../../hooks/coreApi/useTaxiCompanyListQuery";

type TaxiReservationListProp = {
  withTaxiCar?: boolean;
  withTaxiCompany?: boolean;
  withUser?: boolean;
  rideAtFrom?: string;
  rideAtTo?: string;
  dropAtFrom?: string;
  dropAtTo?: string;
  taxiCompanyId?: number;
  status?: string;
  userInfoCode?: string;
};

export default function TaxiReservationList() {
  const initTaxiReservationReq: TaxiReservationListProp = {
    withTaxiCompany: true,
    withUser: true,
    withTaxiCar: true,
  };
  const [taxiReservationReq, setTaxiReservationReq] =
    useState<TaxiReservationApiTaxiReservationListRequest>(
      initTaxiReservationReq
    );
  const taxiachievementQuery = useTaxiReservationListQuery(taxiReservationReq);
  const taxiReservations = taxiachievementQuery?.data?.taxiReservations;

  const taxiCompaniesQuery = useTaxiCompanyListQuery({});
  const taxiCompanies = taxiCompaniesQuery?.data?.taxiCompanies;

  const columns = useMemo<GridColDef[]>(() => {
    return [
      { field: "id", headerName: "ID", filterable: false },
      {
        field: "createdAt",
        headerName: "作成日時",
        filterable: false,
        width: 150,
        valueGetter(
          params: GridValueGetterParams<Date, TaxiReservationResponse>
        ) {
          return dayjs(params.row.createdAt).toDate();
        },
        renderCell(
          params: GridRenderCellParams<Date, TaxiReservationResponse>
        ) {
          return dayjs(params.value).format("YYYY-MM-DD HH:mm");
        },
      },
      {
        field: "updatedAt",
        headerName: "最終更新日時",
        filterable: false,
        width: 150,
        valueGetter(
          params: GridValueGetterParams<Date, TaxiReservationResponse>
        ) {
          return dayjs(params.row.updatedAt).toDate();
        },
        renderCell(
          params: GridRenderCellParams<Date, TaxiReservationResponse>
        ) {
          return dayjs(params.value).format("YYYY-MM-DD HH:mm");
        },
      },
      {
        field: "rideAt",
        headerName: "乗車日時",
        width: 150,
        valueGetter(
          params: GridValueGetterParams<string, TaxiReservationResponse>
        ) {
          return params.row.rideAt;
        },
        type: "date",
        filterOperators: getGridDateOperators()
          .filter((operator) => operator.value === "is")
          .map((operator) => ({
            ...operator,
            label: "…に等しい",
            InputComponent: FilterDateRange,
          })),
      },
      {
        field: "dropAt",
        headerName: "降車日時",
        width: 150,
        valueGetter(
          params: GridValueGetterParams<string, TaxiReservationResponse>
        ) {
          return params.row.dropAt;
        },
        type: "date",
        filterOperators: getGridDateOperators()
          .filter((operator) => operator.value === "is")
          .map((operator) => ({
            ...operator,
            label: "…に等しい",
            InputComponent: FilterDateRange,
          })),
      },
      {
        field: "status",
        headerName: "ステータス",
        width: 200,
        valueGetter(
          params: GridValueGetterParams<string, TaxiReservationResponse>
        ) {
          return getTaxiReservationStatusName(params.row);
        },
        type: "singleSelect",
        filterOperators: getGridSingleSelectOperators()
          .filter((operator) => operator.value === "is")
          .map((operator) => ({ ...operator, label: "…に等しい" })),
        valueOptions: Object.keys(taxiReservationStatusOptions).map((key) => ({
          value: key,
          label: taxiReservationStatusOptions[key].name,
        })),
      },
      {
        field: "cancelReason",
        headerName: "キャンセル理由",
        width: 120,
        type: "string",
      },
      {
        field: "isCreatedByTaxiCompany",
        headerName: "予約元",
        width: 80,
        type: "string",
        valueGetter(
          params: GridValueGetterParams<string, TaxiReservationResponse>
        ) {
          return params.row.isCreatedByTaxiCompany ? "運行会社" : "乗客";
        },
      },
      {
        field: "startLandmark",
        width: 150,
        headerName: "乗車場所",
        filterable: false,
        valueGetter(
          params: GridValueGetterParams<string, TaxiReservationResponse>
        ) {
          return (
            params.row?.startTaxiLandmark?.name ||
            params.row?.startAddress ||
            ""
          );
        },
      },
      {
        field: "endLandmark",
        width: 150,
        headerName: "降車場所",
        filterable: false,
        valueGetter(
          params: GridValueGetterParams<string, TaxiReservationResponse>
        ) {
          return (
            params.row?.endTaxiLandmark?.name || params.row?.endAddress || ""
          );
        },
      },
      {
        field: "taxiCompanyName",
        headerName: "タクシー会社",
        valueGetter(
          params: GridValueGetterParams<string, TaxiReservationResponse>
        ) {
          return params.row.taxiCompany?.name;
        },
        type: "singleSelect",
        filterOperators: getGridSingleSelectOperators()
          .filter((operator) => operator.value === "is")
          .map((operator) => ({ ...operator, label: "…に等しい" })),
        valueOptions:
          taxiCompanies?.map((taxiCompany) => ({
            value: taxiCompany.id,
            label: taxiCompany.name,
          })) || [],
      },
      {
        field: "userInfoCode",
        headerName: "乗客さあ・くるID",
        valueGetter(
          params: GridValueGetterParams<string, TaxiReservationResponse>
        ) {
          return params.row.user?.userInfo?.code;
        },
        type: "string",
        filterOperators: getGridStringOperators()
          .filter((operator) => operator.value === "equals")
          .map((operator) => ({ ...operator, label: "…に等しい" })),
      },
    ];
  }, [taxiCompanies]);

  const onFilterChange = (filterModel: GridFilterModel) => {
    let newReq = initTaxiReservationReq;
    for (const item of filterModel.items) {
      if (!item.value) continue;
      switch (item.columnField) {
        case "rideAt":
          newReq["rideAtFrom"] = item.value[0]
            ? dayjs(item.value[0]).format("YYYY-MM-DD")
            : undefined;
          newReq["rideAtTo"] = item.value[1]
            ? dayjs(item.value[1]).format("YYYY-MM-DD")
            : undefined;
          break;
        case "dropAt":
          newReq["dropAtFrom"] = item.value[0]
            ? dayjs(item.value[0]).format("YYYY-MM-DD")
            : undefined;
          newReq["dropAtTo"] = item.value[1]
            ? dayjs(item.value[1]).format("YYYY-MM-DD")
            : undefined;
          break;
        case "taxiCompanyName":
          newReq["taxiCompanyId"] = item.value;
          break;
        case "userInfoCode":
          newReq["userInfoCode"] = item.value;
          break;
        case "status":
          newReq["status"] = item.value;
          break;
      }
    }
    setTaxiReservationReq(newReq);
  };

  return (
    <>
      <AppContainer
        meta={{
          title: "予約一覧",
        }}
        breadcrumbs={{
          heading: "予約一覧",
          links: [
            { name: "ホーム", href: PATH_DASHBOARD.root },
            { name: "予約実績" },
          ],
        }}
      >
        <Card style={{ height: "1000px", width: "100%" }}>
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ja}>
            <DataGridPremium
              rows={taxiReservations || []}
              columns={columns}
              isRowSelectable={() => false}
              components={{ Toolbar: GridToolbar }}
              localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
              filterMode="server"
              onFilterModelChange={onFilterChange}
              componentsProps={{
                filterPanel: {
                  linkOperators: [GridLinkOperator.And],
                },
              }}
            />
          </LocalizationProvider>
        </Card>
      </AppContainer>
    </>
  );
}
