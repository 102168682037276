import { useState } from "react";
import * as Yup from "yup";
// form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import { Link, Stack, Alert, IconButton, InputAdornment } from "@mui/material";
import { LoadingButton } from "@mui/lab";
// auth
import { useAuthContext } from "../../auth/useAuthContext";
// components
import Iconify from "../../components/iconify";
import FormProvider, { RHFTextField } from "../../components/hook-form";
import { useLoginMutation } from "src/hooks/coreApi/useLoginMutation";
import { useLoginCodeMutation } from "src/hooks/coreApi/useLoginCodeMutation";
import { AxiosError } from "axios";

// ----------------------------------------------------------------------

type FormValuesProps = {
  email: string;
  password: string;
  afterSubmit?: string;
  authCode: string;
};

export default function AuthLoginForm() {
  const { login } = useAuthContext();
  const loginMutation = useLoginMutation();
  const loginCodeMutation = useLoginCodeMutation();

  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [isFirstLogined, setIsFirstLogined] = useState<boolean>(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string().required(
      "Eメールアドレスもしくは電話番号を入力してください"
    ),
    password: Yup.string().required("パスワードを入力してください"),
  });

  const defaultValues = {
    email: "demo@minimals.cc",
    password: "demo1234",
  };

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const { reset, handleSubmit } = methods;

  const onSubmit = async (data: FormValuesProps) => {
    if (isFirstLogined) {
      // 2段階認証（コード入力）
      loginCodeMutation.mutate(
        {
          userLoginCodeRequest: {
            emailOrPhone: data.email,
            password: data.password,
            authCode: data.authCode,
          },
        },
        {
          onSuccess(res) {
            const accessToken = res.data.token;
            reset();
            if (!accessToken) {
              return;
            }
            login(accessToken);
          },
        }
      );
    } else {
      // ログイン
      loginMutation.mutate(
        {
          userLoginRequest: {
            emailOrPhone: data.email,
            password: data.password,
          },
        },
        {
          onSuccess() {
            setIsFirstLogined(true);
          },
        }
      );
    }
  };

  return (
    <>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={3}>
          {loginMutation.isError && (
            <Alert severity="error">
              {/* @ts-ignore */}
              {(loginMutation.error as AxiosError).response?.data?.message}
            </Alert>
          )}

          <RHFTextField name="email" label="Eメールアドレス" />

          <RHFTextField
            name="password"
            label="パスワード"
            type={showPassword ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                  >
                    <Iconify
                      icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"}
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          {isFirstLogined && (
            <RHFTextField name="authCode" label="認証コード" />
          )}
        </Stack>

        <Stack alignItems="flex-end" sx={{ my: 2 }}>
          <Link variant="body2" color="inherit" underline="always">
            パスワードをお忘れの方はこちら
          </Link>
        </Stack>

        <LoadingButton
          fullWidth
          color="inherit"
          size="large"
          type="submit"
          variant="contained"
          loading={loginMutation.isLoading || loginCodeMutation.isLoading}
          sx={{
            bgcolor: "text.primary",
            color: (theme) =>
              theme.palette.mode === "light" ? "common.white" : "grey.800",
            "&:hover": {
              bgcolor: "text.primary",
              color: (theme) =>
                theme.palette.mode === "light" ? "common.white" : "grey.800",
            },
          }}
        >
          ログイン
        </LoadingButton>
      </FormProvider>
    </>
  );
}
