import { ContentApiContentListRequest } from "../../openapi/api";
import appApiClient from "./coreApiClient";
import useBaseQuery from "./useBaseQuery";

export type ContentType = "bousai" | "kairan" | "musen" | "dekigoto" | "kinkyu";

export const useContentListQuery = (
  type: ContentType,
  req: ContentApiContentListRequest
) =>
  useBaseQuery({
    queryKey: ["contentList", type, req],
    queryFn: (config) =>
      appApiClient.contentApi.contentList(
        {
          ...req,
          type,
        },
        config
      ),
  });
