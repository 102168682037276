import { m } from "framer-motion";
// @mui
import { Container, Typography } from "@mui/material";
// components
import { MotionContainer, varBounce } from "../components/animate";
// assets
import { ForbiddenIllustration } from "../assets/illustrations";
//
import { useAuthContext } from "./useAuthContext";
import { UserAuthType, getUserAuthTypeOption } from "src/utils/user";

// ----------------------------------------------------------------------

type RoleBasedGuardProp = {
  hasContent?: boolean;
  roles?: UserAuthType[];
  children: React.ReactNode;
};

export default function RoleBasedGuard({
  hasContent,
  roles,
  children,
}: RoleBasedGuardProp) {
  // Logic here to get current user role
  const { user } = useAuthContext();

  // ロール判別 (admin / taxi / partner)
  const currentRole = getUserAuthTypeOption(user)?.type;

  if (
    roles !== undefined &&
    currentRole !== undefined &&
    !roles.includes(currentRole)
  ) {
    return hasContent ? (
      <Container component={MotionContainer} sx={{ textAlign: "center" }}>
        <m.div variants={varBounce().in}>
          <Typography variant="h3" paragraph>
            Permission Denied
          </Typography>
        </m.div>

        <m.div variants={varBounce().in}>
          <Typography sx={{ color: "text.secondary" }}>
            You do not have permission to access this page
          </Typography>
        </m.div>

        <m.div variants={varBounce().in}>
          <ForbiddenIllustration sx={{ height: 260, my: { xs: 5, sm: 10 } }} />
        </m.div>
      </Container>
    ) : null;
  }

  return <> {children} </>;
}
