import { TaxiReservationResponse } from "src/openapi";

type TaxiReservationStatus = 'rejected' | 'canceled' | 'fixed' | 'draft'
type TaxiReservationStatusOption = {
  name: string
}

export const taxiReservationStatusOptions: { [key in TaxiReservationStatus as string]: TaxiReservationStatusOption } = {
  rejected: {
    name: 'キャンセル(タクシー会社)',
  },
  canceled: {
    name: 'キャンセル(ユーザー)',
  },
  fixed: {
    name: '確定',
  },
  draft: {
    name: '仮予約',
  }
}

export function getTaxiReservationStatusName(taxiReservation: TaxiReservationResponse): string {
  const status = taxiReservation.status;
  return taxiReservationStatusOptions[status as TaxiReservationStatus]?.name || '';
}
