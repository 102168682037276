import axios from "axios";
import { Configuration } from "../../openapi";
import {
  MeApi,
  ContentApi,
  ContentTagApi,
  UserApi,
  AreaApi,
  PlaceApi,
  TenantApi,
  FriendApi,
  RoomApi,
  RoomConnectionApi,
  TaxiAchievementApi,
  TaxiReservationApi,
  TaxiCarApi,
  TaxiCompanyApi,
  TaxiLandmarkApi,
  TopicApi,
  FileApi,
  TrashCalendarApi,
  TaxiWeeklyScheduleApi,
  TaxiDailyScheduleApi,
} from "../../openapi/api";

export const coreApiAxiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_HOST_API_KEY}/api`,
  headers: {
    "X-Tenant-Id": 1,
  },
});

coreApiAxiosInstance.interceptors.request.use((config) => {
  const accessToken = localStorage.getItem("accessToken");
  if (accessToken) {
    if (config && config.headers) {
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
  }
  return config;
});

export const openApiConfig = new Configuration({
  basePath: `${process.env.REACT_APP_HOST_API_KEY}/api`,
});

// 必要なAPIクラス(OPEN APIのタグ毎に分割される）はここに列挙して初期化する
const appApiClient = {
  meApi: new MeApi(openApiConfig, "", coreApiAxiosInstance),
  contentApi: new ContentApi(openApiConfig, "", coreApiAxiosInstance),
  contentTagApi: new ContentTagApi(openApiConfig, "", coreApiAxiosInstance),
  userApi: new UserApi(openApiConfig, "", coreApiAxiosInstance),
  tenantApi: new TenantApi(openApiConfig, "", coreApiAxiosInstance),
  areaApi: new AreaApi(openApiConfig, "", coreApiAxiosInstance),
  placeApi: new PlaceApi(openApiConfig, "", coreApiAxiosInstance),
  friendApi: new FriendApi(openApiConfig, "", coreApiAxiosInstance),
  fileApi: new FileApi(openApiConfig, "", coreApiAxiosInstance),
  roomApi: new RoomApi(openApiConfig, "", coreApiAxiosInstance),
  roomConnectionApi: new RoomConnectionApi(
    openApiConfig,
    "",
    coreApiAxiosInstance
  ),
  taxiReservationApi: new TaxiReservationApi(
    openApiConfig,
    "",
    coreApiAxiosInstance
  ),
  taxiAchievementApi: new TaxiAchievementApi(
    openApiConfig,
    "",
    coreApiAxiosInstance
  ),
  taxiCarApi: new TaxiCarApi(openApiConfig, "", coreApiAxiosInstance),
  taxiCompanyApi: new TaxiCompanyApi(openApiConfig, "", coreApiAxiosInstance),
  taxiLandmarkApi: new TaxiLandmarkApi(openApiConfig, "", coreApiAxiosInstance),
  topicApi: new TopicApi(openApiConfig, "", coreApiAxiosInstance),
  trashCalendarApi: new TrashCalendarApi(
    openApiConfig,
    "",
    coreApiAxiosInstance
  ),
  taxiWeeklyScheduleApi: new TaxiWeeklyScheduleApi(
    openApiConfig,
    "",
    coreApiAxiosInstance
  ),
  taxiDailyScheduleApi: new TaxiDailyScheduleApi(
    openApiConfig,
    "",
    coreApiAxiosInstance
  ),
};

export default appApiClient;
