import { Button, Card, Checkbox } from "@mui/material";
import {
  DataGridPremium,
  GridColDef,
  GridRenderCellParams,
  GridToolbar,
  GridValueGetterParams,
  jaJP,
} from "@mui/x-data-grid-premium";
import dayjs from "dayjs";
import { useMemo } from "react";
import { Link, useParams } from "react-router-dom";
import AppContainer from "src/components/app/common/AppContainer";
import Iconify from "src/components/iconify";
import {
  ContentType,
  useContentListQuery,
} from "src/hooks/coreApi/useContentListQuery";
import { ContentResponse } from "src/openapi";
import { PATH_DASHBOARD } from "src/routes/paths";
import { contentTypeOptions } from "src/utils/app/content";

export default function ContentList() {
  const { type } = useParams<{ type: ContentType }>();
  const contentListQuery = useContentListQuery(type as ContentType, {});
  const contents = contentListQuery?.data?.contents;

  const columns = useMemo<GridColDef[]>(() => {
    return [
      { field: "id", headerName: "ID" },
      {
        field: "titleJa",
        headerName: "タイトル",
        width: 400,
        renderCell(params: GridRenderCellParams<string, ContentResponse>) {
          return (
            <Link to={`/contents/${type}/${params.row.id}`}>
              {params.value}
            </Link>
          );
        },
      },
      {
        field: "releaseAt",
        headerName: "公開日時",
        filterable: true,
        width: 150,
        valueGetter(params: GridValueGetterParams<Date, ContentResponse>) {
          return dayjs(params.row.releaseAt).toDate();
        },
        renderCell(params: GridRenderCellParams<Date, ContentResponse>) {
          return dayjs(params.value).format("YYYY-MM-DD HH:mm");
        },
      },
      {
        field: "createdAt",
        headerName: "作成日時",
        filterable: true,
        width: 150,
        valueGetter(params: GridValueGetterParams<Date, ContentResponse>) {
          return dayjs(params.row.createdAt).toDate();
        },
        renderCell(params: GridRenderCellParams<Date, ContentResponse>) {
          return dayjs(params.value).format("YYYY-MM-DD HH:mm");
        },
      },
      {
        field: "updatedAt",
        headerName: "最終更新日時",
        filterable: true,
        width: 150,
        valueGetter(params: GridValueGetterParams<Date, ContentResponse>) {
          return dayjs(params.row.updatedAt).toDate();
        },
        renderCell(params: GridRenderCellParams<Date, ContentResponse>) {
          return dayjs(params.value).format("YYYY-MM-DD HH:mm");
        },
      },
    ];
  }, [type]);

  const typeName = useMemo(() => {
    return contentTypeOptions?.[type as ContentType]?.name || "コンテンツ";
  }, [type]);

  return (
    <>
      <AppContainer
        meta={{
          title: `${typeName}一覧`,
        }}
        breadcrumbs={{
          heading: `${typeName}一覧`,
          links: [
            { name: "ホーム", href: PATH_DASHBOARD.root },
            { name: `${typeName}一覧` },
          ],
          action: (
            <>
              {/* 新規追加を許可するタイプ */}
              {["dekigoto", "bousai", "kairan", "kinkyu"].includes(
                type || ""
              ) && (
                <Button
                  component={Link}
                  to={`/contents/${type}/add`}
                  variant="contained"
                  startIcon={<Iconify icon="eva:plus-fill" />}
                >
                  新規追加
                </Button>
              )}
            </>
          ),
        }}
      >
        <Card style={{ height: "800px", width: "100%" }}>
          <DataGridPremium
            rows={contents || []}
            columns={columns}
            isRowSelectable={() => false}
            components={{ Toolbar: GridToolbar }}
            localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
            filterMode="client"
          />
        </Card>
      </AppContainer>
    </>
  );
}
