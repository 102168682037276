import { TextFieldProps } from "@mui/material";
import { RHFSelect } from "src/components/hook-form";

type RHFSelectProps = TextFieldProps & {
  name: string;
  label: string;
};

export default function ContentKinkyuLevelSelect(props: RHFSelectProps) {
  return (
    <RHFSelect native {...props}>
      <option value="1">Lv.1</option>
      <option value="2">Lv.2</option>
      <option value="3">Lv.3</option>
      <option value="4">Lv.4</option>
      <option value="5">Lv.5</option>
    </RHFSelect>
  );
}
