import { LoadingButton } from "@mui/lab";
import { Alert, Box, Card, Grid, Stack, Typography } from "@mui/material";
import { ReactNode } from "react";
import { FieldErrors, FieldValues } from "react-hook-form";
import AppErrorAlert from "../AppErrorAlert";

type AppFormLayoutProps = {
  children: any;
};

export default function AppFormLayout(props: AppFormLayoutProps) {
  return (
    <Grid item xs={12} md={8}>
      {props.children}
    </Grid>
  );
}

type AppFormCardProps = {
  children: any;
  title?: string;
  caption?: ReactNode;
};

export function AppFormCard(props: AppFormCardProps) {
  return (
    <Card sx={{ p: 3 }} title={props?.title || ""}>
      {(props?.title || props?.caption) && (
        <Box pb="1rem">
          {props?.title && (
            <Box>
              <Typography gutterBottom variant="h6" component="div">
                {props.title}
              </Typography>
            </Box>
          )}
          {props?.caption && (
            <Box mt="0.5rem">
              <Typography gutterBottom variant="caption" component="div">
                {props.caption}
              </Typography>
            </Box>
          )}
        </Box>
      )}
      <Box>{props.children}</Box>
    </Card>
  );
}

type AppFormErrorGroupProps = {
  children: ReactNode;
};

export function AppFormErrorGroup(props: AppFormErrorGroupProps) {
  return <Stack sx={{ mb: 3 }}>{props.children}</Stack>;
}

type AppFormGroupProps = {
  children: ReactNode;
  title?: string;
};

export function AppFormGroup(props: AppFormGroupProps) {
  return (
    <>
      {props.title && (
        <Box sx={{ mb: 4 }}>
          <Typography gutterBottom variant="h6" component="div">
            {props.title}
          </Typography>
        </Box>
      )}
      <Box
        sx={{ mb: 3 }}
        rowGap={3}
        columnGap={2}
        display="grid"
        gridTemplateColumns={{
          xs: "repeat(1, 1fr)",
          sm: "repeat(2, 1fr)",
        }}
      >
        {props.children}
      </Box>
    </>
  );
}

type AppFormErrorProps<TFieldValues extends FieldValues> = {
  errors: FieldErrors<TFieldValues>;
};

export function AppFormError<T extends FieldValues>(
  props: AppFormErrorProps<T>
) {
  return (
    <Alert severity="error">
      {Object.values(props.errors).map((error: any) => {
        return <div>{error.message}</div>;
      })}
    </Alert>
  );
}

type AppFormButtonGroupProps = {
  children: ReactNode;
};

export function AppFormButtonGroup(props: AppFormButtonGroupProps) {
  return (
    <Stack alignItems="flex-end" sx={{ mt: 3 }}>
      {props.children}
    </Stack>
  );
}

type AppFormDeleteGroup = {
  children: ReactNode;
};

export function AppFormDeleteGroup(props: AppFormDeleteGroup) {
  return (
    <Card sx={{ p: 3 }}>
      <Stack alignItems="flex-start" sx={{ mt: 3 }}>
        {props.children}
      </Stack>
    </Card>
  );
}

type AppFormSubmitButtonProps = {
  loading: boolean;
  children: ReactNode;
};

export function AppFormSubmitButton(props: AppFormSubmitButtonProps) {
  return (
    <LoadingButton
      size="large"
      type="submit"
      variant="contained"
      loading={props.loading}
    >
      {props.children}
    </LoadingButton>
  );
}

type AppFormDeleteCardProps = {
  children: any;
};

export function AppFormDeleteCard(props: AppFormDeleteCardProps) {
  return (
    <Card sx={{ mt: 3, p: 3 }}>
      <Box>
        <Typography variant="button">削除</Typography>
      </Box>
      <Box sx={{ mt: 3 }}>{props.children}</Box>
    </Card>
  );
}

type AppFormDeleteButtonProps = {
  loading: boolean;
  onClick(): void;
  children: ReactNode;
};

export function AppFormDeleteButton(props: AppFormDeleteButtonProps) {
  return (
    <LoadingButton
      onClick={props.onClick}
      size="large"
      type="button"
      variant="contained"
      color="error"
      loading={props.loading}
    >
      {props.children}
    </LoadingButton>
  );
}
