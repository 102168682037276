import { TaxiAchievementResponse } from "src/openapi";

export function download(jsonString: string, filename?: string) {
  // ダウンロード用のファイル名を指定する
  const _fileName = filename || "data.json";

  // aタグを生成し、download属性とhref属性を設定する
  const link = document.createElement("a");
  link.setAttribute("download", _fileName);
  link.setAttribute("href", `data:text/json;charset=utf-8,${encodeURIComponent(jsonString)}`);

  // aタグをクリックしてダウンロードを開始する
  link.click();
}

export function downloadTripLog(taxiAchievement: TaxiAchievementResponse) {
  // DBにはシングルクオートで保存しているため、ダブルクオートに変換
  const jsonStr = taxiAchievement.tripLog.replace(/'/g, "\"");
  download(jsonStr, `taxi-achievement-triplog-${taxiAchievement.id}.json`);
}
