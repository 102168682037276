import AppContainer from "src/components/app/common/AppContainer";
import { PATH_DASHBOARD } from "src/routes/paths";
import UserForm from "src/components/app/user/UserForm";

export default function UserAdd() {
  return (
    <>
      <AppContainer
        meta={{
          title: "ユーザー登録",
        }}
        breadcrumbs={{
          heading: "ユーザー登録",
          links: [
            { name: "ホーム", href: PATH_DASHBOARD.root },
            { name: "ユーザー一覧", href: "/users" },
            { name: "ユーザー登録" },
          ],
        }}
      >
        <UserForm />
      </AppContainer>
    </>
  );
}
