import { useParams } from "react-router-dom";
import AppContainer from "src/components/app/common/AppContainer";
import { usePlaceListQuery } from "src/hooks/coreApi/usePlaceListQuery";
import { PATH_DASHBOARD } from "src/routes/paths";
import PlaceForm from "src/components/app/place/PlaceForm";

export default function PlaceAdd() {
  return (
    <>
      <AppContainer
        meta={{
          title: "地区登録",
        }}
        breadcrumbs={{
          heading: "地区詳細",
          links: [
            { name: "ホーム", href: PATH_DASHBOARD.root },
            { name: "地区一覧", href: PATH_DASHBOARD.place },
            { name: "地区登録" },
          ],
        }}
      >
        <PlaceForm />
      </AppContainer>
    </>
  );
}
