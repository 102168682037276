import { Alert, Typography } from "@mui/material";
import Axios, { AxiosError } from "axios";

type Props = {
  error: any;
};

type ErrorResponse = {
  errors?: { [key: string]: string };
};

export default function AppErrorAlert(props: Props) {
  // Axiosエラー以外のエラーの場合
  if (!Axios.isAxiosError(props.error)) {
    return <Alert severity="error">エラーが発生しました</Alert>;
  }

  const axiosError = props.error as AxiosError<ErrorResponse>;

  // API側で想定しているバリデーションエラーの場合
  const allErrors = axiosError?.response?.data || {};
  if (Object.values(allErrors).length > 0) {
    return (
      <Alert severity="error">
        {Object.values(allErrors).map((errors) => {
          return Object.values(errors).map((err) => {
            return <div>{err}</div>;
          });
        })}
      </Alert>
    );
  }

  // バリデーション以外のエラー
  let message = "";
  switch (axiosError.code) {
    case "401":
      message = "認証エラーです";
      break;
    case "403":
      message = "入力値に誤りがあります";
      break;
    case "404":
      message = "存在しないURLです";
      break;
    case "500":
    default:
      message = "サーバーエラーが発生しました";
      break;
  }

  return <Alert severity="error">{message}</Alert>;
}
