import { QueryKey, useQuery, UseQueryResult } from "@tanstack/react-query";
import { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";

type BaseQueryOptions<TRes> = {
  queryKey: QueryKey;
  queryFn: (config: AxiosRequestConfig) => Promise<AxiosResponse<TRes, any>>;
  enabled?: boolean;
};

const useBaseQuery = <TRes>(
  options: BaseQueryOptions<TRes>
): UseQueryResult<TRes, AxiosError> => {
  const { queryKey, queryFn } = options;
  const query = useQuery({
    queryKey,
    queryFn: async () => {
      const config: AxiosRequestConfig = {
        headers: {},
      };
      const res = await queryFn(config);
      return res.data;
    },
    enabled: options.enabled ?? true,
  });

  return query as UseQueryResult<TRes, AxiosError>;
};

export default useBaseQuery;
