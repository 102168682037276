import { useSnackbar } from "notistack";
import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { RHFTextField } from "src/components/hook-form";
import FormProvider from "src/components/hook-form/FormProvider";
import useFormError from "src/hooks/app/form/useFormError";
import { useTrashCalendarCreateMutation } from "src/hooks/coreApi/useTrashCalendarCreateMutation";
import { useTrashCalendarUpdateMutation } from "src/hooks/coreApi/useTrashCalendarUpdateMutation";
import { TrashCalendarResponse } from "src/openapi";
import AppDescriptionField from "../../common/AppDescriptionField";
import AppFormLayout, {
  AppFormButtonGroup,
  AppFormError,
  AppFormErrorGroup,
  AppFormGroup,
  AppFormCard,
  AppFormSubmitButton,
} from "../../common/AppFormLayout";

type TrashCalendarFormValues = {
  name: string;
  googleCalendarId: string;
};

type Props = {
  isEdit?: boolean;
  currentTrashCalendar?: TrashCalendarResponse;
  onSuccess?(): void;
};

export default function TrashCalendarForm(props: Props) {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const trashCalendarCreateMutation = useTrashCalendarCreateMutation();
  const trashCalendarUpdateMutation = useTrashCalendarUpdateMutation();

  const defaultValues = useMemo<TrashCalendarFormValues>(() => {
    return {
      name: props.currentTrashCalendar?.name || "",
      googleCalendarId: props.currentTrashCalendar?.googleCalendarId || "",
    };
  }, [props.currentTrashCalendar]);

  const trashCalendarForm = useForm<TrashCalendarFormValues>({
    defaultValues,
  });

  useEffect(() => {
    trashCalendarForm.reset(defaultValues);
  }, [props]);

  const onSuccess = () => {
    if (props.isEdit) {
      enqueueSnackbar("更新しました");
    }

    if (!props.isEdit) {
      enqueueSnackbar("追加しました");
      trashCalendarForm.reset();
      navigate("/trashCalendars");
    }
    props?.onSuccess && props.onSuccess();
  };

  const onError = useFormError<TrashCalendarFormValues>(trashCalendarForm);

  const onSubmit = (values: TrashCalendarFormValues) => {
    if (props.isEdit && props?.currentTrashCalendar?.id) {
      trashCalendarUpdateMutation.mutate(
        {
          id: props.currentTrashCalendar.id,
          trashCalendarUpdateRequest: {
            name: values.name,
            googleCalendarId: values.googleCalendarId,
          },
        },
        {
          onSuccess,
          onError,
        }
      );
    }

    if (!props.isEdit) {
      trashCalendarCreateMutation.mutate(
        {
          trashCalendarCreateRequest: {
            name: values.name,
            googleCalendarId: values.googleCalendarId,
          },
        },
        {
          onSuccess,
          onError,
        }
      );
    }
  };

  return (
    <FormProvider
      methods={trashCalendarForm}
      onSubmit={trashCalendarForm.handleSubmit(onSubmit)}
    >
      <AppFormLayout>
        <AppFormCard>
          {!trashCalendarForm.formState.isValid && (
            <AppFormErrorGroup>
              <AppFormError<TrashCalendarFormValues>
                errors={trashCalendarForm.formState.errors}
              />
            </AppFormErrorGroup>
          )}

          {props.isEdit && props.currentTrashCalendar && (
            <AppFormGroup>
              <AppDescriptionField label="ID">
                {props.currentTrashCalendar.id}
              </AppDescriptionField>
            </AppFormGroup>
          )}

          <AppFormGroup>
            <RHFTextField name="name" label="名前" />
            <RHFTextField name="googleCalendarId" label="googleカレンダーID" />
          </AppFormGroup>

          <AppFormButtonGroup>
            <AppFormSubmitButton
              loading={
                trashCalendarCreateMutation.isLoading ||
                trashCalendarUpdateMutation.isLoading
              }
            >
              {!props.isEdit ? "作成する" : "保存する"}
            </AppFormSubmitButton>
          </AppFormButtonGroup>
        </AppFormCard>
      </AppFormLayout>
    </FormProvider>
  );
}
