import { ReactNode } from "react";
import { Container } from "@mui/material";
import { useSettingsContext } from "src/components/settings";
import CustomBreadcrumbs, {
  CustomBreadcrumbsProps,
} from "src/components/custom-breadcrumbs";
import AppPageMeta, { AppPageMetaProps } from "../AppPageMeta";

type AppContainerProps = {
  meta: AppPageMetaProps;
  breadcrumbs: CustomBreadcrumbsProps;
  children?: ReactNode;
};

export default function AppContainer(props: AppContainerProps) {
  const { themeStretch } = useSettingsContext();
  return (
    <>
      <AppPageMeta {...props.meta}></AppPageMeta>
      <Container maxWidth={themeStretch ? false : "lg"}>
        <CustomBreadcrumbs {...props.breadcrumbs} />
        {props.children}
      </Container>
    </>
  );
}
