import { useNavigate, useParams } from "react-router-dom";
import AppContainer from "src/components/app/common/AppContainer";
import { useTaxiAchievementListQuery } from "src/hooks/coreApi/useTaxiAchievementListQuery";
import { PATH_DASHBOARD } from "src/routes/paths";
import TaxiAchievementForm from "src/components/app/taxiAchievement/TaxiAchievementForm";
import AppMenu from "src/components/app/common/AppMenu";
import { useTaxiAchievementDeleteMutation } from "src/hooks/coreApi/useTaxiAchievementDeleteMutation";
import AppAlertDialog, {
  useAppAlertDialog,
} from "src/components/app/common/AppAlertDialog";
import { useSnackbar } from "notistack";

export default function TaxiAchievementEdit() {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const taxiAchievementQuery = useTaxiAchievementListQuery({ id: Number(id) });
  const taxiAchievement = taxiAchievementQuery.data?.taxiAchievements[0];
  const taxiAchievementDeleteMutation = useTaxiAchievementDeleteMutation();
  const alertDialog = useAppAlertDialog();
  const { enqueueSnackbar } = useSnackbar();

  const handleDeleteOk = () => {
    if (taxiAchievement?.id) {
      taxiAchievementDeleteMutation.mutate(
        {
          id: taxiAchievement.id,
        },
        {
          onSuccess() {
            enqueueSnackbar("削除しました");
            navigate("/taxiAchievements");
          },
          onError(err) {
            // TODO エラー表示
            enqueueSnackbar("サーバーエラーが発生しました", {
              variant: "error",
            });
          },
        }
      );
    }
  };

  return (
    <>
      <AppContainer
        meta={{
          title: "タクシー実績詳細",
        }}
        breadcrumbs={{
          heading: "タクシー実績詳細",
          links: [
            { name: "ホーム", href: PATH_DASHBOARD.root },
            { name: "乗車実績一覧", href: PATH_DASHBOARD.taxi.achievement },
            { name: "乗車実績詳細" },
          ],
          action: (
            <AppMenu
              options={[
                {
                  label: "削除する",
                  onClick() {
                    alertDialog.onOpen();
                  },
                },
              ]}
            >
              その他の操作
            </AppMenu>
          ),
        }}
      >
        <TaxiAchievementForm
          isEdit={true}
          currentTaxiAchievement={taxiAchievement}
        />
      </AppContainer>
      {taxiAchievement && (
        <AppAlertDialog
          {...alertDialog}
          title="乗車実績の削除"
          description={`乗車実績(ID: ${taxiAchievement.id} )を削除してもよろしいですか？`}
          onOk={handleDeleteOk}
        />
      )}
    </>
  );
}
