import { TaxiAchievementApiTaxiAchievementListRequest } from "../../openapi";
import appApiClient from "./coreApiClient";
import useBaseQuery from "./useBaseQuery";

export const useTaxiAchievementListQuery = (
  req: TaxiAchievementApiTaxiAchievementListRequest
) =>
  useBaseQuery({
    queryKey: ["taxiAchievementList"],
    queryFn: (config) =>
      appApiClient.taxiAchievementApi.taxiAchievementList(req, config),
  });
