import { Box, Typography } from "@mui/material";
import { ReactNode } from "react";

type AppDescriptionFieldProps = {
  label: string;
  children?: ReactNode;
};

export default function AppDescriptionField(props: AppDescriptionFieldProps) {
  return (
    <Box>
      {typeof props.label === "object" ? (
        <>{props.label}</>
      ) : (
        <Typography
          paragraph
          variant="overline"
          sx={{ color: "text.disabled" }}
        >
          {props.label}
        </Typography>
      )}

      {props?.children && typeof props.children === "object" ? (
        <>{props.children}</>
      ) : (
        <Typography variant="body2">{props.children}</Typography>
      )}
    </Box>
  );
}
