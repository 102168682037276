import { RHFSelect } from "src/components/hook-form";
import { useTaxiCarListQuery } from "src/hooks/coreApi/useTaxiCarListQuery";
import { TaxiCarApiTaxiCarListRequest } from "src/openapi";

type TaxiCarSelectProps = {
  name: string;
  label: string;
  req?: TaxiCarApiTaxiCarListRequest;
};

export default function TaxiCarSelect(props: TaxiCarSelectProps) {
  const taxiCarListQuery = useTaxiCarListQuery(props.req || {});

  const taxiCars = taxiCarListQuery?.data?.taxiCars || [];

  if (taxiCarListQuery.isLoading) {
    return null;
  }

  return (
    <RHFSelect native name={props.name} label={props.label}>
      <option value="">タクシー車両を選択してください</option>
      {taxiCarListQuery.isFetched &&
        taxiCars.length > 0 &&
        taxiCars.map((taxiCar) => {
          return (
            <option key={taxiCar.id} value={taxiCar.id}>
              {taxiCar.name}
            </option>
          );
        })}
    </RHFSelect>
  );
}
