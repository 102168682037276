import AppContainer from "src/components/app/common/AppContainer";
import { PATH_DASHBOARD } from "src/routes/paths";
import TaxiReservationForm from "src/components/app/taxiReservation/TaxiReservationForm";

export default function TaxiReservationAdd() {
  return (
    <>
      <AppContainer
        meta={{
          title: "予約登録",
        }}
        breadcrumbs={{
          heading: "予約詳細",
          links: [
            { name: "ホーム", href: PATH_DASHBOARD.root },
            { name: "予約一覧", href: PATH_DASHBOARD.taxi.reservation },
            { name: "予約登録" },
          ],
        }}
      >
        <TaxiReservationForm />
      </AppContainer>
    </>
  );
}
