// 空文字の場合はnull
export const toNullableString = (value: string | number | null) => {
  if (value === "" || value === null) {
    return null;
  }
  return value;
};

export const toNullableNumber = (value: string | number | null) => {
  if (value === "" || value === null) {
    return null;
  }
  return Number(value);
};
