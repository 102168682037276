import AppContainer from "src/components/app/common/AppContainer";
import { PATH_DASHBOARD } from "src/routes/paths";
import TaxiCompanyForm from "src/components/app/taxiCompany/TaxiCompanyForm";

export default function TaxiCompanyAdd() {
  return (
    <>
      <AppContainer
        meta={{
          title: "タクシー会社登録",
        }}
        breadcrumbs={{
          heading: "タクシー会社詳細",
          links: [
            { name: "ホーム", href: PATH_DASHBOARD.root },
            {
              name: "タクシー会社一覧",
              href: PATH_DASHBOARD.taxi.company,
            },
            { name: "タクシー会社登録" },
          ],
        }}
      >
        <TaxiCompanyForm />
      </AppContainer>
    </>
  );
}
