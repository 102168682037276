import { TaxiLandmarkApiTaxiLandmarkListRequest } from "../../openapi";
import appApiClient from "./coreApiClient";
import useBaseQuery from "./useBaseQuery";

export const useTaxiLandmarkListQuery = (
  req: TaxiLandmarkApiTaxiLandmarkListRequest
) =>
  useBaseQuery({
    queryKey: ["taxiLandmarkList"],
    queryFn: (config) =>
      appApiClient.taxiLandmarkApi.taxiLandmarkList(req, config),
  });
