import { useSnackbar } from "notistack";
import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { RHFTextField } from "src/components/hook-form";
import FormProvider from "src/components/hook-form/FormProvider";
import useFormError from "src/hooks/app/form/useFormError";
import { useTaxiReservationCreateByTaxiCompany } from "src/hooks/coreApi/useTaxiReservationCreateByTaxiCompany";
import { useTaxiReservationUpdateMutation } from "src/hooks/coreApi/useTaxiReservationUpdateMutation";
import { TaxiReservationResponse } from "src/openapi";
import AppDescriptionField from "../../common/AppDescriptionField";
import AppFormLayout, {
  AppFormButtonGroup,
  AppFormError,
  AppFormErrorGroup,
  AppFormGroup,
  AppFormCard,
  AppFormSubmitButton,
} from "../../common/AppFormLayout";
import { Box } from "@mui/material";
import { current } from "immer";

// TaxiReservationCreateByTaxiCompanyRequestと同じプロパティ
type TaxiReservationFormValues = {
  taxiCompanyId: number;
  userId: number;
  userName: string;
  userPhone: string;
  rideAt: string;
  dropAt: string;
  startTaxiLandmarkId: number | null;
  endTaxiLandmarkId: number | null;
  startAddress: string;
  endAddress: string;
  startLatLng: string;
  endLatLng: string;
  taxiCarId: number | null;
};

type Props = {
  isEdit?: boolean;
  currentTaxiReservation?: TaxiReservationResponse;
  onSuccess?(): void;
};

export default function TaxiReservationForm(props: Props) {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const taxiReservationCreateMutation = useTaxiReservationCreateByTaxiCompany();
  const taxiReservationUpdateMutation = useTaxiReservationUpdateMutation();

  const defaultValues = useMemo<TaxiReservationFormValues>(() => {
    return {
      taxiCompanyId: props.currentTaxiReservation?.taxiCompanyId || 0,
      userId: props.currentTaxiReservation?.userId || 0,
      userName: props.currentTaxiReservation?.userName || "",
      userPhone: props.currentTaxiReservation?.userPhone || "",
      rideAt: props.currentTaxiReservation?.rideAt || "",
      dropAt: props.currentTaxiReservation?.dropAt || "",
      startTaxiLandmarkId:
        props.currentTaxiReservation?.startTaxiLandmarkId || null,
      endTaxiLandmarkId:
        props.currentTaxiReservation?.endTaxiLandmarkId || null,
      startAddress: props.currentTaxiReservation?.startAddress || "",
      endAddress: props.currentTaxiReservation?.endAddress || "",
      startLatLng: props.currentTaxiReservation?.startLatLng || "",
      endLatLng: props.currentTaxiReservation?.endLatLng || "",
      taxiCarId: props.currentTaxiReservation?.taxiCarId || null,
    };
  }, [props.currentTaxiReservation]);

  const taxiReservationForm = useForm<TaxiReservationFormValues>({
    defaultValues,
  });

  useEffect(() => {
    taxiReservationForm.reset(defaultValues);
  }, [props]);

  const onSuccess = () => {
    if (props.isEdit) {
      enqueueSnackbar("更新しました");
    }

    if (!props.isEdit) {
      enqueueSnackbar("追加しました");
      taxiReservationForm.reset();
      navigate("/taxiReservations");
    }
    props?.onSuccess && props.onSuccess();
  };

  const onError = useFormError<TaxiReservationFormValues>(taxiReservationForm);

  const onSubmit = (values: TaxiReservationFormValues) => {
    if (props.isEdit && props?.currentTaxiReservation?.id) {
      taxiReservationUpdateMutation.mutate(
        {
          id: props.currentTaxiReservation.id,
          taxiReservationUpdateRequest: {
            userName: props.currentTaxiReservation?.userName || "",
            userPhone: props.currentTaxiReservation?.userPhone || "",
            taxiCompanyId: values.taxiCompanyId,
            rideAt: values.rideAt,
            dropAt: values.dropAt,
            startTaxiLandmarkId: values.startTaxiLandmarkId,
            endTaxiLandmarkId: values.endTaxiLandmarkId,
            startAddress: values.startAddress,
            endAddress: values.endAddress,
            startLatLng: values.startLatLng,
            endLatLng: values.endLatLng,
            taxiCarId: values.taxiCarId,
          },
        },
        {
          onSuccess,
          onError,
        }
      );
    }

    if (!props.isEdit) {
      taxiReservationCreateMutation.mutate(
        {
          taxiReservationCreateByTaxiCompanyRequest: {
            userId: values.userId,
            userName: values.userName,
            userPhone: values.userPhone,
            rideAt: values.rideAt,
            dropAt: values.dropAt,
            startTaxiLandmarkId: values.startTaxiLandmarkId,
            endTaxiLandmarkId: values.endTaxiLandmarkId,
            startAddress: values.startAddress,
            endAddress: values.endAddress,
            startLatLng: values.startLatLng,
            endLatLng: values.endLatLng,
            taxiCarId: values.taxiCarId,
            isInBound: true, // TODO
          },
        },
        {
          onSuccess,
          onError,
        }
      );
    }
  };

  return (
    <FormProvider
      methods={taxiReservationForm}
      onSubmit={taxiReservationForm.handleSubmit(onSubmit)}
    >
      <AppFormLayout>
        <AppFormCard>
          {!taxiReservationForm.formState.isValid && (
            <AppFormErrorGroup>
              <AppFormError<TaxiReservationFormValues>
                errors={taxiReservationForm.formState.errors}
              />
            </AppFormErrorGroup>
          )}

          {props.isEdit && props.currentTaxiReservation && (
            <AppFormGroup>
              <AppDescriptionField label="ID">
                {props.currentTaxiReservation.id}
              </AppDescriptionField>
            </AppFormGroup>
          )}

          <AppFormGroup>
            {/*
             userId: values.userId,
            userName: values.userName,
            userPhone: values.userPhone,
            rideAt: values.rideAt,
            dropAt: values.dropAt,
            startTaxiLandmarkId: values.startTaxiLandmarkId,
            endTaxiLandmarkId: values.endTaxiLandmarkId,
            startAddress: values.startAddress,
            endAddress: values.endAddress,
            startLatLng: values.startLatLng,
            endLatLng: values.endLatLng,
            taxiCarId: values.taxiCarId,
            isInBound: true, // TODO
             */}
            <RHFTextField name="userId" label="乗客ユーザーID" />
            <RHFTextField name="userName" label="乗客名" />
            <RHFTextField name="userPhone" label="乗客電話番号" />
            <Box></Box>
            <RHFTextField
              name="rideAt"
              label="乗車日時"
              type="datetime-local"
              onChange={(evt) => {
                taxiReservationForm.setValue("rideAt", evt.target.value);
              }}
            />
            <RHFTextField
              name="dropAt"
              label="降車日時"
              type="datetime-local"
              onChange={(evt) => {
                taxiReservationForm.setValue("dropAt", evt.target.value);
              }}
            />
            <RHFTextField name="startTaxiLandmarkId" label="乗車場所" />
            <RHFTextField name="endTaxiLandmarkId" label="降車場所" />
            <RHFTextField name="startAddress" label="乗車場所住所" />
            <RHFTextField name="endAddress" label="降車場所住所" />
            <RHFTextField name="taxiCarId" label="車両ID" />
          </AppFormGroup>

          <AppFormButtonGroup>
            <AppFormSubmitButton
              loading={
                taxiReservationCreateMutation.isLoading ||
                taxiReservationUpdateMutation.isLoading
              }
            >
              {!props.isEdit ? "作成する" : "保存する"}
            </AppFormSubmitButton>
          </AppFormButtonGroup>
        </AppFormCard>
      </AppFormLayout>
    </FormProvider>
  );
}
