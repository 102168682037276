import { Button, Card, Container } from "@mui/material";
import {
  DataGridPremium,
  GridColDef,
  GridRenderCellParams,
  GridToolbar,
  jaJP,
} from "@mui/x-data-grid-premium";
import { useMemo } from "react";
import { Link } from "react-router-dom";
import AppContainer from "src/components/app/common/AppContainer";
import Iconify from "src/components/iconify";
import { usePlaceListQuery } from "src/hooks/coreApi/usePlaceListQuery";
import { PlaceResponse } from "src/openapi";
import { PATH_DASHBOARD } from "src/routes/paths";

export default function PlaceList() {
  const placeQuery = usePlaceListQuery({});
  const places = placeQuery?.data?.places;

  const columns = useMemo<GridColDef[]>(() => {
    return [
      { field: "id", headerName: "ID" },
      {
        field: "name",
        headerName: "名前",
        renderCell(params: GridRenderCellParams<string, PlaceResponse>) {
          return <Link to={`/places/${params.row.id}`}>{params.value}</Link>;
        },
      },
      { field: "code", headerName: "コード" },
    ];
  }, []);

  return (
    <>
      <AppContainer
        meta={{
          title: "地区一覧",
        }}
        breadcrumbs={{
          heading: "地区一覧",
          links: [
            { name: "ホーム", href: PATH_DASHBOARD.root },
            { name: "地区" },
          ],
          action: (
            <>
              <Button
                component={Link}
                to="/places/add"
                variant="contained"
                startIcon={<Iconify icon="eva:plus-fill" />}
              >
                新規追加
              </Button>
            </>
          ),
        }}
      >
        <Card style={{ height: "400px", width: "100%" }}>
          <DataGridPremium
            rows={places || []}
            columns={columns}
            isRowSelectable={() => false}
            components={{ Toolbar: GridToolbar }}
            localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
            filterMode="client"
          />
        </Card>
      </AppContainer>
    </>
  );
}
