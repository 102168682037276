import { useParams } from "react-router-dom";
import AppContainer from "src/components/app/common/AppContainer";
import { useTaxiLandmarkListQuery } from "src/hooks/coreApi/useTaxiLandmarkListQuery";
import { PATH_DASHBOARD } from "src/routes/paths";
import TaxiLandmarkForm from "src/components/app/taxiLandmark/TaxiLandmarkForm";

export default function TaxiLandmarkAdd() {
  return (
    <>
      <AppContainer
        meta={{
          title: "ランドマーク登録",
        }}
        breadcrumbs={{
          heading: "ランドマーク詳細",
          links: [
            { name: "ホーム", href: PATH_DASHBOARD.root },
            { name: "ランドマーク一覧", href: PATH_DASHBOARD.taxi.landmark },
            { name: "ランドマーク登録" },
          ],
        }}
      >
        <TaxiLandmarkForm />
      </AppContainer>
    </>
  );
}
