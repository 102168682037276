import { useSnackbar } from "notistack";
import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { RHFSwitch, RHFTextField } from "src/components/hook-form";
import FormProvider from "src/components/hook-form/FormProvider";
import useFormError from "src/hooks/app/form/useFormError";
import { useRoomCreateMutation } from "src/hooks/coreApi/useRoomCreateMutation";
import { useRoomUpdateMutation } from "src/hooks/coreApi/useRoomUpdateMutation";
import {
  RoomParticipantResponse,
  RoomResponse,
  RoomUpdateRequestParticipantsInner,
} from "src/openapi";
import AppDescriptionField from "../../common/AppDescriptionField";
import AppFormLayout, {
  AppFormButtonGroup,
  AppFormError,
  AppFormErrorGroup,
  AppFormGroup,
  AppFormCard,
  AppFormSubmitButton,
} from "../../common/AppFormLayout";
import dayjs from "dayjs";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CircularProgress,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import Iconify from "src/components/iconify";
import {
  DataGridPremium,
  GridColDef,
  GridRenderCellParams,
  GridToolbar,
  GridValueGetterParams,
  jaJP,
} from "@mui/x-data-grid-premium";
import { Link } from "react-router-dom";
import AppVertMenu from "../../common/AppVertMenu";
import { useUserListQuery } from "src/hooks/coreApi/useUserListQuery";
import { useDebounce } from "use-debounce";

interface User {
  title: string;
  userId: number;
}

type RoomFormValues = {
  name: string;
  isPrivate: boolean;
  startAt: string;
  endAt: string;
  participants: Array<RoomUpdateRequestParticipantsInner>;
};

type Props = {
  isEdit?: boolean;
  currentRoom?: RoomResponse;
  onSuccess?(): void;
};

export default function RoomForm(props: Props) {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const roomCreateMutation = useRoomCreateMutation();
  const roomUpdateMutation = useRoomUpdateMutation();

  const roomParticipants = props.currentRoom?.roomParticipants;

  const [open, setOpen] = useState(false);
  const [userSearchId, setUserSearchId] = useState<number>(0);
  const [userSearchQ, setUserSearchQ] = useState<string>("");
  const [debouncedUserSearchId] = useDebounce(userSearchId, 1000);
  const [debouncedUserSearchQ] = useDebounce(userSearchQ, 1000);
  const [options, setOptions] = useState<readonly User[]>([]);
  const [option, setOption] = useState<User | null>(null);
  const userListQuery = useUserListQuery(
    {
      id: userSearchId,
      q: userSearchQ,
    },
    false
  );
  const loading =
    open &&
    userListQuery.isLoading &&
    (userSearchId !== 0 || userSearchQ !== "");

  const defaultValues = useMemo<RoomFormValues>(() => {
    const startAt = props.currentRoom?.startAt || null;
    const endAt = props.currentRoom?.endAt || null;

    return {
      name: props.currentRoom?.name || "",
      isPrivate: props.currentRoom?.isPrivate || false,
      startAt: dayjs(startAt).format("YYYY-MM-DDTHH:mm"),
      endAt: dayjs(endAt).format("YYYY-MM-DDTHH:mm"),
      participants: [],
    };
  }, [props.currentRoom]);
  const roomForm = useForm<RoomFormValues>({
    defaultValues,
  });

  useEffect(() => {
    setUserSearchId(0);
    setUserSearchQ("");
    setOption(null);
    setOptions([]);
    roomForm.reset(defaultValues);
  }, [defaultValues, props.currentRoom, roomForm]);

  useEffect(() => {
    if (!open) {
      setUserSearchId(0);
      setUserSearchQ("");
      setOptions([]);
    }
  }, [open]);

  useEffect(() => {
    if (!userListQuery.data?.users) {
      return;
    }

    const users = userListQuery.data.users;
    const options = users.map((user) => {
      return {
        title: `${user.id}: ${user.nickname} (${user.nicknameKana})`,
        userId: user.id,
      };
    });

    setOptions(options);
  }, [userListQuery.data]);

  useEffect(() => {
    if (!debouncedUserSearchId && !debouncedUserSearchQ) {
      return;
    }

    userListQuery.refetch();
  }, [debouncedUserSearchId, debouncedUserSearchQ, userListQuery]);

  const onSuccess = useCallback(() => {
    if (props.isEdit) {
      enqueueSnackbar("更新しました");
      roomForm.setValue("participants", []);
    }

    if (!props.isEdit) {
      enqueueSnackbar("追加しました");
      roomForm.reset();
      navigate("/video/rooms");
    }
    props?.onSuccess && props.onSuccess();
  }, [enqueueSnackbar, navigate, props, roomForm]);

  const onError = useFormError<RoomFormValues>(roomForm);

  const onSubmit = useCallback(
    (values: RoomFormValues) => {
      if (props.isEdit && props?.currentRoom?.id) {
        roomUpdateMutation.mutate(
          {
            id: props.currentRoom.id,
            roomUpdateRequest: {
              name: values.name,
              startAt: dayjs(values.startAt).isValid()
                ? dayjs(values.startAt).toISOString()
                : undefined,
              endAt: dayjs(values.endAt).isValid()
                ? dayjs(values.endAt).toISOString()
                : undefined,
              isPrivate: values.isPrivate,
              participants: values.participants,
            },
          },
          {
            onSuccess,
            onError,
          }
        );
      }

      if (!props.isEdit) {
        roomCreateMutation.mutate(
          {
            roomCreateRequest: {
              name: values.name,
              startAt: dayjs(values.startAt).isValid()
                ? dayjs(values.startAt).toISOString()
                : new Date(0).toISOString(),
              endAt: dayjs(values.endAt).isValid()
                ? dayjs(values.endAt).toISOString()
                : null,
              isPrivate: values.isPrivate,
            },
          },
          {
            onSuccess,
            onError,
          }
        );
      }
    },
    [
      onError,
      onSuccess,
      props.currentRoom?.id,
      props.isEdit,
      roomCreateMutation,
      roomUpdateMutation,
    ]
  );

  const canUpdateParticipant = useCallback(() => {
    const values = roomForm.getValues();
    const defaults = Object.assign({}, defaultValues);

    for (const key in values) {
      if (
        key !== "participants" &&
        values.hasOwnProperty(key) &&
        values[key as keyof RoomFormValues] !==
          defaults[key as keyof RoomFormValues]
      ) {
        enqueueSnackbar(
          "ルーム情報が変更されています。保存してから削除してください。",
          { variant: "error" }
        );
        return false;
      }
    }

    return true;
  }, [defaultValues, enqueueSnackbar, roomForm]);

  const onClickAddParticipant = useCallback(() => {
    if (!option) {
      console.error("option is undefined");
      return;
    }

    if (!canUpdateParticipant()) {
      return;
    }

    roomForm.setValue("participants", [
      {
        userId: option.userId,
        operation: "add",
      },
    ]);

    roomForm.handleSubmit(onSubmit)();
  }, [onSubmit, option, roomForm, canUpdateParticipant]);

  const onClickDeleteParticipant = useCallback(
    (userId: number | undefined) => {
      if (!userId) {
        console.error("userId is undefined");
        return;
      }

      if (!canUpdateParticipant()) {
        return;
      }

      roomForm.setValue("participants", [
        {
          userId,
          operation: "delete",
        },
      ]);

      roomForm.handleSubmit(onSubmit)();
    },
    [onSubmit, roomForm, canUpdateParticipant]
  );

  const onChangeUserSearchFormValue = useCallback((value: string) => {
    if (!value) {
      setUserSearchId(0);
      setUserSearchQ("");
      setOption(null);
      setOptions([]);
      return;
    }

    if (/^\d+$/.test(value)) {
      setUserSearchId(Number(value));
      setUserSearchQ("");
    } else {
      setUserSearchId(0);
      setUserSearchQ(value);
    }
  }, []);

  const columns = useMemo<GridColDef[]>(() => {
    return [
      { field: "id", headerName: "ID" },
      { field: "userId", headerName: "User ID" },
      {
        field: "nickname",
        headerName: "お名前",
        sortable: true,
        filterable: true,
        renderCell(
          params: GridRenderCellParams<string, RoomParticipantResponse>
        ) {
          return (
            <Link to={`/users/${params.row.user?.id}`}>
              {params.row.user?.nickname}
            </Link>
          );
        },
      },
      {
        field: "nicknameKana",
        headerName: "お名前(カナ)",
        sortable: true,
        filterable: true,
        width: 150,
        valueGetter(
          params: GridValueGetterParams<string, RoomParticipantResponse>
        ) {
          return params.row.user?.nicknameKana;
        },
      },
      {
        field: "createdAt",
        headerName: "作成日時",
        sortable: true,
        filterable: true,
        width: 150,
        valueGetter(
          params: GridValueGetterParams<string, RoomParticipantResponse>
        ) {
          return dayjs(params.row.createdAt).format("YYYY-MM-DD HH:mm");
        },
      },
      {
        field: "actions",
        headerName: "",
        sortable: false,
        filterable: false,
        renderCell(
          params: GridRenderCellParams<string, RoomParticipantResponse>
        ) {
          return (
            <AppVertMenu
              options={[
                {
                  label: "削除する",
                  onClick: () => {
                    onClickDeleteParticipant(params.row.user?.id);
                  },
                },
              ]}
            />
          );
        },
      },
    ];
  }, [onClickDeleteParticipant]);

  return (
    <FormProvider methods={roomForm} onSubmit={roomForm.handleSubmit(onSubmit)}>
      <AppFormLayout>
        <AppFormCard>
          {!roomForm.formState.isValid && (
            <AppFormErrorGroup>
              <AppFormError<RoomFormValues>
                errors={roomForm.formState.errors}
              />
            </AppFormErrorGroup>
          )}

          {props.isEdit && props.currentRoom && (
            <AppFormGroup>
              <AppDescriptionField label="ID">
                {props.currentRoom.id}
              </AppDescriptionField>
            </AppFormGroup>
          )}

          <AppFormGroup>
            <RHFTextField name="name" label="ルーム名" />
            <RHFSwitch
              name="isPrivate"
              label="非公開 (ONにすると許可されたユーザーだけが参加できます)"
            />
          </AppFormGroup>

          <AppFormGroup>
            <RHFTextField
              name="startAt"
              label="開始日時"
              type="datetime-local"
              onChange={(evt) => {
                roomForm.setValue("startAt", evt.target.value);
              }}
            />
            <RHFTextField
              name="endAt"
              label="終了日時"
              type="datetime-local"
              onChange={(evt) => {
                roomForm.setValue("endAt", evt.target.value);
              }}
            />
          </AppFormGroup>

          <AppFormButtonGroup>
            <AppFormSubmitButton
              loading={
                roomCreateMutation.isLoading || roomUpdateMutation.isLoading
              }
            >
              {!props.isEdit ? "作成する" : "保存する"}
            </AppFormSubmitButton>
          </AppFormButtonGroup>
        </AppFormCard>

        {props.isEdit && (
          <>
            <Box sx={{ mt: 5 }}>
              <Stack sx={{ mb: 5 }} direction="row" alignItems="center">
                <Box sx={{ flexGrow: 1 }}>
                  <Typography variant="h5" gutterBottom>
                    参加者一覧
                  </Typography>
                </Box>

                <Stack sx={{ flexShrink: 0 }} direction="row">
                  <Autocomplete
                    value={option}
                    size="small"
                    id="asynchronous-demo"
                    sx={{ width: 300 }}
                    open={open}
                    onOpen={() => {
                      setOpen(true);
                    }}
                    onClose={() => {
                      setOpen(false);
                    }}
                    onChange={(_, value) => {
                      setOption(value);
                    }}
                    onInputChange={(_, value) => {
                      onChangeUserSearchFormValue(value);
                    }}
                    isOptionEqualToValue={(option, value) =>
                      option.title === value.title
                    }
                    getOptionLabel={(option) => option.title}
                    options={options}
                    loading={loading}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="ユーザーを検索"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <Fragment>
                              {loading ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </Fragment>
                          ),
                        }}
                      />
                    )}
                  />
                  <Button
                    disabled={!option}
                    sx={{ ml: 2 }}
                    variant="contained"
                    startIcon={<Iconify icon="eva:plus-fill" />}
                    onClick={onClickAddParticipant}
                  >
                    新規追加
                  </Button>
                </Stack>
              </Stack>
            </Box>
            <Card style={{ height: "800px", width: "100%" }}>
              <DataGridPremium
                rows={roomParticipants || []}
                columns={columns}
                isRowSelectable={() => false}
                components={{ Toolbar: GridToolbar }}
                localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
                filterMode="client"
              />
            </Card>
          </>
        )}
      </AppFormLayout>
    </FormProvider>
  );
}
