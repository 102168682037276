import { TaxiReservationApiTaxiReservationListRequest } from "../../openapi";
import appApiClient from "./coreApiClient";
import useBaseQuery from "./useBaseQuery";

export const useTaxiReservationListQuery = (
  req: TaxiReservationApiTaxiReservationListRequest
) =>
  useBaseQuery({
    queryKey: ["taxiReservationList"],
    queryFn: (config) =>
      appApiClient.taxiReservationApi.taxiReservationList(req, config),
  });
