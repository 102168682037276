import { Link, useNavigate, useParams } from "react-router-dom";
import { useMemo, useEffect, useCallback, useState } from "react";
// form
import { useForm } from "react-hook-form";
// @mui
import { LoadingButton } from "@mui/lab";
import { Grid, Card, Stack, Box, Typography } from "@mui/material";
// routes
import { PATH_DASHBOARD } from "../../../../routes/paths";
// @types
import { IBlogNewPost } from "../../../../@types/blog";
// components
import { useSnackbar } from "../../../snackbar";
import FormProvider, {
  RHFSelect,
  RHFTextField,
  RHFUploadBox,
} from "../../../hook-form";
import { useContentCreateMutation } from "src/hooks/coreApi/useContentCreateMutation";
import { useContentUpdateMutation } from "src/hooks/coreApi/useContentUpdateMutation";
import { ContentResponse } from "src/openapi/api";
import { ContentType } from "src/hooks/coreApi/useContentListQuery";
import dayjs from "dayjs";
import useFormError from "src/hooks/app/form/useFormError";
import { coreApiAxiosInstance } from "src/hooks/coreApi/coreApiClient";
import axios from "axios";
import Iconify from "src/components/iconify";
import { CustomFile } from "./FilePreview";

export type FormValuesProps = IBlogNewPost;

type FormValues = {
  titleJa: string;
  bodyJa: string;
  releaseAt: string;
  removeFileKeys: string[] | null;
};

export type ContentFormProps = {
  isEdit?: boolean;
  currentContent?: ContentResponse;
  onSuccess?(): void;
};

export default function ContentBousaiForm(props: ContentFormProps) {
  const navigate = useNavigate();

  const { type } = useParams<{ type: ContentType }>();

  const { enqueueSnackbar } = useSnackbar();

  const contentCreateMutation = useContentCreateMutation();
  const contentUpdateMutation = useContentUpdateMutation();

  const defaultValues = useMemo<FormValues>(() => {
    return {
      titleJa: props.currentContent?.titleJa || "",
      bodyJa: props.currentContent?.bodyJa || "",
      releaseAt:
        dayjs(props.currentContent?.releaseAt).format("YYYY-MM-DDTHH:mm") || "",
      bousaiPdfFile: props.currentContent?.files?.[0]
        ? {
            name: props.currentContent.files[0].name,
            key: props.currentContent.files[0].key,
            path: props.currentContent?.files[0].cloudFrontUrl,
          }
        : null,
      dekigotoVideoUrl: "",
      removeFileKeys: null,
    };
  }, [props.currentContent]);

  const contentForm = useForm<FormValues>({
    defaultValues,
  });

  useEffect(() => {
    contentForm.reset(defaultValues);
  }, [props]);

  const onSuccess = () => {
    enqueueSnackbar("保存しました");

    if (!props.isEdit) {
      contentForm.reset();
      navigate(PATH_DASHBOARD.content[type as ContentType]);
    }

    props?.onSuccess && props.onSuccess();
  };

  const onError = useFormError<FormValues>(contentForm, {
    name: "name",
  });

  const onSubmit = (values: FormValues) => {
    if (!type) {
      return;
    }

    if (props.isEdit && props.currentContent) {
      contentUpdateMutation.mutate(
        {
          id: props.currentContent.id,
          contentUpdateRequest: {
            slug: props.currentContent.slug,
            detailType: "",
            titleJa: values.titleJa,
            titleEn: values.titleJa, // TODO
            bodyJa: values.bodyJa,
            bodyEn: values.bodyJa, // TODO
            areaIds: [], // TODO
            placeIds: [], // TODO
            status: "public", // TODO
            kinkyuLevel: null,
            releaseAt: dayjs(values.releaseAt).toISOString(),
            // imageFileKey: "tmp/test.jpeg",
            // @ts-ignore
            removeFileKeys: values.removeFileKeys,
            musenAudioFileKey: props.currentContent?.files?.[0]?.key,
          },
        },
        {
          onSuccess,
          onError,
        }
      );
    } else {
      contentCreateMutation.mutate(
        {
          contentCreateRequest: {
            type: type,
            slug: "",
            detailType: "",
            titleJa: values.titleJa,
            titleEn: values.titleJa, // TODO
            bodyJa: values.bodyJa,
            bodyEn: values.bodyJa, // TODO
            areaIds: [], // TODO
            placeIds: [], // TODO
            status: "public", // TODO
            kinkyuLevel: null,
            releaseAt: dayjs(values.releaseAt).toISOString(),
            // imageFileKey: "tmp/test.jpeg",
          },
        },
        {
          onSuccess,
          onError,
        }
      );
    }
  };

  const musenAudioFile = useMemo(() => {
    return props?.currentContent?.files?.[0];
  }, [props.currentContent]);

  const placeNames = useMemo(() => {
    return (
      props?.currentContent?.contentPlaces?.map((cp) => cp?.place?.name) || []
    );
  }, [props.currentContent]);

  return (
    <FormProvider
      methods={contentForm}
      onSubmit={contentForm.handleSubmit(onSubmit)}
    >
      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          <Card sx={{ p: 3 }}>
            <Stack spacing={3}>
              <RHFTextField name="titleJa" label="タイトル" />
              <RHFTextField name="bodyJa" label="本文" multiline rows={3} />
              {musenAudioFile && (
                <>
                  <Stack spacing={1}>
                    <Typography
                      variant="subtitle2"
                      sx={{ color: "text.secondary" }}
                    >
                      音声ファイル（編集不可）
                    </Typography>
                  </Stack>
                  <Box>
                    <a
                      href={musenAudioFile.cloudFrontUrl}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {musenAudioFile?.name}
                    </a>
                  </Box>
                </>
              )}
              {props.isEdit && props.currentContent && (
                <Stack>
                  <Typography
                    variant="subtitle2"
                    sx={{ color: "text.secondary" }}
                  >
                    地区（編集不可）
                  </Typography>
                  <Typography>{placeNames.join(", ")}</Typography>
                </Stack>
              )}
            </Stack>
          </Card>
        </Grid>

        <Grid item xs={12} md={4}>
          <Card sx={{ p: 3 }}>
            <Stack spacing={3}>
              {/* <div>
                <RHFSwitch
                  name="status"
                  label="公開する"
                  labelPlacement="start"
                  sx={{
                    mb: 1,
                    mx: 0,
                    width: 1,
                    justifyContent: "space-between",
                  }}
                />
              </div> */}
              <RHFTextField
                name="releaseAt"
                label="公開日時"
                type="datetime-local"
                onChange={(evt) => {
                  contentForm.setValue("releaseAt", evt.target.value);
                }}
              />
            </Stack>
          </Card>

          <Stack direction="row" spacing={1.5} sx={{ mt: 3 }}>
            <LoadingButton
              fullWidth
              type="submit"
              variant="contained"
              size="large"
              loading={contentForm.formState.isSubmitting}
            >
              保存する
            </LoadingButton>
          </Stack>
        </Grid>
      </Grid>
    </FormProvider>
  );
}
