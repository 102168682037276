import { useSnackbar } from "notistack";
import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { RHFTextField } from "src/components/hook-form";
import FormProvider from "src/components/hook-form/FormProvider";
import useFormError from "src/hooks/app/form/useFormError";
import { useTaxiLandmarkCreateMutation } from "src/hooks/coreApi/useTaxiLandmarkCreateMutation";
import { useTaxiLandmarkUpdateMutation } from "src/hooks/coreApi/useTaxiLandmarkUpdateMutation";
import { TaxiLandmarkResponse } from "src/openapi";
import AppDescriptionField from "../../common/AppDescriptionField";
import AppFormLayout, {
  AppFormButtonGroup,
  AppFormError,
  AppFormErrorGroup,
  AppFormGroup,
  AppFormCard,
  AppFormSubmitButton,
} from "../../common/AppFormLayout";

type TaxiLandmarkFormValues = {
  name: string;
  placeId: number;
  address: string;
};

type Props = {
  isEdit?: boolean;
  currentTaxiLandmark?: TaxiLandmarkResponse;
  onSuccess?(): void;
};

export default function TaxiLandmarkForm(props: Props) {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const taxiLandmarkCreateMutation = useTaxiLandmarkCreateMutation();
  const taxiLandmarkUpdateMutation = useTaxiLandmarkUpdateMutation();

  const defaultValues = useMemo<TaxiLandmarkFormValues>(() => {
    return {
      name: props.currentTaxiLandmark?.name || "",
      placeId: props.currentTaxiLandmark?.placeId || 1,
      address: props.currentTaxiLandmark?.address || "",
    };
  }, [props.currentTaxiLandmark]);

  const taxiLandmarkForm = useForm<TaxiLandmarkFormValues>({
    defaultValues,
  });

  useEffect(() => {
    taxiLandmarkForm.reset(defaultValues);
  }, [props]);

  const onSuccess = () => {
    if (props.isEdit) {
      enqueueSnackbar("更新しました");
    }

    if (!props.isEdit) {
      enqueueSnackbar("追加しました");
      taxiLandmarkForm.reset();
      navigate("/taxi/landmarks");
    }
    props?.onSuccess && props.onSuccess();
  };

  const onError = useFormError<TaxiLandmarkFormValues>(taxiLandmarkForm);

  const onSubmit = (values: TaxiLandmarkFormValues) => {
    if (props.isEdit && props?.currentTaxiLandmark?.id) {
      taxiLandmarkUpdateMutation.mutate(
        {
          id: props.currentTaxiLandmark.id,
          taxiLandmarkUpdateRequest: {
            name: values.name,
            placeId: values.placeId,
            address: values.address,
          },
        },
        {
          onSuccess,
          onError,
        }
      );
    }

    if (!props.isEdit) {
      taxiLandmarkCreateMutation.mutate(
        {
          taxiLandmarkCreateRequest: {
            name: values.name,
            placeId: values.placeId,
            address: values.address,
          },
        },
        {
          onSuccess,
          onError,
        }
      );
    }
  };

  return (
    <FormProvider
      methods={taxiLandmarkForm}
      onSubmit={taxiLandmarkForm.handleSubmit(onSubmit)}
    >
      <AppFormLayout>
        <AppFormCard>
          {!taxiLandmarkForm.formState.isValid && (
            <AppFormErrorGroup>
              <AppFormError<TaxiLandmarkFormValues>
                errors={taxiLandmarkForm.formState.errors}
              />
            </AppFormErrorGroup>
          )}

          {props.isEdit && props.currentTaxiLandmark && (
            <AppFormGroup>
              <AppDescriptionField label="ID">
                {props.currentTaxiLandmark.id}
              </AppDescriptionField>
            </AppFormGroup>
          )}

          <AppFormGroup>
            <RHFTextField name="name" label="名前" />
            <RHFTextField name="address" label="住所" />
          </AppFormGroup>

          <AppFormButtonGroup>
            <AppFormSubmitButton
              loading={
                taxiLandmarkCreateMutation.isLoading ||
                taxiLandmarkUpdateMutation.isLoading
              }
            >
              {!props.isEdit ? "作成する" : "保存する"}
            </AppFormSubmitButton>
          </AppFormButtonGroup>
        </AppFormCard>
      </AppFormLayout>
    </FormProvider>
  );
}
