import { UserApiUserListRequest } from "../../openapi/api";
import appApiClient from "./coreApiClient";
import useBaseQuery from "./useBaseQuery";

export const useUserListQuery = (req: UserApiUserListRequest, enabled?: boolean) =>
  useBaseQuery({
    queryKey: ["userList", req],
    queryFn: (config) => appApiClient.userApi.userList(req, config),
    enabled
  });
