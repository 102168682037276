import { Box } from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { RHFCheckbox, RHFSelect, RHFTextField } from "src/components/hook-form";
import FormProvider from "src/components/hook-form/FormProvider";
import useFormError from "src/hooks/app/form/useFormError";
import { useUserUpdateMutation } from "src/hooks/coreApi/useUserUpdateMutation";
import { useUserCreateMutation } from "src/hooks/coreApi/useUserCreateMutation";
import { fDateTime } from "src/utils/formatTime";
import AppDescriptionField from "../../common/AppDescriptionField";
import AppFormLayout, {
  AppFormButtonGroup,
  AppFormError,
  AppFormErrorGroup,
  AppFormGroup,
  AppFormCard,
  AppFormSubmitButton,
} from "../../common/AppFormLayout";
import PlaceSelect from "../../place/PlaceSelect";
import { UserCreateRequest, UserUpdateRequest } from "src/openapi";
import {
  convertUserAuthTypeToParams,
  convertUserParamsToUserAuthType,
  UserAuthType,
  userAuthTypeOptions,
} from "src/utils/user";
import TaxiCompanySelect from "../../taxiCompany/TaxiCompanySelect";
import { toNullableNumber } from "src/utils/form";

type UserFormValues = {
  nickname: string;
  nicknameKana: string;
  email: string;
  phone: string;
  password: string;
  placeId: number;
  isGeneratedPassword: boolean;
  manualPassword: string | null;
  manualPasswordConfirm: string;
  authType: UserAuthType;
  taxiCompanyId: number;
  emailOrPhone?: string;
};

type Props = {
  isEdit?: boolean;
  currentUser?: any; // TODO
  onSuccess?(): void;
};

export default function UserForm(props: Props) {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const userCreateMutation = useUserCreateMutation();
  const userUpdateMutation = useUserUpdateMutation();

  const defaultValues = useMemo<UserFormValues>(() => {
    return {
      nickname: props.currentUser?.nickname || "",
      nicknameKana: props.currentUser?.nicknameKana || "",
      email: props.currentUser?.userInfo?.email || "",
      phone: props.currentUser?.userInfo?.phone || "",
      password: "",
      placeId: props.currentUser?.placeId || null,
      isGeneratedPassword: false,
      manualPassword: null,
      manualPasswordConfirm: "",
      authType: props.currentUser
        ? convertUserParamsToUserAuthType(props.currentUser)
        : "general",
      taxiCompanyId: props.currentUser?.taxiCompanyId || null,
    };
  }, [props.currentUser]);

  const userForm = useForm<UserFormValues>({
    defaultValues,
  });

  useEffect(() => {
    userForm.reset(defaultValues);
  }, [props]);

  const onSuccess = () => {
    if (props.isEdit) {
      enqueueSnackbar("更新しました");
    }

    if (!props.isEdit) {
      enqueueSnackbar("追加しました");
      userForm.reset();
      navigate("/users");
    }

    props?.onSuccess && props.onSuccess();
  };

  const onError = useFormError<UserFormValues>(userForm, {
    "email or phone": "emailOrPhone",
  });

  const onSubmit = (values: UserFormValues) => {
    const authTypeParams = convertUserAuthTypeToParams(
      values.authType,
      values.taxiCompanyId
    );

    const emailOrPhoneParams: Partial<Pick<UserFormValues, "email" | "phone">> =
      {};

    // TODO API側 nilチェックできるように調整
    if (values.email) {
      emailOrPhoneParams.email = values.email;
    }

    if (values.phone) {
      emailOrPhoneParams.phone = values.phone;
    }

    // update
    if (props.isEdit && props.currentUser?.id) {
      const userUpdateRequest: UserUpdateRequest = {
        nickname: values.nickname,
        nicknameKana: values.nicknameKana,
        placeId: toNullableNumber(values.placeId),
        ...authTypeParams,
        ...emailOrPhoneParams,
      };

      userUpdateMutation.mutate(
        {
          id: props.currentUser.id,
          userUpdateRequest,
        },
        {
          onSuccess,
          onError,
        }
      );
    }

    // create
    if (!props.isEdit) {
      const userCreateRequest: UserCreateRequest = {
        nickname: values.nickname,
        nicknameKana: values.nicknameKana,
        placeId: toNullableNumber(values.placeId),
        manualPassword: values.manualPassword,
        ...authTypeParams,
        ...emailOrPhoneParams,
      };

      userCreateMutation.mutate(
        {
          userCreateRequest,
        },
        {
          onSuccess,
          onError,
        }
      );
    }
  };

  return (
    <FormProvider methods={userForm} onSubmit={userForm.handleSubmit(onSubmit)}>
      <AppFormLayout>
        <AppFormCard>
          {!userForm.formState.isValid && (
            <AppFormErrorGroup>
              <AppFormError<UserFormValues>
                errors={userForm.formState.errors}
              />
            </AppFormErrorGroup>
          )}

          {props.isEdit && props.currentUser && (
            <AppFormGroup>
              <AppDescriptionField label="ID">
                {props.currentUser.id}
              </AppDescriptionField>
              <AppDescriptionField label="さあ・くるID">
                {props.currentUser?.userInfo?.code}
              </AppDescriptionField>
              <AppDescriptionField label="作成日時">
                {fDateTime(props.currentUser.createdAt)}
              </AppDescriptionField>
              <AppDescriptionField label="最終更新日時">
                {fDateTime(props.currentUser.updatedAt)}
              </AppDescriptionField>
            </AppFormGroup>
          )}

          <AppFormGroup>
            <RHFTextField name="nickname" label="お名前" />
            <RHFTextField name="nicknameKana" label="お名前(カナ)" />
            <RHFTextField
              name="email"
              label="Eメールアドレス"
              onBlur={() => {
                userForm.clearErrors("emailOrPhone");
              }}
            />
            <RHFTextField
              name="phone"
              label="電話番号"
              onBlur={() => {
                userForm.clearErrors("emailOrPhone");
              }}
            />
            <Box>
              <RHFSelect native name="authType" label="権限">
                {userAuthTypeOptions.map((opt) => (
                  <option key={opt.type} value={opt.type}>
                    {opt.name}
                  </option>
                ))}
              </RHFSelect>
              {userForm.watch("authType") === "taxiCompany" && (
                <Box sx={{ mt: 3 }}>
                  <TaxiCompanySelect
                    name="taxiCompanyId"
                    label="タクシー会社"
                  />
                </Box>
              )}
            </Box>
            <PlaceSelect name="placeId" label="地区" />
          </AppFormGroup>

          {!props.isEdit && (
            <AppFormGroup>
              <Box>
                <RHFCheckbox
                  name="isGeneratedPassword"
                  label="パスワードを自動生成する"
                />
                {userForm.watch("isGeneratedPassword") === false && (
                  <>
                    <RHFTextField
                      sx={{ mt: 3 }}
                      name="manualPassword"
                      label="任意のパスワード"
                      type="password"
                    />
                    <RHFTextField
                      sx={{ mt: 3 }}
                      name="manualPasswordConfirm"
                      label="任意のパスワード(確認)"
                      type="password"
                    />
                  </>
                )}
              </Box>
            </AppFormGroup>
          )}

          <AppFormButtonGroup>
            <AppFormSubmitButton loading={userCreateMutation.isLoading}>
              {!props.isEdit ? "作成する" : "保存する"}
            </AppFormSubmitButton>
          </AppFormButtonGroup>
        </AppFormCard>
      </AppFormLayout>
    </FormProvider>
  );
}
