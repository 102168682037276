import { Button, Card } from "@mui/material";
import {
  DataGridPremium,
  GridColDef,
  GridRenderCellParams,
  GridToolbar,
  jaJP,
} from "@mui/x-data-grid-premium";
import { useMemo } from "react";
import { Link } from "react-router-dom";
import AppContainer from "src/components/app/common/AppContainer";
import Iconify from "src/components/iconify";
import { useTaxiLandmarkListQuery } from "src/hooks/coreApi/useTaxiLandmarkListQuery";
import { TaxiLandmarkResponse } from "src/openapi";
import { PATH_DASHBOARD } from "src/routes/paths";

export default function TaxiLandmarkList() {
  const taxiLandmarkQuery = useTaxiLandmarkListQuery({});
  const taxiLandmarks = taxiLandmarkQuery?.data?.taxiLandmarks;

  const columns = useMemo<GridColDef[]>(() => {
    return [
      { field: "id", headerName: "ID" },
      {
        field: "name",
        headerName: "名前",
        width: 300,
        renderCell(params: GridRenderCellParams<string, TaxiLandmarkResponse>) {
          return (
            <Link to={`/taxi/landmarks/${params.row.id}`}>{params.value}</Link>
          );
        },
      },
    ];
  }, []);

  return (
    <>
      <AppContainer
        meta={{
          title: "ランドマーク一覧",
        }}
        breadcrumbs={{
          heading: "ランドマーク一覧",
          links: [
            { name: "ホーム", href: PATH_DASHBOARD.root },
            { name: "ランドマーク" },
          ],
          action: (
            <>
              <Button
                component={Link}
                to="/taxi/landmarks/add"
                variant="contained"
                startIcon={<Iconify icon="eva:plus-fill" />}
              >
                新規追加
              </Button>
            </>
          ),
        }}
      >
        <Card style={{ height: "800px", width: "100%" }}>
          <DataGridPremium
            rows={taxiLandmarks || []}
            columns={columns}
            isRowSelectable={() => false}
            components={{ Toolbar: GridToolbar }}
            localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
            filterMode="client"
          />
        </Card>
      </AppContainer>
    </>
  );
}
