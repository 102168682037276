import { useNavigate, useParams } from "react-router-dom";
import { useMemo, useEffect } from "react";
// form
import { useForm } from "react-hook-form";
// @mui
import { LoadingButton } from "@mui/lab";
import { Grid, Card, Stack } from "@mui/material";
// routes
import { PATH_DASHBOARD } from "../../../../routes/paths";
// @types
import { IBlogNewPost } from "../../../../@types/blog";
// components
import { useSnackbar } from "../../../snackbar";
import FormProvider, { RHFSwitch, RHFTextField } from "../../../hook-form";
import { useContentCreateMutation } from "src/hooks/coreApi/useContentCreateMutation";
import { useContentUpdateMutation } from "src/hooks/coreApi/useContentUpdateMutation";
import { ContentResponse } from "src/openapi/api";
import { ContentType } from "src/hooks/coreApi/useContentListQuery";
import dayjs from "dayjs";
import useFormError from "src/hooks/app/form/useFormError";
import ContentKinkyuLevelSelect from "../ContentKinkyuLevelSelect";

export type FormValuesProps = IBlogNewPost;

type FormValues = {
  titleJa: string;
  bodyJa: string;
  kinkyuLevel: number;
  releaseAt: string;
};

export type ContentFormProps = {
  isEdit?: boolean;
  currentContent?: ContentResponse;
  onSuccess?(): void;
};

export default function ContentKinkyuForm(props: ContentFormProps) {
  const navigate = useNavigate();

  const { type } = useParams<{ type: ContentType }>();

  const { enqueueSnackbar } = useSnackbar();

  const contentCreateMutation = useContentCreateMutation();
  const contentUpdateMutation = useContentUpdateMutation();

  const defaultValues = useMemo<FormValues>(() => {
    return {
      titleJa: props.currentContent?.titleJa || "",
      bodyJa: props.currentContent?.bodyJa || "",
      kinkyuLevel: props.currentContent?.kinkyuLevel || 1,
      releaseAt:
        dayjs(props.currentContent?.releaseAt).format("YYYY-MM-DDTHH:mm") || "",
    };
  }, [props.currentContent]);

  const contentForm = useForm<FormValues>({
    defaultValues,
  });

  useEffect(() => {
    contentForm.reset(defaultValues);
  }, [props]);

  const onSuccess = () => {
    enqueueSnackbar("保存しました");

    if (!props.isEdit) {
      navigate(PATH_DASHBOARD.content[type as ContentType]);
    }

    props?.onSuccess && props.onSuccess();
  };

  const onError = useFormError<FormValues>(contentForm);

  const onSubmit = (values: FormValues) => {
    if (!type) {
      return;
    }

    if (props.isEdit && props.currentContent) {
      contentUpdateMutation.mutate(
        {
          id: props.currentContent.id,
          contentUpdateRequest: {
            slug: "",
            detailType: "",
            titleJa: values.titleJa,
            titleEn: values.titleJa, // TODO
            bodyJa: values.bodyJa,
            dekigotoVideoUrl: null,
            bodyEn: values.bodyJa, // TODO
            areaIds: [], // TODO
            placeIds: [], // TODO
            status: "public", // TODO
            kinkyuLevel: Number(values.kinkyuLevel),
            releaseAt: dayjs(values.releaseAt).toISOString(),
            // imageFileKey: "tmp/test.jpeg",
          },
        },
        {
          onSuccess,
          onError,
        }
      );
    } else {
      contentCreateMutation.mutate(
        {
          contentCreateRequest: {
            type: type,
            slug: "",
            detailType: "",
            titleJa: values.titleJa,
            titleEn: values.titleJa, // TODO
            bodyJa: values.bodyJa,
            bodyEn: values.bodyJa, // TODO
            dekigotoVideoUrl: null,
            areaIds: [], // TODO
            placeIds: [], // TODO
            status: "public", // TODO
            kinkyuLevel: Number(values.kinkyuLevel),
            releaseAt: dayjs(values.releaseAt).toISOString(),
            // imageFileKey: "tmp/test.jpeg",
          },
        },
        {
          onSuccess,
          onError,
        }
      );
    }
  };

  return (
    <FormProvider
      methods={contentForm}
      onSubmit={contentForm.handleSubmit(onSubmit)}
    >
      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          <Card sx={{ p: 3 }}>
            <Stack spacing={3}>
              <RHFTextField name="titleJa" label="タイトル" />
              <RHFTextField name="bodyJa" label="本文" multiline rows={3} />
              <ContentKinkyuLevelSelect name="kinkyuLevel" label="緊急レベル" />
            </Stack>
          </Card>
        </Grid>

        <Grid item xs={12} md={4}>
          <Card sx={{ p: 3 }}>
            <Stack spacing={3}>
              {/* <div>
                <RHFSwitch
                  name="status"
                  label="公開する"
                  labelPlacement="start"
                  sx={{
                    mb: 1,
                    mx: 0,
                    width: 1,
                    justifyContent: "space-between",
                  }}
                />
              </div> */}
              <RHFTextField
                name="releaseAt"
                label="公開日時"
                type="datetime-local"
                onChange={(evt) => {
                  contentForm.setValue("releaseAt", evt.target.value);
                }}
              />
            </Stack>
          </Card>

          <Stack direction="row" spacing={1.5} sx={{ mt: 3 }}>
            <LoadingButton
              fullWidth
              type="submit"
              variant="contained"
              size="large"
              loading={contentForm.formState.isSubmitting}
            >
              保存する
            </LoadingButton>
          </Stack>
        </Grid>
      </Grid>
    </FormProvider>
  );
}
