import { ContentTagApiContentTagListRequest } from "../../openapi/api";
import appApiClient from "./coreApiClient";
import useBaseQuery from "./useBaseQuery";

export type ContentType = "bousai" | "kairan" | "musen" | "dekigoto" | "kinkyu";

export const useContentTagListQuery = (
  type: ContentType,
  req: ContentTagApiContentTagListRequest
) =>
  useBaseQuery({
    queryKey: ["contentList", type, req],
    queryFn: (config) =>
      appApiClient.contentTagApi.contentTagList(
        {
          ...req,
          contentType: type,
        },
        config
      ),
  });
