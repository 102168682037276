import { useNavigate, useParams } from "react-router-dom";
import AppContainer from "src/components/app/common/AppContainer";
import { useTrashCalendarListQuery } from "src/hooks/coreApi/useTrashCalendarListQuery";
import { PATH_DASHBOARD } from "src/routes/paths";
import TrashCalendarForm from "src/components/app/trashCalendar/TrashCalendarForm";
import AppMenu from "src/components/app/common/AppMenu";
import { useTrashCalendarDeleteMutation } from "src/hooks/coreApi/useTrashCalendarDeleteMutation";
import AppAlertDialog, {
  useAppAlertDialog,
} from "src/components/app/common/AppAlertDialog";
import { useSnackbar } from "notistack";

export default function TrashCalendarEdit() {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const trashCalendarQuery = useTrashCalendarListQuery({ id: Number(id) });
  const trashCalendar = trashCalendarQuery.data?.trashCalendars[0];
  const trashCalendarDeleteMutation = useTrashCalendarDeleteMutation();
  const alertDialog = useAppAlertDialog();
  const { enqueueSnackbar } = useSnackbar();

  const handleDeleteOk = () => {
    if (trashCalendar?.id) {
      trashCalendarDeleteMutation.mutate(
        {
          id: trashCalendar.id,
        },
        {
          onSuccess() {
            enqueueSnackbar("削除しました");
            navigate("/trashCalendars");
          },
          onError(err) {
            // TODO エラー表示
            enqueueSnackbar("サーバーエラーが発生しました", {
              variant: "error",
            });
          },
        }
      );
    }
  };

  return (
    <>
      <AppContainer
        meta={{
          title: "ゴミ出しカレンダー詳細",
        }}
        breadcrumbs={{
          heading: "ゴミ出しカレンダー詳細",
          links: [
            { name: "ホーム", href: PATH_DASHBOARD.root },
            { name: "ゴミ出しカレンダー一覧", href: PATH_DASHBOARD.trash.root },
            { name: "ゴミ出しカレンダー詳細" },
          ],
          action: (
            <AppMenu
              options={[
                {
                  label: "削除する",
                  onClick() {
                    alertDialog.onOpen();
                  },
                },
              ]}
            >
              その他の操作
            </AppMenu>
          ),
        }}
      >
        <TrashCalendarForm isEdit={true} currentTrashCalendar={trashCalendar} />
      </AppContainer>
      {trashCalendar && (
        <AppAlertDialog
          {...alertDialog}
          title="ゴミ出しの削除"
          description={`ゴミ出しカレンダー: ${trashCalendar.name} (ID: )を削除してもよろしいですか？`}
          onOk={handleDeleteOk}
        />
      )}
    </>
  );
}
