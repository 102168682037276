import { useNavigate, useParams } from "react-router-dom";
import AppContainer from "src/components/app/common/AppContainer";
import { usePlaceListQuery } from "src/hooks/coreApi/usePlaceListQuery";
import { PATH_DASHBOARD } from "src/routes/paths";
import PlaceForm from "src/components/app/place/PlaceForm";
import AppMenu from "src/components/app/common/AppMenu";
import { usePlaceDeleteMutation } from "src/hooks/coreApi/usePlaceDeleteMutation";
import AppAlertDialog, {
  useAppAlertDialog,
} from "src/components/app/common/AppAlertDialog";
import { useSnackbar } from "notistack";

export default function PlaceEdit() {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const placeQuery = usePlaceListQuery({ id: Number(id) });
  const place = placeQuery.data?.places[0];
  const placeDeleteMutation = usePlaceDeleteMutation();
  const alertDialog = useAppAlertDialog();
  const { enqueueSnackbar } = useSnackbar();

  const handleDeleteOk = () => {
    if (place?.id) {
      placeDeleteMutation.mutate(
        {
          id: place.id,
        },
        {
          onSuccess() {
            enqueueSnackbar("削除しました");
            navigate("/places");
          },
          onError(err) {
            // TODO エラー表示
            enqueueSnackbar("サーバーエラーが発生しました", {
              variant: "error",
            });
          },
        }
      );
    }
  };

  return (
    <>
      <AppContainer
        meta={{
          title: "地区詳細",
        }}
        breadcrumbs={{
          heading: "地区詳細",
          links: [
            { name: "ホーム", href: PATH_DASHBOARD.root },
            { name: "地区一覧", href: PATH_DASHBOARD.place },
            { name: "地区詳細" },
          ],
          action: (
            <AppMenu
              options={[
                {
                  label: "削除する",
                  onClick() {
                    alertDialog.onOpen();
                  },
                },
              ]}
            >
              その他の操作
            </AppMenu>
          ),
        }}
      >
        <PlaceForm isEdit={true} currentPlace={place} />
      </AppContainer>
      {place && (
        <AppAlertDialog
          {...alertDialog}
          title="地区の削除"
          description={`地区: ${place.name} (ID: )を削除してもよろしいですか？`}
          onOk={handleDeleteOk}
        />
      )}
    </>
  );
}
