import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import AppContainer from "src/components/app/common/AppContainer";
import { PATH_DASHBOARD } from "src/routes/paths";
import { useTaxiCompanyListQuery } from "src/hooks/coreApi/useTaxiCompanyListQuery";
import { Box, Tab, Tabs } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import Iconify from "src/components/iconify";
import AppMenu from "src/components/app/common/AppMenu";
import AppAlertDialog, {
  useAppAlertDialog,
} from "src/components/app/common/AppAlertDialog";
import { useTaxiCompanyDeleteMutation } from "src/hooks/coreApi/useTaxiCompanyDeleteMutation";
import { useSnackbar } from "notistack";
import TaxiCompanyScheduleDaily from "./TaxiCompanyDetailScheduleDaily";
import TaxiCompanyDetailDefault from "./TaxiCompanyDetailDefault";
import { useAuthContext } from "src/auth/useAuthContext";

type TaxiCompanyDetailTabType = "general" | "taxiDiscountApply";

export default function TaxiCompanyDetail() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams<{ id: string }>();
  const [searchParams, setSearchParams] = useSearchParams();
  const defaultTab = searchParams.get("tab") as TaxiCompanyDetailTabType;
  const taxiCompanyListQuery = useTaxiCompanyListQuery({
    id: Number(id),
  });

  const { user } = useAuthContext();

  const alertDialog = useAppAlertDialog();

  const userDeleteMutation = useTaxiCompanyDeleteMutation();

  const taxiCompany = taxiCompanyListQuery.data?.taxiCompanies?.[0];

  const [currentTab, setCurrentTab] = useState<TaxiCompanyDetailTabType>(
    defaultTab || "general"
  );

  useEffect(() => {
    setSearchParams({
      tab: currentTab,
    });
  }, [currentTab]);

  const onSuccess = useCallback(() => {
    taxiCompanyListQuery.refetch();
  }, []);

  const TABS = [
    {
      value: "general",
      label: "基本設定",
      icon: <Iconify icon="ic:round-account-box" />,
      component: (
        <TaxiCompanyDetailDefault
          currentTaxiCompany={taxiCompany}
          onSuccess={onSuccess}
        />
      ),
    },
    {
      value: "dailySchedule",
      label: "受付可能時間(日別設定)",
      icon: <Iconify icon="material-symbols:directions-car-outline" />,
      component: <TaxiCompanyScheduleDaily currentTaxiCompany={taxiCompany} />,
    },
  ];

  const handleDeleteOk = () => {
    if (taxiCompany?.id) {
      userDeleteMutation.mutate(
        {
          id: taxiCompany.id,
        },
        {
          onSuccess() {
            enqueueSnackbar("削除しました");
            navigate("/taxiCompanies");
          },
          onError(err) {
            // TODO エラー表示
            enqueueSnackbar("サーバーエラーが発生しました", {
              variant: "error",
            });
          },
        }
      );
    }
  };

  const action = useMemo(() => {
    if (!user || !user.isOffice) {
      return <></>;
    }

    return (
      <AppMenu
        options={[
          {
            label: "削除する",
            onClick() {
              alertDialog.onOpen();
            },
          },
        ]}
      >
        その他の操作
      </AppMenu>
    );
  }, [user]);

  return (
    <>
      <AppContainer
        meta={{
          title: "タクシー会社詳細",
        }}
        breadcrumbs={{
          heading: "タクシー会社詳細",
          links: [
            { name: "ホーム", href: PATH_DASHBOARD.root },
            { name: "タクシー会社一覧", href: "/taxiCompanies" },
            { name: "タクシー会社詳細" },
          ],
          action,
        }}
      >
        <Tabs
          value={currentTab}
          onChange={(event, newValue) => setCurrentTab(newValue)}
        >
          {TABS.map((tab) => (
            <Tab
              key={tab.value}
              label={tab.label}
              icon={tab.icon}
              value={tab.value}
            />
          ))}
        </Tabs>

        {TABS.map(
          (tab) =>
            tab.value === currentTab && (
              <Box key={tab.value} sx={{ mt: 5 }}>
                {tab.component}
              </Box>
            )
        )}
      </AppContainer>
      {taxiCompany && (
        <AppAlertDialog
          {...alertDialog}
          title="タクシー会社の削除"
          description={`タクシー会社: ${taxiCompany.name} (ID: ${taxiCompany.id})を削除してもよろしいですか？`}
          onOk={handleDeleteOk}
        />
      )}
    </>
  );
}
