import AppContainer from "src/components/app/common/AppContainer";
import { PATH_DASHBOARD } from "src/routes/paths";
import TaxiAchievementForm from "src/components/app/taxiAchievement/TaxiAchievementForm";

export default function TaxiAchievementAdd() {
  return (
    <>
      <AppContainer
        meta={{
          title: "タクシー実績追加",
        }}
        breadcrumbs={{
          heading: "タクシー実績詳細",
          links: [
            { name: "ホーム", href: PATH_DASHBOARD.root },
            { name: "乗車実績一覧", href: PATH_DASHBOARD.taxi.achievement },
            { name: "乗車実績登録" },
          ],
        }}
      >
        <TaxiAchievementForm isEdit={false} />
      </AppContainer>
    </>
  );
}
