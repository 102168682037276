import { useSnackbar } from "notistack";
import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { RHFCheckbox, RHFTextField } from "src/components/hook-form";
import FormProvider from "src/components/hook-form/FormProvider";
import useFormError from "src/hooks/app/form/useFormError";
import { useTaxiAchievementCreateMutation } from "src/hooks/coreApi/useTaxiAchievementCreateMutation";
import { useTaxiAchievementUpdateMutation } from "src/hooks/coreApi/useTaxiAchievementUpdateMutation";
import { TaxiAchievementResponse } from "src/openapi";
import AppDescriptionField from "../../common/AppDescriptionField";
import AppFormLayout, {
  AppFormButtonGroup,
  AppFormError,
  AppFormErrorGroup,
  AppFormGroup,
  AppFormCard,
  AppFormSubmitButton,
} from "../../common/AppFormLayout";
import TaxiAchievementPayMethodSelect from "../TaxiAchievementPayMethodSelect";
import { formatDateTimeRFC3339, zeroValueToDateTime } from "src/utils/app/time";
import dayjs from "dayjs";
import { useAuthContext } from "src/auth/useAuthContext";
import { Box } from "@mui/material";
import TaxiCarSelect from "../../taxiCar/TaxiCarSelect";
import TaxiCompanySelect from "../../taxiCompany/TaxiCompanySelect";

type TaxiAchievementFormValues = {
  taxiCompanyId?: number;
  taxiCarId: number;
  customerUserId: number;
  rideAt: string;
  dropAt: string;
  taxPer: number;
  totalPrice: number;
  initialPrice: number;
  discountPrice: number;
  finalPrice: number;
  approachAutoDistance: number;
  approachAdjustDistance: number;
  approachAutoPrice: number;
  approachAdjustPrice: number;
  rideAutoDistance: number;
  rideAdjustDistance: number;
  rideAutoPrice: number;
  rideAdjustPrice: number;
  waitAutoSec: number;
  waitAdjustSec: number;
  waitAutoPrice: number;
  waitAdjustPrice: number;
  payMethod: string;
  disabilityDiscount: boolean;
  disabilityDiscountPrice: number;
  returningLicenseDiscount: boolean;
  returningLicenseDiscountPrice: number;
  otherDiscount: number;
  tripLog: string;
};

type Props = {
  isEdit?: boolean;
  currentTaxiAchievement?: TaxiAchievementResponse;
  onSuccess?(): void;
};

export default function TaxiAchievementForm(props: Props) {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useAuthContext();
  const taxiAchievementCreateMutation = useTaxiAchievementCreateMutation();
  const taxiAchievementUpdateMutation = useTaxiAchievementUpdateMutation();

  const defaultValues = useMemo<TaxiAchievementFormValues>(() => {
    return {
      taxiCompanyId: props.currentTaxiAchievement?.taxiCompanyId || undefined,
      taxiCarId: props.currentTaxiAchievement?.taxiCarId || 0,
      customerUserId: props.currentTaxiAchievement?.customerUserId || 0,
      rideAt: zeroValueToDateTime(props.currentTaxiAchievement?.rideAt || ""),
      dropAt: zeroValueToDateTime(props.currentTaxiAchievement?.dropAt || ""),
      taxPer: props.currentTaxiAchievement?.taxPer || 0,
      totalPrice: props.currentTaxiAchievement?.totalPrice || 0,
      initialPrice: props.currentTaxiAchievement?.initialPrice || 0,
      discountPrice: props.currentTaxiAchievement?.discountPrice || 0,
      finalPrice: props.currentTaxiAchievement?.finalPrice || 0,
      approachAutoDistance:
        props.currentTaxiAchievement?.approachAutoDistance || 0,
      approachAdjustDistance:
        props.currentTaxiAchievement?.approachAdjustDistance || 0,
      approachAutoPrice: props.currentTaxiAchievement?.approachAutoPrice || 0,
      approachAdjustPrice:
        props.currentTaxiAchievement?.approachAdjustPrice || 0,
      rideAutoDistance: props.currentTaxiAchievement?.rideAutoDistance || 0,
      rideAdjustDistance: props.currentTaxiAchievement?.rideAdjustDistance || 0,
      rideAutoPrice: props.currentTaxiAchievement?.rideAutoPrice || 0,
      rideAdjustPrice: props.currentTaxiAchievement?.rideAdjustPrice || 0,
      waitAutoSec: props.currentTaxiAchievement?.waitAutoSec || 0,
      waitAdjustSec: props.currentTaxiAchievement?.waitAdjustSec || 0,
      waitAutoPrice: props.currentTaxiAchievement?.waitAutoPrice || 0,
      waitAdjustPrice: props.currentTaxiAchievement?.waitAdjustPrice || 0,
      payMethod: props.currentTaxiAchievement?.payMethod || "",
      disabilityDiscount:
        props.currentTaxiAchievement?.disabilityDiscount || false,
      disabilityDiscountPrice:
        props.currentTaxiAchievement?.disabilityDiscountPrice || 0,
      returningLicenseDiscount:
        props.currentTaxiAchievement?.returningLicenseDiscount || false,
      returningLicenseDiscountPrice:
        props.currentTaxiAchievement?.returningLicenseDiscountPrice || 0,
      otherDiscount: props.currentTaxiAchievement?.otherDiscount || 0,
      tripLog: props.currentTaxiAchievement?.tripLog || "",
    };
  }, [props.currentTaxiAchievement]);

  const taxiAchievementForm = useForm<TaxiAchievementFormValues>({
    defaultValues,
  });

  useEffect(() => {
    taxiAchievementForm.reset(defaultValues);
  }, [props]);

  const onSuccess = (res: any) => {
    if (props.isEdit) {
      enqueueSnackbar("更新しました");
    }

    if (!props.isEdit) {
      enqueueSnackbar("追加しました");
      taxiAchievementForm.reset();
      navigate(`/taxi/achievements/${res.data.id}`);
    }
    props?.onSuccess && props.onSuccess();
  };

  const onError = useFormError<TaxiAchievementFormValues>(taxiAchievementForm);

  const onSubmit = (values: TaxiAchievementFormValues) => {
    if (props.isEdit && props?.currentTaxiAchievement?.id) {
      taxiAchievementUpdateMutation.mutate(
        {
          id: props.currentTaxiAchievement.id,
          taxiAchievementUpdateRequest: {
            taxiCompanyId: values.taxiCompanyId
              ? Number(values.taxiCompanyId)
              : null,
            taxiCarId: Number(values.taxiCarId),
            customerUserId: Number(values.customerUserId),
            rideAt: formatDateTimeRFC3339(dayjs(values.rideAt).utc()),
            dropAt: formatDateTimeRFC3339(dayjs(values.dropAt).utc()),
            taxPer: Number(values.taxPer),
            totalPrice: Number(values.totalPrice),
            initialPrice: Number(values.initialPrice),
            discountPrice: Number(values.discountPrice),
            finalPrice: Number(values.finalPrice),
            approachAutoDistance: Number(values.approachAutoDistance),
            approachAdjustDistance: Number(values.approachAdjustDistance),
            approachAutoPrice: Number(values.approachAutoPrice),
            approachAdjustPrice: Number(values.approachAdjustPrice),
            rideAutoDistance: Number(values.rideAutoDistance),
            rideAdjustDistance: Number(values.rideAdjustDistance),
            rideAutoPrice: Number(values.rideAutoPrice),
            rideAdjustPrice: Number(values.rideAdjustPrice),
            waitAutoSec: Number(values.waitAutoSec),
            waitAdjustSec: Number(values.waitAdjustSec),
            waitAutoPrice: Number(values.waitAutoPrice),
            waitAdjustPrice: Number(values.waitAdjustPrice),
            payMethod: values.payMethod,
            disabilityDiscount: values.disabilityDiscount,
            disabilityDiscountPrice: values.disabilityDiscountPrice,
            returningLicenseDiscount: values.returningLicenseDiscount,
            returningLicenseDiscountPrice: values.returningLicenseDiscountPrice,
            otherDiscount: Number(values.otherDiscount),
            tripLog: values.tripLog,
          },
        },
        {
          onSuccess,
          onError,
        }
      );
    }

    if (!props.isEdit) {
      taxiAchievementCreateMutation.mutate(
        {
          taxiAchievementCreateRequest: {
            taxiCompanyId: values.taxiCompanyId
              ? Number(values.taxiCompanyId)
              : null,
            taxiCarId: Number(values.taxiCarId),
            customerUserId: Number(values.customerUserId),
            rideAt: formatDateTimeRFC3339(dayjs(values.rideAt).utc()),
            dropAt: formatDateTimeRFC3339(dayjs(values.dropAt).utc()),
            taxPer: Number(values.taxPer),
            totalPrice: Number(values.totalPrice),
            initialPrice: Number(values.initialPrice),
            discountPrice: Number(values.discountPrice),
            finalPrice: Number(values.finalPrice),
            approachAutoDistance: Number(values.approachAutoDistance),
            approachAdjustDistance: Number(values.approachAdjustDistance),
            approachAutoPrice: Number(values.approachAutoPrice),
            approachAdjustPrice: Number(values.approachAdjustPrice),
            rideAutoDistance: Number(values.rideAutoDistance),
            rideAdjustDistance: Number(values.rideAdjustDistance),
            rideAutoPrice: Number(values.rideAutoPrice),
            rideAdjustPrice: Number(values.rideAdjustPrice),
            waitAutoSec: Number(values.waitAutoSec),
            waitAdjustSec: Number(values.waitAdjustSec),
            waitAutoPrice: Number(values.waitAutoPrice),
            waitAdjustPrice: Number(values.waitAdjustPrice),
            payMethod: values.payMethod,
            disabilityDiscount: values.disabilityDiscount,
            disabilityDiscountPrice: Number(values.disabilityDiscountPrice),
            returningLicenseDiscount: values.returningLicenseDiscount,
            returningLicenseDiscountPrice: Number(
              values.returningLicenseDiscountPrice
            ),
            otherDiscount: Number(values.otherDiscount),
            tripLog: values.tripLog,
          },
        },
        {
          onSuccess,
          onError,
        }
      );
    }
  };

  const taxiCompanyId = taxiAchievementForm.watch("taxiCompanyId");
  const taxiCompanyReq = { taxiCompanyId };

  return (
    <FormProvider
      methods={taxiAchievementForm}
      onSubmit={taxiAchievementForm.handleSubmit(onSubmit)}
    >
      <AppFormLayout>
        <AppFormCard>
          {!taxiAchievementForm.formState.isValid && (
            <AppFormErrorGroup>
              <AppFormError<TaxiAchievementFormValues>
                errors={taxiAchievementForm.formState.errors}
              />
            </AppFormErrorGroup>
          )}

          <AppFormGroup>
            {props.isEdit && props.currentTaxiAchievement && (
              <AppDescriptionField label="ID">
                {props.currentTaxiAchievement.id}
              </AppDescriptionField>
            )}
            {user?.isOffice === true && (
              <TaxiCompanySelect
                name="taxiCompanyId"
                label="タクシー会社ID"
                disabled={props.isEdit === true}
              />
            )}
          </AppFormGroup>

          <AppFormGroup>
            <TaxiCarSelect
              name="taxiCarId"
              label="車両ID"
              req={taxiCompanyReq}
            />
            <RHFTextField name="customerUserId" label="顧客ユーザーID" />
            <RHFTextField
              name="rideAt"
              label="乗車日時"
              type="datetime-local"
              onChange={(evt) => {
                taxiAchievementForm.setValue("rideAt", evt.target.value);
              }}
            />
            <RHFTextField
              name="dropAt"
              label="降車日時"
              type="datetime-local"
              onChange={(evt) => {
                taxiAchievementForm.setValue("dropAt", evt.target.value);
              }}
            />
            <RHFTextField name="totalPrice" label="通常料金" />
            <RHFTextField name="initialPrice" label="初乗料金" />
            <RHFTextField name="discountPrice" label="助成額" />
            <RHFTextField name="finalPrice" label="支払い料金" />
            <RHFTextField
              name="approachAutoDistance"
              label="迎車距離(自動計測)"
            />
            <RHFTextField
              name="approachAdjustDistance"
              label="迎車距離(調整後)"
            />
            <RHFTextField name="approachAutoPrice" label="迎車料金(自動計測)" />
            <RHFTextField name="approachAdjustPrice" label="迎車料金(調整後)" />
            <RHFTextField name="rideAutoDistance" label="乗車距離(自動計測)" />
            <RHFTextField name="rideAdjustDistance" label="乗車距離(調整後)" />
            <RHFTextField name="rideAutoPrice" label="乗車料金(自動計測)" />
            <RHFTextField name="rideAdjustPrice" label="乗車料金(調整後)" />
            <RHFTextField name="waitAutoSec" label="待機時間(自動計測)" />
            <RHFTextField name="waitAdjustSec" label="待機時間(調整後)" />
            <RHFTextField name="waitAutoPrice" label="待機料金(自動計測)" />
            <RHFTextField name="waitAdjustPrice" label="待機料金(調整後)" />
            <TaxiAchievementPayMethodSelect name="payMethod" label="決済方法" />
            <RHFCheckbox name="disabilityDiscount" label="障害者割引" />
            <RHFTextField
              name="disabilityDiscountPrice"
              label="障害者割引金額"
            />
            <RHFCheckbox name="returningLicenseDiscount" label="免許返納割引" />
            <RHFTextField
              name="returningLicenseDiscountPrice"
              label="免許返納割引金額"
            />
            <RHFTextField name="otherDiscount" label="その他割引金額" />
          </AppFormGroup>

          <AppFormButtonGroup>
            <AppFormSubmitButton
              loading={taxiAchievementUpdateMutation.isLoading}
            >
              {!props.isEdit ? "作成する" : "保存する"}
            </AppFormSubmitButton>
          </AppFormButtonGroup>
        </AppFormCard>
      </AppFormLayout>
    </FormProvider>
  );
}
