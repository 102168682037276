import { RHFSelect } from "src/components/hook-form";
import { usePlaceListQuery } from "src/hooks/coreApi/usePlaceListQuery";
import { PlaceApiPlaceListRequest } from "src/openapi";

type PlaceSelectProps = {
  name: string;
  label: string;
  req?: PlaceApiPlaceListRequest;
};

export default function PlaceSelect(props: PlaceSelectProps) {
  const placeListQuery = usePlaceListQuery(props.req || {});

  const places = placeListQuery?.data?.places || [];

  if (placeListQuery.isLoading) {
    return null;
  }

  return (
    <RHFSelect native name={props.name} label={props.label}>
      <option value="">地区を選択してください</option>
      {places.map((place) => {
        return (
          <option key={place.id} value={place.id}>
            {place.name}
          </option>
        );
      })}
    </RHFSelect>
  );
}
