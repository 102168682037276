import { TaxiDailyScheduleApiTaxiDailyScheduleListRequest } from "../../openapi";
import appApiClient from "./coreApiClient";
import useBaseQuery from "./useBaseQuery";

export const useTaxiDailyScheduleListQuery = (
  req: TaxiDailyScheduleApiTaxiDailyScheduleListRequest
) =>
  useBaseQuery({
    queryKey: ["taxiDailyScheduleList"],
    queryFn: (config) =>
      appApiClient.taxiDailyScheduleApi.taxiDailyScheduleList(req, config),
  });
