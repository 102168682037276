import { useNavigate, useParams } from "react-router-dom";
import { useMemo, useEffect, useCallback, useState } from "react";
// form
import { useForm } from "react-hook-form";
// @mui
import { LoadingButton } from "@mui/lab";
import { Grid, Card, Stack, Box, Typography } from "@mui/material";
// routes
import { PATH_DASHBOARD } from "../../../../routes/paths";
// @types
import { IBlogNewPost } from "../../../../@types/blog";
// components
import { useSnackbar } from "../../../snackbar";
import FormProvider, {
  RHFTextField,
  RHFUploadBox,
  RHFAutocomplete,
} from "../../../hook-form";
import { useContentCreateMutation } from "src/hooks/coreApi/useContentCreateMutation";
import { useContentUpdateMutation } from "src/hooks/coreApi/useContentUpdateMutation";
import { ContentResponse } from "src/openapi/api";
import { ContentType } from "src/hooks/coreApi/useContentListQuery";
import dayjs from "dayjs";
import useFormError from "src/hooks/app/form/useFormError";
import { coreApiAxiosInstance } from "src/hooks/coreApi/coreApiClient";
import axios from "axios";
import Iconify from "src/components/iconify";
import { CustomFile, FilePreview } from "./FilePreview";
import { useContentTagListQuery } from "src/hooks/coreApi/useContentTagListQuery";
import DeleteIcon from "@mui/icons-material/Delete";

export type FormValuesProps = IBlogNewPost;

type FormValues = {
  titleJa: string;
  bodyJa: string;
  releaseAt: string;
  kairanPdfFile: CustomFile | null;
  imageFile: CustomFile | null;
  removeFileIds: number[] | null;
  tagLabels: string[];
};

export type ContentFormProps = {
  isEdit?: boolean;
  currentContent?: ContentResponse;
  onSuccess?(): void;
};

export default function ContentKairanForm(props: ContentFormProps) {
  const navigate = useNavigate();

  const { type } = useParams<{ type: ContentType }>();

  const { enqueueSnackbar } = useSnackbar();

  const contentCreateMutation = useContentCreateMutation();
  const contentUpdateMutation = useContentUpdateMutation();

  const contentTagListQuery = useContentTagListQuery("kairan", {});
  const contentTags = contentTagListQuery.data?.contentTags || [];

  const defaultValues = useMemo<FormValues>(() => {
    const kairanPdfFile = props.currentContent?.files?.find(
      (file) => file.type === "content_kairan_pdf"
    );
    const kairanImageFile = props.currentContent?.files?.find(
      (file) => file.type === "content_image"
    );
    return {
      titleJa: props.currentContent?.titleJa || "",
      bodyJa: props.currentContent?.bodyJa || "",
      releaseAt:
        dayjs(props.currentContent?.releaseAt).format("YYYY-MM-DDTHH:mm") || "",
      kairanPdfFile: kairanPdfFile
        ? {
            name: kairanPdfFile.name,
            key: kairanPdfFile.key,
            path: kairanPdfFile.cloudFrontUrl,
            id: kairanPdfFile.id,
          }
        : null,
      imageFile: kairanImageFile
        ? {
            name: kairanImageFile.name,
            key: kairanImageFile.key,
            path: kairanImageFile.cloudFrontUrl,
            id: kairanImageFile.id,
          }
        : null,
      removeFileIds: null,
      tagLabels: props.currentContent?.contentTags?.map((t) => t.label) || [],
    };
  }, [props.currentContent]);

  const contentForm = useForm<FormValues>({
    defaultValues,
  });

  useEffect(() => {
    contentForm.reset(defaultValues);
  }, [props]);

  const onSuccess = () => {
    enqueueSnackbar("保存しました");

    if (!props.isEdit) {
      contentForm.reset();
      navigate(PATH_DASHBOARD.content[type as ContentType]);
    }

    props?.onSuccess && props.onSuccess();
  };

  const onError = useFormError<FormValues>(contentForm, {
    name: "name",
  });

  const onClickDeleteTag = (index: number) => {
    const confirmOk = window.confirm(
      "このタグを削除しますか？\n（削除すると他のコンテンツに紐づいているタグも削除されます）"
    );
    if (confirmOk) {
      enqueueSnackbar("タグを削除しました");
    }
  };

  const onSubmit = (values: FormValues) => {
    if (!type) {
      return;
    }

    if (props.isEdit && props.currentContent) {
      contentUpdateMutation.mutate(
        {
          id: props.currentContent.id,
          contentUpdateRequest: {
            slug: props.currentContent.slug,
            detailType: "",
            titleJa: values.titleJa,
            titleEn: values.titleJa, // TODO
            bodyJa: values.bodyJa,
            dekigotoVideoUrl: null,
            bodyEn: values.bodyJa, // TODO
            areaIds: [], // TODO
            placeIds: [], // TODO
            status: "public", // TODO
            kinkyuLevel: null,
            kairanPdfFileKey: values.kairanPdfFile?.key
              ? values.kairanPdfFile.key
              : undefined,
            imageFileKey: values.imageFile?.key
              ? values.imageFile.key
              : undefined,
            releaseAt: dayjs(values.releaseAt).toISOString(),
            tagLabels: values.tagLabels,
            // @ts-ignore
            removeFileIds: values.removeFileIds,
          },
        },
        {
          onSuccess,
          onError,
        }
      );
    } else {
      contentCreateMutation.mutate(
        {
          contentCreateRequest: {
            type: type,
            slug: "",
            detailType: "",
            titleJa: values.titleJa,
            titleEn: values.titleJa, // TODO
            bodyJa: values.bodyJa,
            bodyEn: values.bodyJa, // TODO
            dekigotoVideoUrl: null,
            areaIds: [], // TODO
            placeIds: [], // TODO
            status: "public", // TODO
            kinkyuLevel: null,
            kairanPdfFileKey: values.kairanPdfFile
              ? values.kairanPdfFile.key
              : undefined,
            imageFileKey: values.imageFile?.key
              ? values.imageFile.key
              : undefined,
            releaseAt: dayjs(values.releaseAt).toISOString(),
            tagLabels: values.tagLabels,
          },
        },
        {
          onSuccess,
          onError,
        }
      );
    }
  };

  const currentKairanPdfFile = contentForm.watch("kairanPdfFile");
  const currentImageFile = contentForm.watch("imageFile");

  const handleDropKairanPdf = async (acceptedFiles: File[]) => {
    const file = acceptedFiles[0];
    // TODO 型を修正
    const presignRes = await coreApiAxiosInstance.post("/files/presignPutUrl", {
      fileName: file.name,
    });

    if (!presignRes.data) {
      return;
    }

    const params = new FormData();
    params.append("file", file);

    const preSignedUrl = presignRes.data.url;
    const options = {
      headers: {
        "Content-Type": file.type,
      },
    };
    await axios.put(preSignedUrl, file, options);

    if (currentKairanPdfFile?.id) {
      // サーバー保存済みのファイルがあれば削除
      contentForm.setValue("removeFileIds", [currentKairanPdfFile.id]);
    }

    contentForm.setValue("kairanPdfFile", {
      name: file.name,
      key: presignRes.data.key,
      path: URL.createObjectURL(file),
    });
  };

  const handleRemoveKairanPdf = (file: CustomFile) => {
    if (file?.id) {
      // サーバー保存済みのファイルの場合
      contentForm.setValue("removeFileIds", [file.id]);
    }
    contentForm.setValue("kairanPdfFile", null);
  };

  const handleDropImage = async (acceptedFiles: File[]) => {
    const file = acceptedFiles[0];
    // TODO 型を修正
    const presignRes = await coreApiAxiosInstance.post("/files/presignPutUrl", {
      fileName: file.name,
    });

    if (!presignRes.data) {
      return;
    }

    const params = new FormData();
    params.append("file", file);

    const preSignedUrl = presignRes.data.url;
    const options = {
      headers: {
        "Content-Type": file.type,
      },
    };
    await axios.put(preSignedUrl, file, options);

    if (currentImageFile?.id) {
      // サーバー保存済みのファイルがあれば削除
      contentForm.setValue("removeFileIds", [currentImageFile.id]);
    }

    contentForm.setValue("imageFile", {
      name: file.name,
      key: presignRes.data.key,
      path: URL.createObjectURL(file),
    });
  };

  const handleRemoveImage = (file: CustomFile) => {
    if (file?.id) {
      // サーバー保存済みのファイルの場合
      contentForm.setValue("removeFileIds", [file.id]);
    }
    contentForm.setValue("imageFile", null);
  };

  return (
    <FormProvider
      methods={contentForm}
      onSubmit={contentForm.handleSubmit(onSubmit)}
    >
      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          <Card sx={{ p: 3 }}>
            <Stack spacing={3}>
              <RHFTextField name="titleJa" label="タイトル" />
              <RHFTextField name="bodyJa" label="本文" multiline rows={3} />
              <Stack spacing={1}>
                <Typography
                  variant="subtitle2"
                  sx={{ color: "text.secondary" }}
                >
                  画像
                </Typography>
              </Stack>
              <Stack>
                <RHFUploadBox
                  name="imageFileKey"
                  onDrop={handleDropImage}
                  placeholder={
                    <Stack spacing={0.5} alignItems="center">
                      <Iconify icon="eva:cloud-upload-fill" width={40} />
                      <Typography variant="body2">Upload file</Typography>
                    </Stack>
                  }
                  sx={{
                    flexGrow: 1,
                    width: "100%",
                    height: "auto",
                    py: 2.5,
                    mb: 3,
                  }}
                />
                <FilePreview
                  file={currentImageFile}
                  onRemove={handleRemoveImage}
                />
              </Stack>
              <Stack spacing={1}>
                <Typography
                  variant="subtitle2"
                  sx={{ color: "text.secondary" }}
                >
                  添付ファイル（PDF）
                </Typography>
              </Stack>
              <Stack>
                <RHFUploadBox
                  name="kairanPdfFileKey"
                  onDrop={handleDropKairanPdf}
                  placeholder={
                    <Stack spacing={0.5} alignItems="center">
                      <Iconify icon="eva:cloud-upload-fill" width={40} />
                      <Typography variant="body2">Upload file</Typography>
                    </Stack>
                  }
                  sx={{
                    flexGrow: 1,
                    width: "100%",
                    height: "auto",
                    py: 2.5,
                    mb: 3,
                  }}
                />
                <FilePreview
                  file={currentKairanPdfFile}
                  onRemove={handleRemoveKairanPdf}
                />
              </Stack>
            </Stack>
          </Card>
        </Grid>

        <Grid item xs={12} md={4}>
          <Card sx={{ p: 3 }}>
            <Stack spacing={3}>
              <RHFAutocomplete
                name="tagLabels"
                label="カテゴリ"
                freeSolo
                multiple
                options={contentTags.map((c) => c.label)}
                ChipProps={{ size: "small" }}
                renderOption={(props, option, { selected }) => {
                  return (
                    <li {...props}>
                      <Box
                        width="100%"
                        display="flex"
                        justifyContent="space-between"
                      >
                        <div>{option}</div>
                        <div style={{ fontSize: "10px" }}>
                          <DeleteIcon
                            htmlColor="gray"
                            onClick={() => {
                              onClickDeleteTag(1);
                            }}
                          />
                        </div>
                      </Box>
                    </li>
                  );
                }}
              />
              <RHFTextField
                name="releaseAt"
                label="公開日時"
                type="datetime-local"
                onChange={(evt) => {
                  contentForm.setValue("releaseAt", evt.target.value);
                }}
              />
            </Stack>
          </Card>

          <Stack direction="row" spacing={1.5} sx={{ mt: 3 }}>
            <LoadingButton
              fullWidth
              type="submit"
              variant="contained"
              size="large"
              loading={contentForm.formState.isSubmitting}
            >
              保存する
            </LoadingButton>
          </Stack>
        </Grid>
      </Grid>
    </FormProvider>
  );
}
