// routes
import { PATH_DASHBOARD } from "../../../routes/paths";
// components
import SvgColor from "../../../components/svg-color";

import { NavSectionProps } from "src/components/nav-section";

// ----------------------------------------------------------------------

const icon = (name: string) => (
  <SvgColor
    src={`/assets/icons/navbar/${name}.svg`}
    sx={{ width: 1, height: 1 }}
  />
);

const ICONS = {
  user: icon("ic_user"),
  blank: icon("ic_blank"),
  ecommerce: icon("ic_ecommerce"),
  analytics: icon("ic_analytics"),
  dashboard: icon("ic_dashboard"),
};

const navConfig: NavSectionProps["data"] = [
  // ----------------------------------------------------------------------
  {
    subheader: "基本設定",
    items: [
      {
        title: "ユーザー",
        path: PATH_DASHBOARD.user.list,
        icon: ICONS.user,
        roles: ["office"],
      },
      {
        title: "居住エリア",
        path: PATH_DASHBOARD.place,
        icon: ICONS.blank,
        roles: ["office"],
        children: [
          {
            title: "地区",
            path: PATH_DASHBOARD.place,
            icon: ICONS.blank,
            roles: ["office"],
          },
          {
            title: "集落",
            path: PATH_DASHBOARD.area,
            icon: ICONS.blank,
            roles: ["office"],
          },
        ],
      },
      {
        title: "設定",
        path: PATH_DASHBOARD.setting.root,
        icon: ICONS.blank,
        roles: ["office", "taxiCompany"],
        children: [
          {
            title: "VPNテスト",
            path: PATH_DASHBOARD.setting.vpnTest,
            icon: ICONS.blank,
            roles: ["office", "taxiCompany"],
          },
        ],
      },
    ],
  },
  {
    subheader: "各種機能",
    items: [
      {
        title: "まちのクルマLet's",
        icon: ICONS.blank,
        path: PATH_DASHBOARD.taxi.root,
        roles: ["office", "taxiCompany"],
        children: [
          {
            title: "予約",
            path: PATH_DASHBOARD.taxi.reservation,
            roles: ["office", "taxiCompany"],
          },
          {
            title: "運行実績",
            path: PATH_DASHBOARD.taxi.achievement,
            roles: ["office", "taxiCompany"],
          },
          {
            title: "運行会社",
            path: PATH_DASHBOARD.taxi.company,
            roles: ["office", "taxiCompany"],
          },
          {
            title: "ランドマーク",
            path: PATH_DASHBOARD.taxi.landmark,
            roles: ["office"],
          },
        ],
      },
      {
        title: "コンテンツ配信",
        icon: ICONS.blank,
        path: PATH_DASHBOARD.content.root,
        roles: ["office"],
        children: [
          {
            title: "神山ch",
            path: PATH_DASHBOARD.content.dekigoto,
            roles: ["office"],
          },
          {
            title: "お知らせ",
            path: PATH_DASHBOARD.content.kairan,
            roles: ["office"],
          },
          {
            title: "防災情報",
            path: PATH_DASHBOARD.content.bousai,
            roles: ["office"],
          },
          {
            title: "防災無線",
            path: PATH_DASHBOARD.content.musen,
            roles: ["office"],
          },
          {
            title: "緊急情報",
            path: PATH_DASHBOARD.content.kinkyu,
            roles: ["office"],
          },
        ],
      },
      {
        title: "ごみの日",
        icon: ICONS.blank,
        path: PATH_DASHBOARD.trash.root,
        roles: ["office"],
        children: [
          {
            title: "カレンダー",
            path: PATH_DASHBOARD.trash.calendar,
            roles: ["office"],
          },
        ],
      },
      {
        title: "ビデオ通話",
        icon: ICONS.blank,
        path: PATH_DASHBOARD.video.root,
        roles: ["office"],
        children: [
          {
            title: "ルーム",
            path: PATH_DASHBOARD.video.room,
            roles: ["office"],
          },
        ],
      },
    ],
  },
];

export default navConfig;
