import { Box, Card, Stack } from "@mui/material";
import Iconify from "src/components/iconify";

export type CustomFile = {
  name: string;
  key: string;
  path: string;
  id?: number;
};

type FilePreviewProps = {
  file: CustomFile | null;
  onRemove(file: CustomFile): void;
};

function extractFileExtension(url: string): string | null {
  const regex = /(?:\.([^.]+))?$/;
  const match = regex.exec(url);
  return match ? match[1] : null;
}

type FileType = "pdf" | "image" | "voice" | "other";
const getFileType = (fileName: string): FileType => {
  const ext = extractFileExtension(fileName);
  if (!ext) {
    return "other";
  }

  switch (ext.toLowerCase()) {
    case "pdf":
      return "pdf";
    case "png":
    case "jpg":
    case "jpeg":
      return "image";
    default:
      return "other";
  }
};

export function FilePreview(props: FilePreviewProps) {
  if (!props.file) {
    return null;
  }

  const fileType = getFileType(props.file.name);

  return (
    <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
      <Card
        variant="outlined"
        onClick={() => {
          window.open(props.file?.path);
        }}
        sx={{
          py: 1,
          px: 2,
          borderRadius: 1.5,
          textTransform: "capitalize",
          flexGrow: 1,
          cursor: "pointer",
        }}
      >
        <Stack direction="row" justifyContent="flex-start" alignItems="center">
          {fileType === "image" ? (
            <img src={props.file.path} alt="" />
          ) : (
            <>
              <Iconify
                icon="ic:outline-insert-drive-file"
                width={40}
                color="#919eab"
              />
              <Box sx={{ ml: 1 }}>{props.file.name}</Box>
            </>
          )}
        </Stack>
      </Card>
      <Iconify
        onClick={() => {
          if (props.file) {
            props.onRemove(props.file);
          }
        }}
        icon="material-symbols:close"
        width={30}
        color="#919eab"
        sx={{ ml: 1, cursor: "pointer" }}
      />
    </Box>
  );
}
