import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Stack,
  Button,
  Box,
  ButtonProps,
  Typography,
} from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import { styled } from "@mui/system";
import dayjs, { Dayjs } from "dayjs";
import { produce } from "immer";
import { atom, useRecoilState, useRecoilValue } from "recoil";

const isSameOrAfter = require("dayjs/plugin/isSameOrAfter");
const isSameOrBefore = require("dayjs/plugin/isSameOrBefore");
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

const TABLE_SPACING = "0.2em";

const StyledRow = styled(TableRow)(({ theme }) => ({
  paddingTop: TABLE_SPACING,
  paddingBottom: TABLE_SPACING,
  "&:hover": {
    backgroundColor: "rgba(0, 0, 0, 0.07)", // ホバー時の背景色
  },
}));

const StyledCell = styled(TableCell)(({ theme }) => ({
  paddingTop: TABLE_SPACING,
  paddingBottom: TABLE_SPACING,
}));

const HeaderRow = styled(StyledRow)(({ theme }) => ({
  position: "sticky",
  // 他のスタイルプロパティもここに追加できます
  top: 0,
  zIndex: 2,
  backgroundColor: "white",
}));

const FixedColumn = styled(StyledCell)(({ theme }) => ({
  position: "sticky",
  // 他のスタイルプロパティもここに追加できます
  left: 0,
  zIndex: 1,
  backgroundColor: "white",
}));

const StyledTable = styled(Table)(({ theme }) => ({
  tableCell: {
    border: "1px solid black",
  },
}));

const INTERVAL_MINUTES = 15;

type ToggleButtonProps = {
  isOn: boolean;
  onClick(): void;
  disabled?: boolean;
};
const ToggleButton = function (props: ToggleButtonProps) {
  const [innerIsOn, setInnerIsOn] = useState<null | boolean>(null);
  const buttonProps: ButtonProps = {
    size: "small",
    variant: "outlined",
  };

  useEffect(() => {
    setInnerIsOn(null);
  }, [props.isOn]);

  const showIsOn = innerIsOn === null ? props.isOn : innerIsOn;

  if (showIsOn) {
    buttonProps.color = "primary";
  } else {
    buttonProps.color = "error";
  }

  return (
    <Button
      {...buttonProps}
      disabled={props.disabled}
      onClick={() => {
        setInnerIsOn(!showIsOn);
        props.onClick();
      }}
    >
      <Typography sx={{ fontSize: "1.8rem" }}>
        {" "}
        {showIsOn ? "○" : "×"}
      </Typography>
    </Button>
  );
};

export type TaxiCompanyScheduleTableDataRow = {
  day: string;
  times: string[];
};

type TaxiCompanyScheduleTableHeaderType = "dayofWeek" | "day";

type TaxiCompanyScheduleTableProps = {
  dataRows: TaxiCompanyScheduleTableValues;
  headerType: TaxiCompanyScheduleTableHeaderType;
  startTime: string;
  endTime: string;
  minDate: Dayjs;
  maxDate: Dayjs;
  callbackDisabled?(day: string, time: string): boolean;
};

export type TaxiCompanyScheduleTableValues = {
  day: string;
  times: string[];
}[];

const getDayLabel = (
  type: TaxiCompanyScheduleTableHeaderType,
  day: Dayjs
): string => {
  const date = dayjs(day);
  switch (type) {
    case "dayofWeek":
      return date.format("ddd");
    case "day":
    default:
      return date.format("MM/DD(ddd)");
  }
};

const cellValuesState = atom<TaxiCompanyScheduleTableValues>({
  key: "TaxiCompanyScheduleTableValues",
  default: [],
});

export function useTaxiCompanySchedulTableValues() {
  return useRecoilValue(cellValuesState);
}

export function TaxiCompanyScheduleTable(props: TaxiCompanyScheduleTableProps) {
  // 週カーソル月曜起点 state
  const [currentWeekStartDate, setCurrentWeekStartDate] = useState<Dayjs>(
    props.minDate.startOf("week").startOf("day")
  );

  // 週カーソル日曜終点
  const currentWeekEndDate = useMemo<Dayjs>(() => {
    return currentWeekStartDate.add(6, "day").endOf("day");
  }, [currentWeekStartDate]);

  // 期間判定
  const isCurrentWeek = useCallback(
    (time: Dayjs) => {
      return (
        time.isSameOrAfter(currentWeekStartDate) &&
        time.isSameOrBefore(currentWeekEndDate)
      );
    },
    [currentWeekStartDate, currentWeekEndDate]
  );

  // 列(日)固有の設定
  const allDayOptions = useMemo(() => {
    // minDateからmaxDateまでのdayjs型の配列を作成
    const tmpDayOptions = [];
    for (
      let d = props.minDate;
      d.isSameOrBefore(props.maxDate);
      d = d.add(1, "day")
    ) {
      tmpDayOptions.push({
        day: d.format("YYYY-MM-DD"),
        label: getDayLabel(props.headerType, d),
      });
    }

    return tmpDayOptions;
  }, []);

  // 列(日)固有の設定(カーソル週)
  const displayDayOptions = useMemo(() => {
    return allDayOptions.filter((opt) => {
      return isCurrentWeek(dayjs(opt.day));
    });
  }, [allDayOptions, currentWeekStartDate]);

  // 行(時間)固有の設定
  const timeOptions = useMemo(() => {
    const tmpRowOptions = [];
    let time = dayjs().startOf("day");
    const ymd = dayjs().format("YYYY-MM-DD");
    const startTime = dayjs(`${ymd} ${props.startTime}`);
    const endTime = dayjs(`${ymd} ${props.endTime}`);

    while (time.isBefore(dayjs().endOf("day"))) {
      // 営業時間 07:00〜の場合、07:00は含むので isBeforeを使う
      // 営業時間〜19:00の場合、19:00は含まないので isSameOrAfterを使う
      if (time.isBefore(startTime) || time.isSameOrAfter(endTime)) {
        time = time.add(INTERVAL_MINUTES, "minute");
        continue;
      }

      const t = time.format("HH:mm");
      tmpRowOptions.push({ time: t, label: t });
      time = time.add(INTERVAL_MINUTES, "minute");
    }

    return tmpRowOptions;
  }, [props.startTime, props.endTime]);

  // 全てのセル状態を保存するrecoil state 親コンポーネントからも参照できるようにする
  const [cellValues, setCellValues] = useRecoilState(cellValuesState);

  // props.dataRowsから初期化
  useEffect(() => {
    const initialValues: TaxiCompanyScheduleTableValues = [];
    allDayOptions.forEach((opt) => {
      const row = props.dataRows.find((r) => opt.day === r.day);
      if (row) {
        initialValues.push({
          day: opt.day,
          times: row.times,
        });
      } else {
        initialValues.push({
          day: opt.day,
          times: [],
        });
      }
    });
    setCellValues(initialValues);
  }, [props.dataRows]);

  const displayCellValues = useMemo(() => {
    return cellValues.filter((v) => {
      return isCurrentWeek(dayjs(v.day));
    });
  }, [cellValues, currentWeekStartDate]);

  // 前週にカーソル移動
  const onClickBeforeWeek = () => {
    setCurrentWeekStartDate(currentWeekStartDate.add(-7, "day"));
  };

  // 次週にカーソル移動
  const onClickAfterWeek = () => {
    setCurrentWeekStartDate(currentWeekStartDate.add(7, "day"));
  };

  // 週ページネーションが必要かどうか
  const isNeedWeekCursor = useMemo<boolean>(() => {
    return allDayOptions.length > 7;
  }, [allDayOptions]);

  // cellクリックハンドラ
  const onClickCell = (day: string, time: string) => {
    setCellValues(
      produce(cellValues, (draft) => {
        const colIndex = draft.findIndex((v) => v.day === day);
        const rowIndex = draft[colIndex].times.findIndex((v) => v === time);
        if (rowIndex === -1) {
          draft[colIndex].times.push(time);
        } else {
          draft[colIndex].times.splice(rowIndex, 1);
        }
      })
    );
  };

  return (
    <Stack
      direction="row"
      display="flex"
      justifyContent="space-between"
      spacing="4px"
    >
      {isNeedWeekCursor && (
        <Box
          flex="1"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Button
            startIcon={<ArrowBackIosIcon />}
            style={{ height: "100%" }}
            onClick={onClickBeforeWeek}
            disabled={currentWeekStartDate.isSameOrBefore(props.minDate)}
          ></Button>
        </Box>
      )}
      <Box>
        <TableContainer
          component={Paper}
          style={{ height: "600px", overflow: "auto" }}
        >
          <StyledTable>
            <TableHead>
              <HeaderRow>
                <StyledCell></StyledCell>
                {displayDayOptions.map((opt) => (
                  <StyledCell key={opt.day}>
                    <Box textAlign="center">{opt.label}</Box>
                  </StyledCell>
                ))}
              </HeaderRow>
            </TableHead>
            <TableBody>
              {timeOptions.map((timeOpt) => (
                <StyledRow key={timeOpt.time}>
                  <FixedColumn>
                    <Stack direction="row">
                      <Box flex="1">
                        {timeOpt.label.slice(3, 5) === "00"
                          ? timeOpt.label.slice(0, 2)
                          : ""}
                      </Box>
                      <Box flex="1" textAlign="center">
                        :
                      </Box>
                      <Box flex="1">{timeOpt.label.slice(3, 5)}</Box>
                    </Stack>
                  </FixedColumn>
                  {displayDayOptions.map((dayOpt) => {
                    let isDisabled = false;
                    if (props.callbackDisabled) {
                      isDisabled = props.callbackDisabled(
                        dayOpt.day,
                        timeOpt.time
                      );
                    }

                    const dv = displayCellValues.find(
                      (v) => v.day === dayOpt.day
                    );

                    let isOn = false;

                    if (isDisabled) {
                      isOn = false;
                    } else if (
                      dv?.times?.filter((t) => t === timeOpt.time).length === 0
                    ) {
                      isOn = true;
                    }

                    return (
                      <StyledCell key={dayOpt.day}>
                        <Stack direction="column" spacing={0.5}>
                          <ToggleButton
                            disabled={isDisabled}
                            onClick={() => {
                              onClickCell(dayOpt.day, timeOpt.time);
                            }}
                            isOn={isOn}
                          />
                        </Stack>
                      </StyledCell>
                    );
                  })}
                </StyledRow>
              ))}
            </TableBody>
          </StyledTable>
        </TableContainer>
      </Box>
      {isNeedWeekCursor && (
        <Box
          flex="1"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Button
            startIcon={<ArrowForwardIosIcon />}
            style={{ height: "100%" }}
            onClick={onClickAfterWeek}
            disabled={currentWeekEndDate.isSameOrAfter(props.maxDate)}
          ></Button>
        </Box>
      )}
    </Stack>
  );
}

export default TaxiCompanyScheduleTable;
