import { Box, Button } from "@mui/material";
import { TaxiCompanyResponse } from "src/openapi";
import { useSnackbar } from "notistack";
import TaxiCompanyScheduleTable, {
  TaxiCompanyScheduleTableValues,
} from "../TaxiCompanyScheduleTableOld";
import { useTaxiWeeklyScheduleListQuery } from "src/hooks/coreApi/useTaxiWeeklyScheduleListQuery";
import { useEffect, useMemo, useState } from "react";
import { useTaxiWeeklyScheduleSaveMutation } from "src/hooks/coreApi/useTaxiWeeklyScheduleSaveMutation";
import { produce } from "immer";

type Props = {
  currentTaxiCompany?: TaxiCompanyResponse;
  onSuccess?(): void;
};

export default function TaxiCompanyScheduleWeeklyForm(props: Props) {
  const { enqueueSnackbar } = useSnackbar();

  const taxiWeeklyScheduleListQuery = useTaxiWeeklyScheduleListQuery({
    taxiCompanyId: props.currentTaxiCompany?.id,
  });

  const taxiWeeklyScheduleSaveMutation = useTaxiWeeklyScheduleSaveMutation();

  const taxiWeeklySchedules = taxiWeeklyScheduleListQuery.data;

  const colOptions = useMemo<
    {
      value: string;
      label: string;
      times: string[];
    }[]
  >(() => {
    const opts = [
      {
        value: "sun",
        label: "日",
        times: taxiWeeklySchedules?.sunTimes || [],
      },
      {
        value: "mon",
        label: "月",
        times: taxiWeeklySchedules?.monTimes || [],
      },
      {
        value: "tue",
        label: "火",
        times: taxiWeeklySchedules?.tueTimes || [],
      },
      {
        value: "wed",
        label: "水",
        times: taxiWeeklySchedules?.wedTimes || [],
      },
      {
        value: "thu",
        label: "木",
        times: taxiWeeklySchedules?.thuTimes || [],
      },
      {
        value: "fri",
        label: "金",
        times: taxiWeeklySchedules?.friTimes || [],
      },
      {
        value: "sat",
        label: "土",
        times: taxiWeeklySchedules?.satTimes || [],
      },
    ];

    return opts;
  }, [taxiWeeklySchedules, props.currentTaxiCompany]);

  const [cellValues, setCellValues] = useState<TaxiCompanyScheduleTableValues>(
    []
  );

  useEffect(() => {
    setCellValues(
      colOptions.map((opt) => ({ colKey: opt.value, times: opt.times }))
    );
  }, [colOptions]);

  const onClickCell = (rowValue: string, colKey: string) => {
    setCellValues(
      produce(cellValues, (draft) => {
        const colIndex = draft.findIndex((v) => v.colKey === colKey);
        const rowIndex = draft[colIndex].times.findIndex((v) => v === rowValue);
        if (rowIndex === -1) {
          draft[colIndex].times.push(rowValue);
        } else {
          draft[colIndex].times.splice(rowIndex, 1);
        }
      })
    );
  };

  if (!props.currentTaxiCompany) {
    return null;
  }

  const onClickSubmit = () => {
    taxiWeeklyScheduleSaveMutation.mutate(
      {
        taxiWeeklyScheduleSaveRequest: {
          taxiCompanyId: props.currentTaxiCompany?.id || 0,
          monTimes: cellValues.find((v) => v.colKey === "mon")?.times || [],
          tueTimes: cellValues.find((v) => v.colKey === "tue")?.times || [],
          wedTimes: cellValues.find((v) => v.colKey === "wed")?.times || [],
          thuTimes: cellValues.find((v) => v.colKey === "thu")?.times || [],
          friTimes: cellValues.find((v) => v.colKey === "fri")?.times || [],
          satTimes: cellValues.find((v) => v.colKey === "sat")?.times || [],
          sunTimes: cellValues.find((v) => v.colKey === "sun")?.times || [],
        },
      },
      {
        onSuccess: () => {
          enqueueSnackbar("保存しました", { variant: "success" });
          props.onSuccess?.();
        },
        onError: (e) => {
          enqueueSnackbar("保存に失敗しました", { variant: "error" });
        },
      }
    );
  };

  return (
    <Box>
      <TaxiCompanyScheduleTable
        colOptions={colOptions}
        onClickCell={onClickCell}
        startTime={props.currentTaxiCompany?.startBusinessHour || "00:00:00"}
        endTime={props.currentTaxiCompany?.endBusinessHour || "23:59:00"}
      />
      <Box marginTop="1rem" textAlign="right">
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={onClickSubmit}
        >
          保存する
        </Button>
      </Box>
    </Box>
  );
}
