import { useNavigate, useParams } from "react-router-dom";
import AppContainer from "src/components/app/common/AppContainer";
import { PATH_DASHBOARD } from "src/routes/paths";
import { useUserListQuery } from "src/hooks/coreApi/useUserListQuery";
import UserForm from "src/components/app/user/UserForm";
import { Box, Tab, Tabs } from "@mui/material";
import { useCallback, useState } from "react";
import Iconify from "src/components/iconify";
import UserTaxiDiscountForm from "src/components/app/user/UserTaxiDiscountForm";
import AppMenu from "src/components/app/common/AppMenu";
import AppAlertDialog, {
  useAppAlertDialog,
} from "src/components/app/common/AppAlertDialog";
import { useUserDeleteMutation } from "src/hooks/coreApi/useUserDeleteMutation";
import { useSnackbar } from "notistack";

type UserDetailTabType = "general" | "taxiDiscountApply";

export default function UserDetail() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams<{ id: string }>();
  const userListQuery = useUserListQuery({
    id: Number(id),
    withPlace: true,
    withUserInfo: true,
    withTaxiCompany: true,
  });

  const alertDialog = useAppAlertDialog();

  const userDeleteMutation = useUserDeleteMutation();

  const user = userListQuery?.data?.users?.[0];

  const [currentTab, setCurrentTab] = useState<UserDetailTabType>("general");

  const onSuccess = useCallback(() => {
    userListQuery.refetch();
  }, []);

  const TABS = [
    {
      value: "general",
      label: "基本情報",
      icon: <Iconify icon="ic:round-account-box" />,
      component: (
        <UserForm isEdit={true} currentUser={user} onSuccess={onSuccess} />
      ),
    },
    {
      value: "taxiDiscountApply",
      label: "まちのクルマLet's",
      icon: <Iconify icon="material-symbols:directions-car-outline" />,
      component: (
        <UserTaxiDiscountForm currentUser={user} onSuccess={onSuccess} />
      ),
    },
  ];

  const handleDeleteOk = () => {
    if (user?.id) {
      userDeleteMutation.mutate(
        {
          id: user.id,
        },
        {
          onSuccess() {
            enqueueSnackbar("削除しました");
            navigate("/users");
          },
          onError(err) {
            // TODO エラー表示
            enqueueSnackbar("サーバーエラーが発生しました", {
              variant: "error",
            });
          },
        }
      );
    }
  };

  return (
    <>
      <AppContainer
        meta={{
          title: "ユーザー詳細",
        }}
        breadcrumbs={{
          heading: "ユーザー詳細",
          links: [
            { name: "ホーム", href: PATH_DASHBOARD.root },
            { name: "ユーザー一覧", href: "/users" },
            { name: "ユーザー詳細" },
          ],
          action: (
            <AppMenu
              options={[
                {
                  label: "削除する",
                  onClick() {
                    alertDialog.onOpen();
                  },
                },
              ]}
            >
              その他の操作
            </AppMenu>
          ),
        }}
      >
        <Tabs
          value={currentTab}
          onChange={(event, newValue) => setCurrentTab(newValue)}
        >
          {TABS.map((tab) => (
            <Tab
              key={tab.value}
              label={tab.label}
              icon={tab.icon}
              value={tab.value}
            />
          ))}
        </Tabs>

        {TABS.map(
          (tab) =>
            tab.value === currentTab && (
              <Box key={tab.value} sx={{ mt: 5 }}>
                {tab.component}
              </Box>
            )
        )}
      </AppContainer>
      {user && (
        <AppAlertDialog
          {...alertDialog}
          title="ユーザーの削除"
          description={`ユーザー: ${user.nickname} (ID: ${user.id})を削除してもよろしいですか？`}
          onOk={handleDeleteOk}
        />
      )}
    </>
  );
}
