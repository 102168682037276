import { useNavigate, useParams } from "react-router-dom";
import { useMemo, useEffect, useCallback, useState } from "react";
// form
import { useForm } from "react-hook-form";
// @mui
import { LoadingButton } from "@mui/lab";
import { Grid, Card, Stack, Box, Typography } from "@mui/material";
// routes
import { PATH_DASHBOARD } from "../../../../routes/paths";
// @types
import { IBlogNewPost } from "../../../../@types/blog";
// components
import { useSnackbar } from "../../../snackbar";
import FormProvider, {
  RHFSelect,
  RHFTextField,
  RHFUploadBox,
} from "../../../hook-form";
import { useContentCreateMutation } from "src/hooks/coreApi/useContentCreateMutation";
import { useContentUpdateMutation } from "src/hooks/coreApi/useContentUpdateMutation";
import { ContentResponse } from "src/openapi/api";
import { ContentType } from "src/hooks/coreApi/useContentListQuery";
import dayjs from "dayjs";
import useFormError from "src/hooks/app/form/useFormError";
import { coreApiAxiosInstance } from "src/hooks/coreApi/coreApiClient";
import axios from "axios";
import Iconify from "src/components/iconify";
import { CustomFile, FilePreview } from "./FilePreview";
import PlaceSelect from "../../place/PlaceSelect";

export type FormValuesProps = IBlogNewPost;

type FormValues = {
  titleJa: string;
  bodyJa: string;
  releaseAt: string;
  bousaiType: "series" | "map";
  placeId: number;
  bousaiPdfFile: CustomFile | null;
  removeFileIds: number[] | null;
};

export type ContentFormProps = {
  isEdit?: boolean;
  currentContent?: ContentResponse;
  onSuccess?(): void;
};

export default function ContentBousaiForm(props: ContentFormProps) {
  const navigate = useNavigate();

  const { type } = useParams<{ type: ContentType }>();

  const { enqueueSnackbar } = useSnackbar();

  const contentCreateMutation = useContentCreateMutation();
  const contentUpdateMutation = useContentUpdateMutation();

  const defaultValues = useMemo<FormValues>(() => {
    const bousaiPdfFile = props.currentContent?.files?.find(
      (file) => file.type === "content_bousai_pdf"
    );
    return {
      bousaiType: props.currentContent?.contentTags?.find(
        (ct) => ct.label === "防災シリーズ"
      )
        ? "series"
        : "map",
      titleJa: props.currentContent?.titleJa || "",
      bodyJa: props.currentContent?.bodyJa || "",
      releaseAt:
        dayjs(props.currentContent?.releaseAt).format("YYYY-MM-DDTHH:mm") || "",
      bousaiPdfFile: bousaiPdfFile
        ? {
            name: bousaiPdfFile.name,
            key: bousaiPdfFile.key,
            path: bousaiPdfFile.cloudFrontUrl,
            id: bousaiPdfFile.id,
          }
        : null,
      placeId: props.currentContent?.contentPlaces?.[0]?.placeId || 0,
      removeFileIds: null,
    };
  }, [props.currentContent]);

  const contentForm = useForm<FormValues>({
    defaultValues,
  });

  useEffect(() => {
    contentForm.reset(defaultValues);
  }, [props]);

  const onSuccess = () => {
    enqueueSnackbar("保存しました");

    if (!props.isEdit) {
      contentForm.reset();
      navigate(PATH_DASHBOARD.content[type as ContentType]);
    }

    props?.onSuccess && props.onSuccess();
  };

  const onError = useFormError<FormValues>(contentForm, {
    name: "name",
  });

  const onSubmit = (values: FormValues) => {
    if (!type) {
      return;
    }

    let tag;
    switch (values.bousaiType) {
      case "map":
        tag = "ハザードマップ";
        break;
      case "series":
        tag = "防災シリーズ";
        break;
    }

    if (props.isEdit && props.currentContent) {
      contentUpdateMutation.mutate(
        {
          id: props.currentContent.id,
          contentUpdateRequest: {
            slug: props.currentContent.slug,
            detailType: "",
            titleJa: values.titleJa,
            titleEn: values.titleJa,
            bodyJa: values.bodyJa,
            bodyEn: values.bodyJa,
            areaIds: [],
            placeIds: values.placeId ? [Number(values.placeId)] : [],
            status: "public",
            kinkyuLevel: null,
            bousaiPdfFileKey: values.bousaiPdfFile?.key
              ? values.bousaiPdfFile.key
              : undefined,
            releaseAt: dayjs(values.releaseAt).toISOString(),
            // imageFileKey: "tmp/test.jpeg",
            // @ts-ignore
            removeFileIds: values.removeFileIds,
            tagLabels: tag ? [tag] : [],
          },
        },
        {
          onSuccess,
          onError,
        }
      );
    } else {
      contentCreateMutation.mutate(
        {
          contentCreateRequest: {
            type: type,
            slug: "",
            detailType: "",
            titleJa: values.titleJa,
            titleEn: values.titleJa,
            bodyJa: values.bodyJa,
            bodyEn: values.bodyJa,
            areaIds: [],
            placeIds: values.placeId ? [Number(values.placeId)] : [],
            status: "public", // TODO
            kinkyuLevel: null,
            bousaiPdfFileKey: values.bousaiPdfFile
              ? values.bousaiPdfFile.key
              : undefined,
            releaseAt: dayjs(values.releaseAt).toISOString(),
            // imageFileKey: "tmp/test.jpeg",
            tagLabels: tag ? [tag] : [],
          },
        },
        {
          onSuccess,
          onError,
        }
      );
    }
  };

  const handleDrop = async (acceptedFiles: File[]) => {
    const file = acceptedFiles[0];
    // const presignRes = await coreApiClient.fileApi.filePresignPutUrl({
    //   fileName: file.name,
    // });
    // TODO 型を修正
    const presignRes = await coreApiAxiosInstance.post("/files/presignPutUrl", {
      fileName: file.name,
    });

    if (!presignRes.data) {
      return;
    }

    const params = new FormData();
    params.append("file", file);

    const preSignedUrl = presignRes.data.url;
    const options = {
      headers: {
        "Content-Type": file.type,
      },
    };
    await axios.put(preSignedUrl, file, options);

    if (currentBousaiPdfFile?.id) {
      // サーバー保存済みのファイルがあれば削除
      contentForm.setValue("removeFileIds", [currentBousaiPdfFile.id]);
    }

    contentForm.setValue("bousaiPdfFile", {
      name: file.name,
      key: presignRes.data.key,
      path: URL.createObjectURL(file),
    });
  };

  const handleRemoveFile = (file: CustomFile) => {
    if (file?.id) {
      // サーバー保存済みのファイルの場合
      contentForm.setValue("removeFileIds", [file.id]);
    }
    contentForm.setValue("bousaiPdfFile", null);
  };

  const currentBousaiPdfFile = contentForm.watch("bousaiPdfFile");

  return (
    <FormProvider
      methods={contentForm}
      onSubmit={contentForm.handleSubmit(onSubmit)}
    >
      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          <Card sx={{ p: 3 }}>
            <Stack spacing={3}>
              <RHFSelect native name="bousaiType" label="種類">
                <option value="series">防災シリーズ</option>
                <option value="map">ハザードマップ</option>
              </RHFSelect>
              <RHFTextField name="titleJa" label="タイトル" />
              <PlaceSelect name="placeId" label="地区" />
              <Stack spacing={1}>
                <Typography
                  variant="subtitle2"
                  sx={{ color: "text.secondary" }}
                >
                  添付ファイル（PDF）
                </Typography>
              </Stack>
              <Stack>
                <RHFUploadBox
                  name="bousaiPdfFileKey"
                  onDrop={handleDrop}
                  placeholder={
                    <Stack spacing={0.5} alignItems="center">
                      <Iconify icon="eva:cloud-upload-fill" width={40} />
                      <Typography variant="body2">Upload file</Typography>
                    </Stack>
                  }
                  sx={{
                    flexGrow: 1,
                    width: "100%",
                    height: "auto",
                    py: 2.5,
                    mb: 3,
                  }}
                />
                <FilePreview
                  file={currentBousaiPdfFile}
                  onRemove={handleRemoveFile}
                />
              </Stack>
            </Stack>
          </Card>
        </Grid>

        <Grid item xs={12} md={4}>
          <Card sx={{ p: 3 }}>
            <Stack spacing={3}>
              {/* <div>
                <RHFSwitch
                  name="status"
                  label="公開する"
                  labelPlacement="start"
                  sx={{
                    mb: 1,
                    mx: 0,
                    width: 1,
                    justifyContent: "space-between",
                  }}
                />
              </div> */}
              <RHFTextField
                name="releaseAt"
                label="公開日時"
                type="datetime-local"
                onChange={(evt) => {
                  contentForm.setValue("releaseAt", evt.target.value);
                }}
              />
            </Stack>
          </Card>

          <Stack direction="row" spacing={1.5} sx={{ mt: 3 }}>
            <LoadingButton
              fullWidth
              type="submit"
              variant="contained"
              size="large"
              loading={contentForm.formState.isSubmitting}
            >
              保存する
            </LoadingButton>
          </Stack>
        </Grid>
      </Grid>
    </FormProvider>
  );
}
