import React, { useEffect, useMemo, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Stack,
  Button,
  Box,
  ButtonProps,
  Typography,
} from "@mui/material";

import { styled } from "@mui/system";
import dayjs from "dayjs";
import { set } from "nprogress";
import { produce } from "immer";

const isSameOrAfter = require("dayjs/plugin/isSameOrAfter");
const isSameOrBefore = require("dayjs/plugin/isSameOrBefore");
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

const TABLE_SPACING = "0.2em";

const StyledRow = styled(TableRow)(({ theme }) => ({
  paddingTop: TABLE_SPACING,
  paddingBottom: TABLE_SPACING,
  "&:hover": {
    backgroundColor: "rgba(0, 0, 0, 0.07)", // ホバー時の背景色
  },
}));

const StyledCell = styled(TableCell)(({ theme }) => ({
  paddingTop: TABLE_SPACING,
  paddingBottom: TABLE_SPACING,
}));

const HeaderRow = styled(StyledRow)(({ theme }) => ({
  position: "sticky",
  // 他のスタイルプロパティもここに追加できます
  top: 0,
  zIndex: 2,
  backgroundColor: "white",
}));

const FixedColumn = styled(StyledCell)(({ theme }) => ({
  position: "sticky",
  // 他のスタイルプロパティもここに追加できます
  left: 0,
  zIndex: 1,
  backgroundColor: "white",
}));

const StyledTable = styled(Table)(({ theme }) => ({
  tableCell: {
    border: "1px solid black",
  },
}));

const INTERVAL_MINUTES = 15;

type ToggleButtonProps = {
  isOn: boolean;
  onClick(): void;
};
const ToggleButton = function (props: ToggleButtonProps) {
  const [innerIsOn, setInnerIsOn] = useState<null | boolean>(null);
  const buttonProps: ButtonProps = {
    size: "small",
    variant: "outlined",
  };

  useEffect(() => {
    setInnerIsOn(null);
  }, [props.isOn]);

  const showIsOn = innerIsOn === null ? props.isOn : innerIsOn;

  if (showIsOn) {
    buttonProps.color = "primary";
  } else {
    buttonProps.color = "error";
  }

  return (
    <Button
      {...buttonProps}
      onClick={() => {
        setInnerIsOn(!showIsOn);
        props.onClick();
      }}
    >
      <Typography sx={{ fontSize: "1.8rem" }}>
        {" "}
        {showIsOn ? "○" : "×"}
      </Typography>
    </Button>
  );
};

export type TaxiCompanyScheduleTableWeekDay =
  | "mon"
  | "tue"
  | "wed"
  | "thu"
  | "fri"
  | "sat"
  | "sun";

export type TaxiCompanyScheduleTableColOption = {
  value: TaxiCompanyScheduleTableWeekDay;
  label: string;
  times: string[];
};

type TaxiCompanyScheduleTableProps = {
  // TODO この型リファクタ
  colOptions: { value: string; label: string; times: string[] }[];
  onClickCell(rowValue: string, colValue: string): void;
  startTime: string;
  endTime: string;
};

export type TaxiCompanyScheduleTableValues = {
  colKey: string;
  times: string[];
}[];

export function TaxiCompanyScheduleTable(props: TaxiCompanyScheduleTableProps) {
  const rowOptions = useMemo(() => {
    const tmpRowOptions = [];
    let time = dayjs().startOf("day");
    const ymd = dayjs().format("YYYY-MM-DD");
    const startTime = dayjs(`${ymd} ${props.startTime}`);
    const endTime = dayjs(`${ymd} ${props.endTime}`);

    while (time.isBefore(dayjs().endOf("day"))) {
      // 営業時間 07:00〜の場合、07:00は含むので isBeforeを使う
      // 営業時間〜19:00の場合、19:00は含まないので isSameOrAfterを使う
      if (time.isBefore(startTime) || time.isSameOrAfter(endTime)) {
        time = time.add(INTERVAL_MINUTES, "minute");
        continue;
      }

      const t = time.format("HH:mm");
      tmpRowOptions.push({ value: t, label: t });
      time = time.add(INTERVAL_MINUTES, "minute");
    }

    return tmpRowOptions;
  }, [props.startTime, props.endTime]);

  return (
    <Box>
      <TableContainer
        component={Paper}
        style={{ height: "600px", overflow: "auto" }}
      >
        <StyledTable>
          <TableHead>
            <HeaderRow>
              <StyledCell></StyledCell>
              {props.colOptions.map((opt) => (
                <StyledCell key={opt.value}>
                  <Box textAlign="center">{opt.label}</Box>
                </StyledCell>
              ))}
            </HeaderRow>
          </TableHead>
          <TableBody>
            {rowOptions.map((rowOpt) => (
              <StyledRow key={rowOpt.value}>
                <FixedColumn>
                  <Stack direction="row">
                    <Box flex="1">
                      {rowOpt.label.slice(3, 5) === "00"
                        ? rowOpt.label.slice(0, 2)
                        : ""}
                    </Box>
                    <Box flex="1" textAlign="center">
                      :
                    </Box>
                    <Box flex="1">{rowOpt.label.slice(3, 5)}</Box>
                  </Stack>
                </FixedColumn>
                {props.colOptions.map((colOpt) => (
                  <StyledCell key={colOpt.value}>
                    <Stack direction="column" spacing={0.5}>
                      <ToggleButton
                        onClick={() => {
                          props.onClickCell(rowOpt.value, colOpt.value);
                        }}
                        isOn={
                          colOpt.times.filter((t) => t === rowOpt.value)
                            .length === 0
                        }
                      />
                    </Stack>
                  </StyledCell>
                ))}
              </StyledRow>
            ))}
          </TableBody>
        </StyledTable>
      </TableContainer>
    </Box>
  );
}

export default TaxiCompanyScheduleTable;
