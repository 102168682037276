import * as React from 'react';
import Box from '@mui/material/Box';
import {GridFilterInputDateProps} from "@mui/x-data-grid-premium";
import TextField, { TextFieldProps } from '@mui/material/TextField';
import {DateRangePicker, DateRange} from '@mui/x-date-pickers-pro/DateRangePicker';
import {InputLabel} from "@mui/material";


export function FilterDateRange(props: GridFilterInputDateProps) {
  const {item, applyValue, focusElementRef} = props;
  const [value, setValue] = React.useState<DateRange<Date>>(item.value || [null, null]);

  return (
    <Box
      sx={{
        alignItems: 'center',
        height: 48,
        width: 500,
      }}
    >
      <InputLabel shrink={true}>値</InputLabel>
      <DateRangePicker
        value={value}
        inputFormat='yyyy年MM月dd日'
        mask='____年__月__日'
        onChange={(newValue) => {
          setValue(newValue);
          applyValue({...item, value: newValue});
        }}
        renderInput={(startProps: JSX.IntrinsicAttributes & TextFieldProps, endProps: JSX.IntrinsicAttributes & TextFieldProps) => (
          <React.Fragment>
            <TextField {...startProps} variant="standard" label='' inputProps={{...startProps.inputProps, placeholder: '年 /月/日'}} />
            <Box sx={{ mx: 2 }}> to </Box>
            <TextField {...endProps} variant="standard" label='' inputProps={{...endProps.inputProps, placeholder: '年 /月/日'}} />
          </React.Fragment>
        )}
        />
      </Box>
  );
}
