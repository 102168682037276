import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import React, { useState } from "react";

type UseAppAlertDialogReturn = {
  isOpen: boolean;
  onClose(): void;
  onOk(): void;
};

export function useAppAlertDialog() {
  const [isOpen, setIsOpen] = useState(false);
  const onOpen = () => {
    setIsOpen(true);
  };
  const onClose = () => {
    setIsOpen(false);
  };
  return {
    isOpen,
    onOpen,
    onClose,
  };
}

type AppAlertDialogProps = {
  onCancel?(): void;
  onOk(): void;
  title?: string;
  description?: string;
} & UseAppAlertDialogReturn;

// source: https://mui.com/material-ui/react-dialog/#alerts
export default function AppAlertDialog(props: AppAlertDialogProps) {
  const handleClose = () => {
    props.onClose();
  };

  const handleOk = () => {
    props.onOk();
    handleClose();
  };

  const handleCancel = () => {
    props?.onCancel && props.onCancel();
    handleClose();
  };

  return (
    <div>
      <Dialog
        open={props.isOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{props?.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {props?.description}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel}>キャンセル</Button>
          <Button onClick={handleOk} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
