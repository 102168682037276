import { Button, Card } from "@mui/material";
import {
  DataGridPremium,
  GridColDef,
  GridRenderCellParams,
  GridToolbar,
  GridValueGetterParams,
  jaJP,
} from "@mui/x-data-grid-premium";
import { useMemo } from "react";
import { Link } from "react-router-dom";
import AppContainer from "src/components/app/common/AppContainer";
import Iconify from "src/components/iconify";
import { useRoomListQuery } from "src/hooks/coreApi/useRoomListQuery";
import { RoomResponse } from "src/openapi";
import { PATH_DASHBOARD } from "src/routes/paths";
import dayjs from "dayjs";

export default function RoomList() {
  const roomQuery = useRoomListQuery({
    withRoomParticipants: true,
    type: "group",
  });
  const rooms = roomQuery?.data?.rooms;

  const columns = useMemo<GridColDef[]>(() => {
    return [
      { field: "id", headerName: "ID" },
      {
        field: "name",
        headerName: "ルーム名",
        width: 400,
        renderCell(params: GridRenderCellParams<string, RoomResponse>) {
          return (
            <Link to={`/video/rooms/${params.row.id}`}>{params.value}</Link>
          );
        },
      },
      {
        field: "isPrivate",
        headerName: "公開設定",
        filterable: true,
        valueGetter(params: GridValueGetterParams<string, RoomResponse>) {
          return params.row.isPrivate ? "非公開" : "公開";
        },
      },
      {
        field: "startAt",
        headerName: "開始日時",
        filterable: true,
        width: 150,
        valueGetter(params: GridValueGetterParams<string, RoomResponse>) {
          if (!params.row.startAt) {
            return "未設定";
          }
          return dayjs(params.row.startAt).format("YYYY-MM-DD HH:mm");
        },
      },
      {
        field: "endAt",
        headerName: "終了日時",
        filterable: true,
        width: 150,
        valueGetter(params: GridValueGetterParams<string, RoomResponse>) {
          if (!params.row.endAt) {
            return "未設定";
          }
          return dayjs(params.row.endAt).format("YYYY-MM-DD HH:mm");
        },
      },
      {
        field: "numberOfParticipants",
        headerName: "参加中人数",
        filterable: true,
        valueGetter(params: GridValueGetterParams<string, RoomResponse>) {
          if (!params.row.roomParticipants) {
            return 0;
          }
          return params.row.roomParticipants.length;
        },
      },
      {
        field: "createdAt",
        headerName: "作成日時",
        filterable: true,
        width: 150,
        valueGetter(params: GridValueGetterParams<string, RoomResponse>) {
          return dayjs(params.row.createdAt).format("YYYY-MM-DD HH:mm");
        },
      },
      {
        field: "updatedAt",
        headerName: "更新日時",
        filterable: true,
        width: 150,
        valueGetter(params: GridValueGetterParams<string, RoomResponse>) {
          return dayjs(params.row.updatedAt).format("YYYY-MM-DD HH:mm");
        },
      },
    ];
  }, []);

  return (
    <>
      <AppContainer
        meta={{
          title: "ルーム一覧（グループ）",
        }}
        breadcrumbs={{
          heading: "ルーム一覧（グループ）",
          links: [
            { name: "ホーム", href: PATH_DASHBOARD.root },
            { name: "ルーム一覧（グループ）" },
          ],
          action: (
            <>
              <Button
                component={Link}
                to="/video/rooms/add"
                variant="contained"
                startIcon={<Iconify icon="eva:plus-fill" />}
              >
                新規追加
              </Button>
            </>
          ),
        }}
      >
        <Card style={{ height: "800px", width: "100%" }}>
          <DataGridPremium
            rows={rooms || []}
            columns={columns}
            isRowSelectable={() => false}
            components={{ Toolbar: GridToolbar }}
            localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
            filterMode="client"
          />
        </Card>
      </AppContainer>
    </>
  );
}
