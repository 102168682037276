// routes
import { PATH_AUTH } from "../routes/paths";
// utils
import axios from "../utils/axios";

// ----------------------------------------------------------------------

function jwtDecode(token: string) {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join("")
  );

  return JSON.parse(jsonPayload);
}

// ----------------------------------------------------------------------

export const isValidToken = (accessToken: string) => {
  if (!accessToken) {
    return false;
  }

  const decoded = jwtDecode(accessToken);

  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

// ----------------------------------------------------------------------

export const tokenExpired = (exp: number) => {
  // eslint-disable-next-line prefer-const
  let expiredTimer;

  const currentTime = Date.now();

  // Test token expires after 10s
  // const timeLeft = currentTime + 10000 - currentTime; // ~10s

  // 最大遅延時間を超えると即時実行されるため
  // developer.mozilla.org/ja/docs/Web/API/setTimeout#%E6%9C%80%E5%A4%A7%E3%81%AE%E9%81%85%E5%BB%B6%E6%99%82%E9%96%93
  const timeLeft = Math.min(exp * 1000 - currentTime, 60 * 60 * 24 * 1000);

  clearTimeout(expiredTimer);

  expiredTimer = setTimeout(() => {
    alert(
      "セッション有効期限切れのため自動ログアウトしました"
    );

    removeSession();

    window.location.href = PATH_AUTH.login;
  }, timeLeft);
};

export const removeSession = () => {
  localStorage.removeItem("accessToken");
};

// ----------------------------------------------------------------------

export const setSession = (accessToken: string | null) => {
  if (accessToken) {
    localStorage.setItem("accessToken", accessToken);

    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

    // This function below will handle when token is expired
    const { exp } = jwtDecode(accessToken); // ~3 days by minimals server

    tokenExpired(exp);
  } else {
    removeSession();

    delete axios.defaults.headers.common.Authorization;
  }
};
