import { UserResponse } from "src/openapi";
export type UserAuthType = "general" | "office" | "taxiCompany";

type UserAuthTypeOption = {
  type: UserAuthType;
  name: string;
};

export const userAuthTypeOptions: UserAuthTypeOption[] = [
  {
    type: "general",
    name: "一般",
  },
  {
    type: "office",
    name: "管理者",
  },
  {
    type: "taxiCompany",
    name: "タクシー事業者",
  },
];

// TODO return Type
export const convertUserAuthTypeToParams = (
  userAuthType: UserAuthType,
  taxiCompanyId: number | null
): any => {
  switch (userAuthType) {
    case "taxiCompany":
      return {
        isOffice: false,
        taxiCompanyId: taxiCompanyId ? Number(taxiCompanyId) : null,
      };
    case "office":
      return {
        isOffice: true,
        taxiCompanyId: null,
      };
    case "general":
    default:
      return {
        isOffice: false,
        taxiCompanyId: null,
      };
  }
};

export const convertUserParamsToUserAuthType = (
  user: UserResponse
): UserAuthType => {
  if (user.isOffice) {
    return "office";
  }

  if (user.taxiCompanyId) {
    return "taxiCompany";
  }

  return "general";
};

export const getUserAuthTypeOption = (
  user: UserResponse | undefined | null
): UserAuthTypeOption | undefined => {
  if (!user) {
    return undefined;
  }
  const authType = convertUserParamsToUserAuthType(user);
  const authTypeOption = userAuthTypeOptions.find(
    (opt) => opt.type === authType
  );
  return authTypeOption;
};
