import { RHFSelect } from "src/components/hook-form";

type TaxiAchievementPayMethodSelectProps = {
  name: string;
  label: string;
};

export default function TaxiAchievementPayMethodSelect(
  props: TaxiAchievementPayMethodSelectProps
) {
  return (
    <RHFSelect native name={props.name} label={props.label}>
      <option value="">決済方法を選択してください</option>
      <option value="money">現金</option>
      <option value="payPay">PayPay</option>
      <option value="credit">クレジット</option>
    </RHFSelect>
  );
}
