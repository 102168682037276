import { UseQueryOptions } from "@tanstack/react-query";
import { TaxiWeeklyScheduleApiTaxiWeeklyScheduleListRequest } from "../../openapi";
import appApiClient from "./coreApiClient";
import useBaseQuery from "./useBaseQuery";
import { AxiosError } from "axios";

export const useTaxiWeeklyScheduleListQuery = (
  req: TaxiWeeklyScheduleApiTaxiWeeklyScheduleListRequest
) =>
  useBaseQuery({
    queryKey: ["taxiWeeklyScheduleList"],
    queryFn: (config) =>
      appApiClient.taxiWeeklyScheduleApi.taxiWeeklyScheduleList(req, config),
  });
