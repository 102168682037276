import { TrashCalendarApiTrashCalendarListRequest } from "../../openapi/api";
import appApiClient from "./coreApiClient";
import useBaseQuery from "./useBaseQuery";

export const useTrashCalendarListQuery = (
  req: TrashCalendarApiTrashCalendarListRequest
) =>
  useBaseQuery({
    queryKey: ["trashCalendarList", req],
    queryFn: (config) =>
      appApiClient.trashCalendarApi.trashCalendarList(req, config),
  });
