import { TaxiDailyScheduleApiTaxiDailyScheduleSaveRequest } from "src/openapi";
import appApiClient from "./coreApiClient";
import useBaseMutation from "./useBaseMutation";

export const useTaxiDailyScheduleSaveMutation = () =>
  useBaseMutation({
    mutationFn: (
      req: TaxiDailyScheduleApiTaxiDailyScheduleSaveRequest,
      config
    ) => appApiClient.taxiDailyScheduleApi.taxiDailyScheduleSave(req, config),
  });
