import {
  Alert,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import axios, { AxiosResponse } from "axios";
import React, { useMemo } from "react";
import AppContainer from "src/components/app/common/AppContainer";
import { UserListResponse } from "src/openapi/api";
import { PATH_DASHBOARD } from "src/routes/paths";

const SECURE_API_BASE_URL = "https://secure-api.cir-cle.app";

type TestStatus = "loading" | "success" | "error";

type ConnectStatusProps = {
  status: TestStatus;
};

function ConnectStatus({ status }: ConnectStatusProps) {
  if (status === "loading") {
    return (
      <Alert severity="info">
        リクエスト中 &nbsp;
        <CircularProgress color="inherit" size={12} />
      </Alert>
    );
  }

  if (status === "success") {
    return <Alert severity="success">個人情報APIの接続に成功しました。</Alert>;
  }

  return <Alert severity="error">個人情報API 接続エラー</Alert>;
}

// Direct Connect疎通テスト 個人情報の取得確認用
export default function VPNTest() {
  const query = useQuery<AxiosResponse<UserListResponse>>({
    queryKey: ["secureUsers"],
    queryFn: () =>
      axios.get("/users", {
        params: { pageSize: 100, withUserInfo: true },
        baseURL: `${SECURE_API_BASE_URL}/api`,
        headers: {
          "X-Tenant-Id": 1,
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      }),
    retry: 0,
    retryDelay: 1,
    refetchOnWindowFocus: false,
  });

  const users = query.data?.data?.users;

  const status = useMemo<TestStatus>(() => {
    if (query.isError) {
      return "error";
    }

    if (query.isLoading) {
      return "loading";
    }

    if (query.isSuccess) {
      return "success";
    }

    return "loading";
  }, [query.fetchStatus]);

  return (
    <>
      <AppContainer
        meta={{
          title: "VPN接続テスト",
        }}
        breadcrumbs={{
          heading: "VPN接続テスト",
          links: [
            { name: "ホーム", href: PATH_DASHBOARD.root },
            { name: "VPNテスト" },
          ],
        }}
      >
        {/* <ConnectStatus status={status} /> */}
        <Card variant="outlined">
          <CardHeader title="テスト状態"></CardHeader>
          <CardContent>
            <ConnectStatus status={status} />
          </CardContent>
          <CardActions>
            <Button
              onClick={() => {
                query.refetch();
              }}
            >
              再接続
            </Button>
          </CardActions>
        </Card>
        <Box mt="4">
          {users?.map((user) => (
            <div key={user.id}>
              {Object.values(user?.userInfo || {}).join(",")}
            </div>
          ))}
        </Box>
      </AppContainer>
    </>
  );
}
