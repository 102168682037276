import { Button, Card, Checkbox, Chip } from "@mui/material";
import {
  DataGridPremium,
  GridColDef,
  GridRenderCellParams,
  GridToolbar,
  GridValueGetterParams,
  jaJP,
} from "@mui/x-data-grid-premium";
import dayjs from "dayjs";
import { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import AppContainer from "src/components/app/common/AppContainer";
import UserAuthTypeChip from "src/components/app/user/UserAuthTypeChip";
import Iconify from "src/components/iconify";
import { useUserListQuery } from "src/hooks/coreApi/useUserListQuery";
import { UserResponse } from "src/openapi";
import { PATH_DASHBOARD } from "src/routes/paths";
import { getUserAuthTypeOption } from "src/utils/user";

export default function UserList() {
  const [pageSize, setPageSize] = useState(500);

  const userQuery = useUserListQuery({
    withPlace: true,
    withTaxiCompany: true,
    withUserInfo: true,
    pageSize: 2000,
  });

  const users = userQuery?.data?.users;

  const columns = useMemo<GridColDef[]>(() => {
    return [
      { field: "id", headerName: "ID" },
      {
        field: "nickname",
        headerName: "お名前",
        filterable: true,
        width: 150,
        renderCell(params: GridRenderCellParams<string, UserResponse>) {
          return <Link to={`/users/${params.row.id}`}>{params.value}</Link>;
        },
      },
      {
        field: "nicknameKana",
        headerName: "お名前(カナ)",
        filterable: true,
        width: 150,
      },
      {
        field: "code",
        headerName: "さあ・くるID",
        filterable: true,
        width: 120,
        valueGetter(params: GridValueGetterParams<string, UserResponse>) {
          return params.row.userInfo?.code;
        },
      },
      {
        field: "authType",
        headerName: "権限",
        filterable: true,
        width: 120,
        valueGetter(params: GridValueGetterParams<string, UserResponse>) {
          return getUserAuthTypeOption(params.row)?.name || "";
        },
        renderCell(params: GridRenderCellParams<string, UserResponse>) {
          return <UserAuthTypeChip user={params.row} />;
        },
      },
      {
        field: "taxiDiscountFlag",
        headerName: "乗車割引適用",
        filterable: true,
        width: 80,
        valueGetter(params: GridValueGetterParams<string, UserResponse>) {
          return params.row.taxiDiscountFlag ? "適用" : "未適用";
        },
        renderCell(params: GridRenderCellParams<string, UserResponse>) {
          return <Checkbox checked={params.row.taxiDiscountFlag} disabled />;
        },
      },
      {
        field: "userInfo.topicId",
        headerName: "TOPIC ID",
        filterable: true,
        width: 150,
        valueGetter(params: GridValueGetterParams<string, UserResponse>) {
          return params.row.userInfo?.topicId;
        },
      },
      {
        field: "commonName",
        headerName: "氏名",
        filterable: true,
        width: 150,
        valueGetter(params: GridValueGetterParams<string, UserResponse>) {
          return params.row.userInfo?.commonName;
        },
      },
      {
        field: "userInfo.email",
        headerName: "Eメールアドレス",
        filterable: true,
        width: 200,
        valueGetter(params: GridValueGetterParams<string, UserResponse>) {
          return params.row.userInfo?.email;
        },
      },
      {
        field: "userInfo.phone",
        headerName: "電話番号",
        filterable: true,
        width: 150,
        valueGetter(params: GridValueGetterParams<string, UserResponse>) {
          return params.row.userInfo?.phone;
        },
      },
      {
        field: "createdAt",
        headerName: "作成日時",
        filterable: true,
        width: 150,
        valueGetter(params: GridValueGetterParams<Date, UserResponse>) {
          return dayjs(params.row.createdAt).toDate();
        },
        renderCell(params: GridRenderCellParams<Date, UserResponse>) {
          return dayjs(params.value).format("YYYY-MM-DD HH:mm");
        },
      },
      {
        field: "updatedAt",
        headerName: "最終更新日時",
        filterable: true,
        width: 150,
        valueGetter(params: GridValueGetterParams<Date, UserResponse>) {
          // user.updatedAtかuserInfo.updatedAtの新しい方
          let updatedAt = params.row.updatedAt;
          if (
            params.row.userInfo?.updatedAt &&
            params.row.updatedAt > params.row.userInfo?.updatedAt
          ) {
            updatedAt = params.row.userInfo.updatedAt;
          }
          return dayjs(updatedAt).toDate();
        },
        renderCell(params: GridRenderCellParams<Date, UserResponse>) {
          return dayjs(params.value).format("YYYY-MM-DD HH:mm");
        },
      },
    ];
  }, []);

  return (
    <>
      <AppContainer
        meta={{
          title: "ユーザー一覧",
        }}
        breadcrumbs={{
          heading: "ユーザー一覧",
          links: [
            { name: "ホーム", href: PATH_DASHBOARD.root },
            { name: "ユーザー一覧" },
          ],
          action: (
            <>
              <Button
                component={Link}
                to="/users/add"
                variant="contained"
                startIcon={<Iconify icon="eva:plus-fill" />}
              >
                新規追加
              </Button>
            </>
          ),
        }}
      >
        <Card style={{ height: "1000px", width: "100%" }}>
          <DataGridPremium
            rows={users || []}
            columns={columns}
            isRowSelectable={() => false}
            components={{ Toolbar: GridToolbar }}
            localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
            filterMode="client"
            pageSize={pageSize}
            onPageSizeChange={setPageSize}
            rowsPerPageOptions={[500, 1000, 1500, 2000]}
            pagination
            density="compact"
            initialState={{
              sorting: {
                sortModel: [{ field: "id", sort: "desc" }],
              },
            }}
          />
        </Card>
      </AppContainer>
    </>
  );
}
